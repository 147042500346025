/**
 * Logo.js | Component
 * Marklii logo with right margin and sizing.
 * 
 * Props: none.
 */

import React from 'react';
import styles from './Logo.module.css';
import markliiLogo from '../../assets/images/logo-h-white@3x.png';


const logo = (props) => (
    <div className={styles.Logo}>
        <img src={markliiLogo} alt="Marklii" />
    </div>
);

export default logo;