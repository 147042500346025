/**
 * Button.js | UI Component
 * A styled button.
 * 
 * Props: 
 * - disabled: 
 * - disabledText: 
 * - type: the style of the button. Currently, it can be Primary (green), RedAlert (red) or Default (white).
 * - size: small or normal;
 * - shouldSubmit: set the button type to 'submit'.
 * - onClick: [function].
 */

import React from 'react';
import styles from './Button.module.css';
import searchIcon from '../../../assets/images/search-icon@3x.png';
import deleteIcon from '../../../assets/images/delete-icon@3x.png';
import backIcon from '../../../assets/images/right-arrow-icon@3x.png';

const button = (props) => {

    const buttonText = props.disabled ? props.disabledText ? props.disabledText : props.children : props.children;
    const buttonContent = props.children ? <div className={styles.Text}>{buttonText}</div> : null;

    let buttonIcon = null;
    switch (props.icon) {
        case 'search':
            buttonIcon = <img src={searchIcon} className={styles.Icon} alt='Pesquisar'></img>
            break;

        case 'delete':
            buttonIcon = <img src={deleteIcon} className={styles.Icon} alt='Excluir'></img>
            break;

        case 'back':
            buttonIcon = <img src={backIcon} className={styles.IconFlipped} alt='Anterior'></img>
            break;

        case 'forward':
            buttonIcon = <img src={backIcon} className={styles.Icon} alt='Próximo'></img>
            break;
        
        default:
            break;
    }

    return (
        <div className={styles.ButtonDiv}>
            <button 
                type={props.shouldSubmit ? 'submit' : 'button'}
                className={[styles[props.type ? props.type : 'Primary'], styles[props.size === 'small' ? 'Small' : '']].join(' ')} 
                disabled={props.disabled}
                onClick={props.onClick ? (e) => {e.preventDefault(); props.onClick()} : null}>
                {buttonIcon} 
                {buttonContent}
            </button>
        </div>
    );
}



export default button;