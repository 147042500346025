import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import styles from './UnknownRoute.module.css';

import piggy from '../../assets/images/404-piggy-is-here-for-you.jpg'

class UnknownRoute extends Component {

    componentDidMount() {
        this.props.setPageTitle("😱😱😱😱");
    }

    componentDidUpdate() {
        this.props.setPageTitle("😱😱😱😱");
    }

    render() {
        return (
            <div className={styles.Container}>
                <img src={piggy} className={styles.Piggy} alt='Estou aqui por você'></img>
            </div>
        )
    }

};

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (title) => dispatch(actions.setTitle(title))
    }
}

export default connect(null, mapDispatchToProps)(UnknownRoute);