/**
 * Product.js | Data Model
 * An object representing a Product. 
 * This same object can be created from items stored the Catalog collection and History collection. 
 * Different data will be retrieved from each location.
 * 
*/

class Product {

    constructor(jsonPayload) {

        this.storeSection = jsonPayload.storeSection || 'Novo no Marklii';
        this.markliiCode = jsonPayload.markliiCode;
        this.GTIN = jsonPayload.GTIN || jsonPayload.gtin || null;
        this.pictureURL = jsonPayload.pictureURL || null;
        this.isFemaleGenericProduct = jsonPayload.isFemaleGenericProduct || false;
        this.genericName = jsonPayload.genericName;
        this.genericType = jsonPayload.genericType || null;
        this.productVariation = jsonPayload.productVariation || null;
        this.alsoKnownAs = jsonPayload.alsoKnownAs || [];
        this.manufacturer = jsonPayload.manufacturer || null;
        this.brand = jsonPayload.brand || null;
        this.tags = jsonPayload.tags || [];
        if (this.tags.constructor !== Array) {
            this.tags = [];
        }
        this.packageQuantity = Number(jsonPayload.packageQuantity) || 1;
        this.contentOfOneUnit = Number(jsonPayload.contentOfOneUnit) || 1;
        this.contentUnit = jsonPayload.contentUnit || null;
        this.isPriceByWeight = jsonPayload.isPriceByWeight || false;
        this.wordForOneUnit = jsonPayload.wordForOneUnit || null;
        this.isFemaleWordForOneUnit = jsonPayload.isFemaleWordForOneUnit || false;
        this.productDescription = jsonPayload.productDescription || null;
        this.isIncomplete = jsonPayload.isIncomplete || false;

    }

    displayName() {
        let names = [this.genericName];
        if (this.genericType) {
            names.push(this.genericType);
        }
        if (this.productVariation) {
            names.push(this.productVariation);
        }
        return names.join(' ');
    }

    // 480g
    displayTotalContent() {
        const content = this.contentOfOneUnit * this.packageQuantity;
        return content + this.contentUnit;
    }

    // 80g
    displayContentOfOneUnit() {
        return this.contentOfOneUnit + this.contentUnit;
    }

    // 6x 80g
    displayPackageQuantityAndContent() {
        if (this.isPriceByWeight) {
            return 'Por peso';
        } else {
            if (this.packageQuantity > 1) {
                if (this.contentUnit === 'un') {
                    return this.packageQuantity + this.contentUnit;
                } else {
                    return this.packageQuantity + 'x ' + this.displayContentOfOneUnit();
                }
            } else {
                return this.displayContentOfOneUnit();
            }
        }
    }

    displayQuantity() {
        return this.quantityBought + (this.isPriceByWeight ? this.contentUnit : ' un.');
    }

    // This function is called on AddPurchaseItem, when looking for customCode products in History. 
    // It removes all information related to purchases, leaving only catalog data.
    clearPurchaseInformation() {
        this.purchaseID = null;
        this.purchaseDate = null;
        this.userWhoBought = null;
        this.pantryID = null;
        this.quantityBought = null;
        this.price = null;
        this.discount = null;
        this.itemNumber = null;
    }

    filter() {
        Object.keys(this).forEach(key => { if (this[key] === undefined) delete this[key] });
    }

}

export default Product;