/**
 * Tag.js | Component
 * A tag component.
 * 
 * Props: 
 * - color: [String] 'blue', 'blank', 'green', 'red'
 * - children: [String] the text that will be shown in the tag.
 * - onRemoval: [function] function used to close/remove the tag.
 * - onClick: [function] optional function used when clicking on the tag.
*/

import React from 'react';
import styles from './Tag.module.css';
import removeIcon from '../../../assets/images/delete-icon@3x.png';

const tag = (props) => {

    const onRemoval = () => {
        props.onRemoval(props);
    }

    let removeButton = null
    if (props.onRemoval) {
        removeButton = (
            <div className={styles.RemoveBox} onClick={onRemoval} title="Remover">
                <img src={removeIcon} className={styles.RemoveIcon} alt='Excluir'></img>
            </div>
        );
    }

    let colorClass = styles.Blue;
    switch (props.color) {
        case 'blue':
            colorClass = styles.Blue
            break;

        case 'blank':
            colorClass = styles.Blank
            break;

        case 'green':
            colorClass = styles.Green
            break;

        case 'red':
            colorClass = styles.Red
            break;

        default:
            break;
    }

    const onClick = () => {
        if (props.onClick)
            props.onClick(props);
    }

    return (
        <div className={[styles.Container, colorClass].join(' ')}>
            <div className={[styles.TagText, props.onClick ? styles.Pointer : null].join(' ')} onClick={onClick}>{props.children}</div>
            {removeButton}
        </div>
    );

}

export default tag;