import React, { Component } from 'react';
import { connect } from 'react-redux';

import Sidebar from '../../components/Sidebar/Sidebar'
import Header from '../../components/Header/Header'
import styles from './Layout.module.css'

const mql = window.matchMedia('(min-width: 768px)');

class Layout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hasUserHiddenSidebar: false,
            isSidebarVisible: mql.matches,
        };

        this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    }

    UNSAFE_componentWillMount() {
        mql.addListener(this.mediaQueryChanged);
    }

    componentWillUnmount() {
        mql.removeListener(this.mediaQueryChanged);
    }

    onSetSidebarOpen(open) {
        this.setState({isSidebarVisible: open});
    }

    mediaQueryChanged() {
        const isScreenWideEnough = mql.matches;
        this.setState({isSidebarVisible: isScreenWideEnough });
    }

    toggleSidebar = () => {
        this.setState(( prevState ) => {
            return {
                isSidebarVisible: !prevState.isSidebarVisible,
                hasUserHiddenSidebar: !prevState.hasUserHiddenSidebar
            };
        } );
    }

    render () {
        return (
            <div className={styles.Container}>
                <Sidebar isVisible={this.state.isSidebarVisible} toggleSidebar={this.toggleSidebar} />
                <div className={styles.MainColumn}>
                    <Header pageTitle={this.props.pageTitle} isSidebarVisible={this.state.isSidebarVisible} toggleSidebar={this.toggleSidebar} />
                    <main className={styles.Canvas}>
                        {this.props.children}
                    </main>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        pageTitle: state.app.title
    }
}

export default connect(mapStateToProps)(Layout);
