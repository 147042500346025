/**
 * Notification.js | Component
 * TO BE REDONE
 * Currently only a label, used in AuthView for showing errors. 
 
 * TODO: When possible, adjust it to present a top notification slider with any message.
 * 
 * Props: none.
 */

import React from 'react';
import styles from './Notification.module.css';


const notification = (props) => {

    return (
        <div className={styles.Notification}>
            {props.children}
        </div>
    );
};

export default notification;