/**
 * InputText.js | Component
 * An input of type text component, with custom methods and styles.
 * 
 * Props: 
 * - name: [String] the name of the input
 * - ref: [String] if any, a ref so the DOM can focus.
 * - value: [String] the input text.
 * - placeholder: [String] placeholder, if any.
 * - autoComplete: [String] used to specify autocompletion feature. "off" disabled autocomplete.
 * - isRequired: [Bool] If true, value can not be empty.
 * - isNumber: [Bool] If true, value should be a valid number.
 * - isEmail: [Bool] If true, value should be a valid email.
 * - isDate: [Bool] If, true value should be a valid date.
 * - minLength: [Int] The minimum amount of characters the value should have.
 * - width: [String] the width of the input
 * - onChange: [function]: the function that updates the state value that defines the input value.
 * 
 * Suggestions sub-component props:
 * - suggestions: [Array] A list of suggestions to show the user.
 * - onSuggestionSelection: [function] When a suggestion is selected, TextInput returns all props that were sent, alongside the selected suggestion. (this.props, suggestion)
 * - suggestionsTitle: [String] An optional title to be displayed informing what kind of suggestions are being displayed.
 * 
*/

import React, { PureComponent } from 'react';
import moment from 'moment';
import Fuse from 'fuse.js';
import styles from './InputText.module.css';

class InputText extends PureComponent {

    state = {
        preselectedSuggestion: 0,
        showSuggestions: false,
        isHovering: false,
        shouldValidate: false // Used to trigger the validation rules.
    }

    fuseOptions = {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
            "normalizedValue",
        ]
    };
    fuse = new Fuse(this.props.suggestions, this.fuseOptions);

    listOfFilteredSuggestions = [];

    suggestionsRef = React.createRef();
    inputRef = React.createRef();

    UNSAFE_componentWillMount() {
        this.setupSuggestionsSearch(this.props.suggestions);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.suggestions !== nextProps.suggestions) {
            this.setupSuggestionsSearch(nextProps.suggestions)
        }
    }

    setupSuggestionsSearch(suggestions) {
        if (suggestions) {
            const normalizedList = suggestions.map(value => ({ 'value': value ? value : 'null', 'normalizedValue': value ? String(value).normalize('NFD').replace(/[\u0300-\u036f]/g, "") : 'null' }));
            this.fuse = new Fuse(normalizedList, this.fuseOptions);
        }
    }

    isValid() {
        if (!this.state.shouldValidate) {
            return true;
        }

        let isValid = true;

        if (this.props.value === null) {
            return false;
        }

        if (this.props.isRequired) {
            isValid = this.props.value.trim() !== '' && isValid;
        }

        if (this.props.minLength) {
            isValid = this.props.value.length >= this.props.minLength && isValid;
        }

        if (this.props.isDate) {
            isValid = moment(this.props.value, 'D/M/YYYY', true).isValid() && isValid;
        }

        if (this.props.isNumber) {
            isValid = (!isNaN(+this.props.value) && isFinite(this.props.value)) && isValid;
        }

        return isValid;
    }

    preselectSuggestion = (index) => {
        this.setState({ preselectedSuggestion: index, isHovering: true });
    }

    /**
     * Calls the onSuggestionSelection function from props, and hides the suggestions box.
     */
    selectSuggestion = (suggestion) => {
        this.props.onSuggestionSelection(this.props, suggestion);
        this.inputRef.current.blur();
        this.setState({ showSuggestions: false });
    }

    /**
     * Returns the filtered suggestions based on what the user typed.
     * If no results, the whole suggestions array is returned.
     */
    filteredSuggestions() {
        const normalizedText = String(this.props.value).normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        let results = this.fuse.search(normalizedText);
        const values = results.length > 0 ? results.map(item => item.value) : this.props.suggestions.map(suggestion => suggestion ? suggestion : "null");
        // We update listOfFilteredSuggestions so we can retrieve the selected suggestion when the user press Enter.
        this.listOfFilteredSuggestions = values;
        return values;
    }

    /**
     * Handles key press for selecting suggestions.
     */
    onKeyPress = (e) => {

        if (!this.props.suggestions) {
            return;
        }

        const maxHeight = 150;
        const lineHeight = 25;

        if (e.keyCode === 13) { // enter

            this.selectSuggestion(this.listOfFilteredSuggestions[this.state.preselectedSuggestion - 1]);

        } else if (e.keyCode === 40) { // down arrow

            this.setState(prevState => ({
                preselectedSuggestion: prevState.preselectedSuggestion + 1 > this.props.suggestions.length ? this.props.suggestions.length : prevState.preselectedSuggestion + 1
            }), () => {
                this.suggestionsRef.current.scrollTo(0, (this.state.preselectedSuggestion * lineHeight) - maxHeight);
            });

        } else if (e.keyCode === 38) { // up arrow
            this.setState(prevState => ({
                preselectedSuggestion: prevState.preselectedSuggestion === 1 ? 1 : prevState.preselectedSuggestion - 1
            }), () => {
                this.suggestionsRef.current.scrollTo(0, (this.state.preselectedSuggestion * lineHeight) - maxHeight);
            });
        }
    }

    /**
     * Called onBlur and onFocus. Only sets showsSuggestions as false if the mouse is not hovering the suggestions box.
     * If it is hovering, the box should not disappear as a click might be coming. 
     */
    handleSuggestionsVisibility = (isFocused) => {
        if (this.props.suggestions && isFocused) {
            this.setState({ showSuggestions: true, preselectedSuggestion: 1, isFocused: true, shouldValidate: true });
        } else if (!this.state.isHovering) {
            this.setState({ showSuggestions: false });
        } else {
            this.setState({ isFocused: false });
        }
    }

    /**
     * This function handles the state of isHovering. If the input is not focused onMouseOut, the function hides the suggestion box.
     */
    handleMouseOut = () => {
        this.setState({ isHovering: false }, () => {
            if (!this.state.isFocused) {
                this.handleSuggestionsVisibility(false);
            }
        });
    }

    /**
     * A sub component that renders the suggestions box if there are any suggestions.
     */
    renderSuggestions() {

        if (!this.props.suggestions) {
            return null;
        }

        let header = null;
        if (this.props.suggestionsTitle) {
            header = (
                <div className={styles.SuggestionsHeader}>
                    <div>{this.props.title}</div>
                </div>
            );
        }

        let listOfSuggestions = this.filteredSuggestions();

        return (
            <div className={styles.SuggestionsContainer}>
                <div className={[styles.Suggestions, this.state.showSuggestions ? null : styles.Hidden].join(' ')}>
                    {header}
                    <ul ref={this.suggestionsRef}>
                        {listOfSuggestions.map((suggestion, index) => (
                            <li
                                key={index + 1}
                                className={this.state.preselectedSuggestion === index + 1 ? styles.Preselected : null}
                                onMouseOver={() => this.preselectSuggestion(index + 1)}
                                onMouseOut={this.handleMouseOut}
                                onClick={() => this.selectSuggestion(suggestion)}
                            >
                                {suggestion}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }

    render() {
        let inputClasses = [styles.InputText];

        if (!this.isValid()) {
            inputClasses.push(styles.Invalid);
        }

        return (
            <div>
                <input
                    type="text"
                    className={inputClasses.join(' ')}
                    name={this.props.name}
                    value={this.props.value}
                    ref={this.inputRef}
                    autoComplete={this.props.autoComplete ? this.props.autoComplete : null}
                    onChange={this.props.onChange}
                    onKeyDown={this.onKeyPress}
                    onBlur={() => this.handleSuggestionsVisibility(false)}
                    onFocus={() => this.handleSuggestionsVisibility(true)}
                    style={{ width: this.props.width || "100%" }}
                />
                {this.renderSuggestions()}
            </div>
        );
    }

}

export default InputText;