/**
 * NavigationList.js | Component
 * Main navigation list, with all standard menu items.
 * 
 * Props: none.
 */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import styles from './NavigationList.module.css'
import NavigationItem from './NavigationItem/NavigationItem'
import * as routes from '../../../config/routes';

import dashboardIcon from '../../../assets/images/dashboard-icon@3x.png';
import catalogIcon from '../../../assets/images/catalog-icon@3x.png';
import markliiersIcon from '../../../assets/images/user-icon@3x.png';
import receiptIcon from '../../../assets/images/receipt-icon@3x.png';
import storeIcon from '../../../assets/images/store-icon@3x.png';
import pricesIcon from '../../../assets/images/price-tag-icon@3x.png';
import errorsIcon from '../../../assets/images/errors-icon@3x.png';


const navigationList = (props) => (
    <ul className={styles.NavigationList}>
        <NavigationItem link={routes.HOME} icon={dashboardIcon} isActive>Dashboard</NavigationItem>
        <NavigationItem link={routes.CATALOG} icon={catalogIcon}>Catálogo</NavigationItem>
        <NavigationItem link={routes.USERS} icon={markliiersIcon}>Markliiers</NavigationItem>
        <NavigationItem link={routes.PURCHASES} icon={receiptIcon} notification={props.pendingReceipts}>Compras</NavigationItem>
        <NavigationItem link={routes.PRICES} icon={pricesIcon}>Preços</NavigationItem>
        <NavigationItem link={routes.STORES} icon={storeIcon}>Mercados</NavigationItem>
        <NavigationItem link={routes.ERRORS} icon={errorsIcon}>Erros</NavigationItem>
    </ul>
);

const mapStateToProps = (state) => {
    return {
        pendingReceipts: state.purchases.unprocessedPurchases
    }
}

export default withRouter(connect(mapStateToProps)(navigationList));
// export default navigationList;