/**
 * authActions.js | Redux Actions File
 * All authentication actions that manage global state.
 * 
 */

import * as actionTypes from './types';

export const authSuccess = (userData) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        userData: userData
    };
};

export const logOut = () => {
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};
