/**
 * Input.js | Component
 * A generic Input. Makes it easy to recreate forms programatically. 
 * 
 * Props: 
 * - element: An object of InputElement.
 * - label: A label to describe the input, goes on top input.
 * - innerRef: A React Ref that will be passed to the parent component, so we can access this Input on the DOM. 
 *             We are using React.forwardRef() for this.
 */


import React, { Component } from 'react';
import moment from 'moment';
import styles from './Input.module.css';

class Input extends Component {

    render() {

        let inputElement = null;

        let inputClasses = [styles.InputElement];

        if (!this.props.element.isValid && this.props.element.shouldValidate && this.props.element.isTouched) {
            inputClasses.push(styles.Invalid);
        }

        const defaultInputElement =
            <input
                name={this.props.element.key}
                ref={this.props.innerRef}
                className={inputClasses.join(' ')}
                onChange={this.props.onChange}
                {...this.props.element.elementConfig}
            />;

        switch (this.props.element.elementType) {

            case ('input'):
                inputElement = defaultInputElement
                break;

            case ('select'):
                inputElement = (
                    <select
                        name={this.props.element.key}
                        ref={this.props.innerRef}
                        className={inputClasses.join(' ')}
                        onChange={this.props.onChange}
                        {...this.props.element.elementConfig}
                    >
                        {this.props.element.selectOptions.map(option => (
                            <option key={option.value} value={option.value}>{option.displayValue}</option>
                        ))}

                    </select>
                );
                break;
            
            case ('checkbox'):
                inputElement = (
                    <input 
                        type='checkbox' 
                        className={styles.Checkbox} 
                        ref={this.props.innerRef} 
                        onChange={this.props.onChange}
                        checked={this.props.element.value}
                        {...this.props.element.elementConfig} 
                    />
                );
                break;

            default:
                inputElement = defaultInputElement
        }

        return (
            <div className={styles.Input} >
                <label>{this.props.label}</label>
                {inputElement}
            </div>
        );
    }
}

export default React.forwardRef((props, ref) => <Input innerRef={ref} {...props} />);



/**
 * * InputElement | Data Model
 * An object containing all necessary information to create an input.
 * 
 * Parameters:
 * 
 * @param {string} elementType The element choice: input, textfield, select etc.
 * @param {string} type The type of the element: text, email, password, checkbox...
 * @param {string} placeholder Which placeholder to add. Optional.
 * @param {string} defaultValue A default value to insert in the input. Optional.
 * @param {array} selectOptions Only for elementType = select. Which options to include in the dropdown.
 * @param {object} validation An key-value pair containing rules to which the input value must comply. 
 *                            Currently available rules: required, minLength.
 *                            Example: 
 *                            
 * 
 * Example:
 * loginForm = {
        'email': new InputElement('email', 'input', 'email', 'Usuário', 'farias89@gmail.com', null, { 'required': true }),
        'password': new InputElement('password', 'input', 'password', 'Senha', null, null, { 'required': true, 'minLength': 6 })
        'userType': new InputElement('userType', 'input', 'select', null, 'admin', ['Admin', 'Usuário'], { 'required': true })
    };
 * 
 */

export class InputElement {
    constructor(key, elementType, type, placeholder = '', defaultValue = '', selectOptions = [], validation = {}) {
        this.key = key;
        this.elementType = elementType;
        this.elementConfig = {
            type: type,
            placeholder: placeholder,
            defaultValue: defaultValue
        }
        this.selectOptions = selectOptions;
        this.validation = validation;
        this.isValid = false;
        this.updateInputValue(defaultValue);
        this.isTouched = false;
        this.shouldValidate = validation === {} ? false : true;
    }

    updateInputValue(value = '') {
        this.isTouched = true;
        this.value = value;
        this.validate();
    }

    validate() {

        let isValid = true;

        if (this.value === null) { 
            this.isValid = false;
            return;
        }

        if (this.elementConfig.type === 'checkbox') {
            return;
        }

        if (this.validation.required === true) {
            isValid = this.value.trim() !== '' && isValid;
        }

        if (this.validation.minLength) {
            isValid = this.value.length >= this.validation.minLength && isValid;
        }

        if (this.validation.isDate === true) {
            isValid = moment(this.value, 'D/M/YYYY',true).isValid() && isValid;
        }

        if (this.validation.isNumber === true) {
            isValid = (!isNaN(+this.value) && isFinite(this.value)) && isValid;
        }

        this.isValid = isValid
    }
}