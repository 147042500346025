/**
 * PurchaseItem.js | Component
 * A card showing key information about a purchase: purchaser name, location, date, total amount.
 * 
 * Props:
 * - purchase: an object of Purchase.
 */

import React from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';

import Card from '../../../../components/Card/Card'
import ProfileImage from '../../../../components/Profile/ProfileImage/ProfileImage';
import styles from './PurchaseItem.module.css'

// Props:
// - purchase.name:
// - purchase.address:
// - purchase.amount:
// - purchase.city:
// - purchase.date: 
// - purchase.imgURL

const purchaseItem = (props) => {

    let address = 'Aguardando processamento...';
    if (props.purchase.receiptsUIDs.length === 0) {
        address = 'Aguardando nota fiscal...';
    } else if (props.purchase.storeAddress) {
        address = props.purchase.storeAddress;
    }

    let purchaseTitle = "Compra de " + props.purchase.uploadDate.toLocaleDateString("pt-BR");
    if (props.purchase.storeName !== null) {
        purchaseTitle = props.purchase.storeName;
    }

    var profileColor = 'green';
    if (props.purchase.receiptsUIDs.length === 0) {
        profileColor = 'yellow';
    }
    if (props.purchase.receiptStatus) {
        profileColor = 'red';
    }

    return (
        <div className={styles.Container}>
            <Card>
                <div className={styles.TopContainer}>
                    <div className={styles.ProfileContainer}>
                        <ProfileImage 
                            displayName={props.purchase.purchaserDisplayName} 
                            imgURL={props.purchase.purchaserProfileURL} 
                            color={profileColor} />
                    </div>
                    <div className={styles.MainContainer}>
                        <div className={styles.PurchaserName}>{purchaseTitle}</div>
                        <div className={styles.StoreName}>{address}</div>
                        <div className={styles.StoreName}>{props.purchase.purchaserDisplayName}</div>
                    </div>
                    <div className={styles.PriceContainer}>
                        <div className={styles.PriceTag}>
                            <div className={styles.Currency}>R$</div>
                            <div className={styles.Price}>{props.purchase.amountPaid.toFixed(2)}</div>
                        </div>
                    </div>
                </div>

                <div className={styles.BottomContainer}>
                    <div className={styles.SmallLabel}>{props.purchase.city ? props.purchase.city + ', ' + props.purchase.state : 'Local desconhecido'}</div>
                    <div className={styles.SmallLabel}>{moment(props.purchase.date).locale('pt-br').startOf('min').fromNow()}</div>
                </div>
            </Card>
        </div>
    );
}

export default purchaseItem;