import * as actionTypes from '../actions/types';
import { updateObject } from '../utility';

const initialState = {
    currentUser: JSON.parse(localStorage.getItem('authUser'))
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);

        default:
            return state;
    };

};

const authSuccess = (state, action) => {
    console.log('[Auth] Authenticated successfully...');
    localStorage.setItem('authUser', JSON.stringify(action.userData));
    return updateObject(state, { currentUser: action.userData});
}

const authLogout = (state, action) => {
    console.log('[Auth] Logging out...');
    localStorage.removeItem('authUser');
    return updateObject(state, { currentUser: null })
}



export default reducer;