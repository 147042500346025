/**
 * UpdateMetrics.js | Container
 * Performs aggregation methods to create consolidated metrics.
 * 
 *  
 * Props: 
 * - metric: [String]
 * 
 */

import React, { Component } from 'react';
import { withFirebase } from '../../components/Firebase';
import styles from './UpdateMetrics.module.css';
import updateIcon from '../../assets/images/update-icon@3x.png';
import Purchase from '../../models/Purchase';
import User from '../../models/User';
import Store from '../../models/Store';

class UpdateMetrics extends Component {

    updateAllMetrics = () => {
        let confirmationMessage = "Tem certeza que gostaria de atualizar TODAS as métricas?";
        if (this.props.metric === "purchases")
            confirmationMessage = "Tem certeza que gostaria de atualizar as métricas de COMPRAS?";
        if (this.props.metric === "stores")
            confirmationMessage = "Tem certeza que gostaria de atualizar as métricas de MERCADOS?";
        if (this.props.metric === "users")
            confirmationMessage = "Tem certeza que gostaria de atualizar as métricas de USUÁRIOS?";

        if (window.confirm(confirmationMessage)) {
            if (this.props.metric === "purchases")
                this.updatePurchasesMetrics()
            else if (this.props.metric === "stores")
                this.updateStoresMetrics();
            else if (this.props.metric === "users")
                this.updateUsersMetrics();
            else {
                this.updatePurchasesMetrics()
                this.updateUsersMetrics();
                this.updateStoresMetrics();
            }
        }
    }

    async updateStoresMetrics() {
        const storesSnapshot = await this.props.firebase.stores().get();

        let allStores = [];
        storesSnapshot.docs.forEach(doc => { allStores.push(new Store(doc.id, doc.data())) });

        // Gets the unique names cities and states.
        const cityDocIDs = [...new Set(allStores.filter(store => store.state !== null).map(store => (store.city + '-' + store.state)))];


        // Calculate how many stores were registered by month and year.
        const years = [...new Set(allStores.map(store => (store.registrationDate.getFullYear())))];
        const storesByYear = {}
        const newStoresPerYear = {};   // { 2018: 143, 2019: 40 }
        const newStoresPerMonth = {};   // { 2018: 143, 2019: 40 }

        years.forEach(year => {

            const currentYearStores = allStores.filter(store => (store.registrationDate.getFullYear() === year));
            storesByYear[year] = currentYearStores;
            newStoresPerYear[year] = currentYearStores.length;

            for (var i = 0; i < 12; i++) {
                const month = i;
                const currentIndex = year + '-' + month;
                const currentMonthStores = currentYearStores.filter(store => (store.registrationDate.getMonth() === month));
                newStoresPerMonth[currentIndex] = currentMonthStores.length;
            }
        });

        const storesMetrics = {
            'storeTotalCount': allStores.length,
            'newStoresPerYear': newStoresPerYear,
            'newStoresPerMonth': newStoresPerMonth
        };

        // Calculates the number of stores per city.
        let storesPerCity = {};
        var batch = this.props.firebase.db.batch();
        cityDocIDs.forEach(id => {
            const city = id.split('-')[0];
            const state = id.split('-')[1];
            storesPerCity[id] = allStores.filter(store => (store.city === city && store.state === state)).reduce((partialSum) => partialSum + 1, 0);

            var cityRef = this.props.firebase.metricsOf('Locations').collection('Cities').doc(id);
            batch.set(cityRef, { numberOfStores: storesPerCity[id] }, { merge: true });
        });

        try {
            await batch.commit();
            await this.props.firebase.metricsOf('Stores').set(storesMetrics, { merge: true });
            console.log('Store metrics updated successfully!');
        } catch (error) {
            console.log(error);
        }
    }

    async updateUsersMetrics() {
        const usersSnapshot = await this.props.firebase.users().get();

        let allUsers = [];
        usersSnapshot.docs.forEach(doc => { allUsers.push(new User(doc.id, doc.data())) });

        const usersOnTheDark = allUsers.filter(user => user.city === null).length;
        console.log(usersOnTheDark, 'users do not have a location.');

        // Gets the unique names cities and states.
        const cityDocIDs = [...new Set(allUsers.filter(user => user.state !== null).map(user => (user.city + '-' + user.state)))];

        // Calculates the number of prices per city, based on the quantityOfItems property of purchases.
        let markliiersPerCity = {};
        var batch = this.props.firebase.db.batch();
        cityDocIDs.forEach(id => {
            const city = id.split('-')[0];
            const state = id.split('-')[1];
            markliiersPerCity[id] = allUsers.filter(user => (user.city === city && user.state === state)).reduce((partialSum) => partialSum + 1, 0);

            var cityRef = this.props.firebase.metricsOf('Locations').collection('Cities').doc(id);
            batch.set(cityRef, { numberOfMarkliiers: markliiersPerCity[id] }, { merge: true });
        });

        const userMetrics = {
            'usersWithoutLocation': usersOnTheDark,
            'totalUsers': allUsers.length
        }

        try {
            await this.props.firebase.metricsOf('Users').set(userMetrics, { merge: true });
            await batch.commit()
            console.log('User metrics updated successfully!');
        } catch (error) {
            console.log(error);
        }
    }

    async updatePurchasesMetrics() {
        const purchasesSnapshot = await this.props.firebase.purchases().get();

        let allPurchases = [];
        purchasesSnapshot.docs.forEach(doc => { if (doc.data().uploadDate) allPurchases.push(new Purchase(doc.id, doc.data())) });
        const years = [...new Set(allPurchases.map(purchase => (purchase.uploadDate.getFullYear())))];

        const purchasesByYear = {};         // { 2018: [], 2019: [] }
        const purchasesByMonth = {};        // { 2018-0: [], 2018-1: [] }
        const purchaseCountPerYear = {};   // { 2018: 143, 2019: 40 }
        const purchaseCountPerMonth = {};   // { 2018: 143, 2019: 40 }
        const purchaseAmountPerYear = {};   // { 2018: 1341.40, 2019: 2431.54 }
        const purchaseAmountPerMonth = {};   // { 2018: 1341.40, 2019: 2431.54 }

        years.forEach(year => {

            const currentYearPurchases = allPurchases.filter(purchase => (purchase.uploadDate.getFullYear() === year));
            purchasesByYear[year] = currentYearPurchases;
            purchaseCountPerYear[year] = currentYearPurchases.length;
            purchaseAmountPerYear[year] = currentYearPurchases.reduce((partialSum, purchase) => partialSum + purchase.amountPaid, 0).toFixed(2);

            for (var i = 0; i < 12; i++) {
                const month = i;
                const currentIndex = year + '-' + month;
                // We only consider purchases who have receipt.
                const currentMonthPurchases = currentYearPurchases.filter(purchase => (purchase.uploadDate.getMonth() === month && purchase.receiptsUIDs.length > 0));
                purchaseCountPerMonth[currentIndex] = currentMonthPurchases.length;
                purchaseAmountPerMonth[currentIndex] = currentMonthPurchases.reduce((partialSum, purchase) => partialSum + purchase.amountPaid, 0).toFixed(2);
                purchasesByMonth[currentIndex] = currentMonthPurchases;
            }

        });

        const unprocessedPurchases = allPurchases.filter(purchase => (purchase.hasBeenProcessed === false && purchase.receiptStatus == null)).length;
        const purchaseTotalAmount = allPurchases.reduce((partialSum, purchase) => partialSum + purchase.amountPaid, 0).toFixed(2);

        const purchaseMetrics = {
            'purchaseTotalCount': allPurchases.length,
            'purchaseTotalAmount': purchaseTotalAmount,
            'purchaseCountPerYear': purchaseCountPerYear,
            'purchaseAmountPerYear': purchaseAmountPerYear,
            'purchaseCountPerMonth': purchaseCountPerMonth,
            'purchaseAmountPerMonth': purchaseAmountPerMonth,
            'unprocessedPurchases': unprocessedPurchases
        };

        await this.updatePurchasesWithoutLocationInformation(allPurchases);
        await this.updatePurchasesWithoutQuantityInformation(allPurchases);

        // Gets the unique names cities and states.
        const cities = [...new Set(allPurchases.filter(purchase => purchase.city !== null).map(purchase => (purchase.city)))];
        const states = [...new Set(allPurchases.filter(purchase => purchase.state !== null).map(purchase => (purchase.state)))];
        const cityDocIDs = [...new Set(allPurchases.filter(purchase => purchase.state !== null).map(purchase => (purchase.city + '-' + purchase.state)))];

        // Calculates the number of prices per city, based on the quantityOfItems property of purchases.
        let pricesPerCity = {};
        var locationBatch = this.props.firebase.db.batch();
        cityDocIDs.forEach(id => {
            const city = id.split('-')[0];
            const state = id.split('-')[1];
            pricesPerCity[id] = allPurchases.filter(purchase => (purchase.city === city && purchase.state === state)).reduce((partialSum, purchase) => partialSum + purchase.quantityOfItems, 0);

            var cityRef = this.props.firebase.metricsOf('Locations').collection('Cities').doc(id);
            locationBatch.set(cityRef, { numberOfPrices: pricesPerCity[id] }, { merge: true });
        });

        const locationMetrics = {
            'numberOfCities': cities.length,
            'numberOfStates': states.length
        }

        try {
            await this.props.firebase.metricsOf('Purchases').set(purchaseMetrics, { merge: true });
            await this.props.firebase.metricsOf('Locations').set(locationMetrics, { merge: true });
            await locationBatch.commit()
            console.log('Purchase metrics updated successfully!');
        } catch (error) {
            console.log(error);
        }

    }

    // This function will only execute if the purchase does not contain location information. 
    // It basically only fixes old purchases that didn't contain city and state properties.
    async updatePurchasesWithoutLocationInformation(allPurchases) {
        await Promise.all(allPurchases.map(async (purchase) => {
            if ((purchase.city === null || purchase.state === null) && purchase.storeID) {
                console.log('Updating purchase', purchase.uid, 'with storeID', purchase.storeID);
                const storeDoc = await this.props.firebase.store(purchase.storeID).get();
                if (storeDoc.exists) {
                    this.props.firebase.doSetOnPurchase(purchase.uid, { city: storeDoc.data().city, state: storeDoc.data().state });
                    purchase.city = storeDoc.data().city;
                    purchase.state = storeDoc.data().state;
                }
            }
        }));
    }

    // This function will only execute its contents if the purchase does not contain quantityOfItems information.
    // It basically only fixes old purchases that didn't contain this data for some reason.
    async updatePurchasesWithoutQuantityInformation(allPurchases) {
        let historyItemsWithoutPurchase = 0
        await Promise.all(allPurchases.map(async (purchase) => {
            if (purchase.storeID && purchase.quantityOfItems === 0) {
                const historySnapshot = await this.props.firebase.history().where('purchaseID', '==', purchase.uid).get();
                if (historySnapshot.size > 0) {
                    console.log('Updating purchase', purchase.uid, 'with quantity of', historySnapshot.size, 'items.');
                    this.props.firebase.doSetOnPurchase(purchase.uid, { quantityOfItems: historySnapshot.size });
                    purchase.quantityOfItems = historySnapshot.size;
                } else {
                    historyItemsWithoutPurchase += 1
                }
            }
        }));
        console.log(historyItemsWithoutPurchase, 'itens de History não possuem purchaseID.');
    }


    render() {

        let content = this.props.children;
        if (this.props.type === 'icon') {
            content = <img src={updateIcon} className={styles.Icon} alt='Atualizar'></img>
        }

        return (
            <div className={styles.Container} onClick={this.updateAllMetrics}>
                {content}
            </div>
        );
    }
}

export default withFirebase(UpdateMetrics);