/**
 * Section.js | Component
 * Sections of the layout canvas. Creates structure for the responsive layout.
 * Also adds a section title.
 * 
 * Props: 
 * - title: the title of the section.
 */

import React from 'react';

import styles from './Section.module.css';

// Props:
// - title: self explanatory.
// - isCollapsable: if the section can be collapsed or not.

const section = ( props ) => (
    <div className={styles.Section}>
        <h2>{props.title}</h2>
        <div className={styles.Content}>
            {props.children}
        </div>
    </div>
);

export default section;