import * as actionTypes from '../actions/types';
import { updateObject } from '../utility';

const initialState = {
    catalog: []
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.ADD_CATALOG_ITEM: return addCatalogItem(state, action);
        case actionTypes.REMOVE_CATALOG_ITEM: return removeCatalogItem(state, action);
        case actionTypes.UPDATE_CATALOG_ITEM: return updateCatalogItem(state, action);

        default:
            return state;
    };

};

const addCatalogItem = (state, action) => {
    return updateObject(state, { catalog: state.catalog.concat(action.catalogItem)});
}

const removeCatalogItem = (state, action) => {
    const updatedArray = state.catalog.filter(catalogItem => catalogItem.uid !== action.catalogItem.uid);
    return updateObject(state, { catalog: updatedArray});
}

const updateCatalogItem = (state, action) => {
    const updatedArray = state.catalog.map(catalogItem => {
        if (catalogItem.uid === action.catalogItem.uid) {
            return action.catalogItem;
        } else {
            return catalogItem;
        }
    });
    return updateObject(state, { catalog: updatedArray});
}

export default reducer;