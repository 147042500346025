/**
 * PurchaseView.js | Container
 * The purchases view of the app. It's the entrance page for all other functionality regarding Purchases.
 * It shows key statistics about purchases and a list of recent unprocessed purchases.
 * I have changed this line of code. And now I have changed again! 
 * 
 * Local state:
 * - purchases.
 * 
 */

import React, { Component } from 'react';

// Libraries
import { withFirebase } from '../../components/Firebase';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../../store/actions';
import * as routes from '../../config/routes';

import Section from '../../components/UI/Section/Section';
import PurchaseList from '../PurchasesView/PurchaseList/PurchaseList';
import StatsCard from '../../components/UI/StatsCard/StatsCard';
import Utils from '../../utils/Utils';

import styles from './PurchasesView.module.css';

class PurchasesView extends Component {

    state = {
        purchaseTotalCount: null,
        purchaseTotalAmount: null,
        purchaseAmountPerYear: null,
        purchaseAmountPerMonth: null,
        purchaseCountPerMonth: null,
        purchaseCountPerYear: null
    }

    today = new Date();
    currentMonthIndex = '';
    previousMonthIndex = '';

    componentWillUnmount() {
        this.removeMetricsListener();
    }

    setMetricsIndices() {
        this.currentMonthIndex = this.today.getFullYear() + '-' + this.today.getMonth();
        this.previousMonthIndex = this.today.getFullYear() + '-' + (this.today.getMonth() - 1);
        if (this.today.getMonth() === 0)
            this.previousMonthIndex = (this.today.getFullYear() - 1) + '-11';
    }

    componentDidMount() {
        this.props.setPageTitle("Compras");
        this.setMetricsIndices();
        this.removeMetricsListener = this.props.firebase.metricsOf('Purchases').onSnapshot(doc => {
            this.setState({
                purchaseTotalAmount: doc.data().purchaseTotalAmount || null,
                purchaseTotalCount: doc.data().purchaseTotalCount || null,
                purchaseAmountPerMonth: doc.data().purchaseAmountPerMonth || null,
                purchaseCountPerMonth: doc.data().purchaseCountPerMonth || null
            })
        });
    }

    componentDidUpdate() {
        this.props.setPageTitle("Compras");
    }

    render() {

        let purchaseList = 'Todas as compras foram processadas! 👏';

        if (this.props.purchaseList.length > 0) {
            purchaseList = <PurchaseList purchaseList={this.props.purchaseList} />
        }

        let currentMonthAmount = null;
        let previousMonthAmount = null;
        let currentMonthCount = null;
        let previousMonthCount = null;
        let amountVariation = null;
        let countVariation = null;
        let totalAmountVariation = null
        let totalCountVariation = null

        if (this.state.purchaseAmountPerMonth && this.state.purchaseCountPerMonth) {
            currentMonthAmount = this.state.purchaseAmountPerMonth[this.currentMonthIndex];
            previousMonthAmount = this.state.purchaseAmountPerMonth[this.previousMonthIndex];
            currentMonthCount = this.state.purchaseCountPerMonth[this.currentMonthIndex];
            previousMonthCount = this.state.purchaseCountPerMonth[this.previousMonthIndex];
            amountVariation = (currentMonthAmount / previousMonthAmount) - 1;
            countVariation = (currentMonthCount / previousMonthCount) - 1;
            totalAmountVariation = (currentMonthAmount / (this.state.purchaseTotalAmount - currentMonthAmount));
            totalCountVariation = (currentMonthCount / (this.state.purchaseTotalCount - currentMonthCount));
        }

        return (
            <div className={styles.FlexContainer}>
                <div className={styles.StatsSection}>
                    <Section title='Principais Métricas'>
                        <div className={styles.StatsContainer}>
                            <StatsCard
                                title="Valor Total Processado"
                                value={Utils.currencyValue(this.state.purchaseTotalAmount)}
                                variation={totalAmountVariation}
                                description="Taxa de crescimento"
                                caption={"Até mês passado: " + Utils.currencyValue(this.state.purchaseTotalAmount - currentMonthAmount) + "\nEste mês: " + Utils.currencyValue(currentMonthAmount)} />
                            <StatsCard
                                title="Valor Processado neste Mês"
                                value={Utils.currencyValue(currentMonthAmount)}
                                variation={amountVariation}
                                description="Comparado ao mês anterior"
                                caption={"Mês anterior: " + Utils.currencyValue(previousMonthAmount)} />
                            <StatsCard
                                title="Compras Totais"
                                value={this.state.purchaseTotalCount || "-"}
                                variation={totalCountVariation}
                                description="Taxa de crescimento"
                                caption={"Até mês passado: " + (this.state.purchaseTotalCount - currentMonthCount) + "\nEste mês: " + currentMonthCount} />
                            <StatsCard
                                title="Compras neste mês"
                                value={currentMonthCount || "-"}
                                variation={countVariation}
                                description="Comparado ao mês anterior"
                                caption={"Mês anterior: " + previousMonthCount} />
                        </div>
                    </Section>
                </div>

                <div className={styles.PurchasesSection}>
                    <Section title='Últimas Compras'>

                        <Link key='see-all-purchases' to={routes.PURCHASES_ALL}>
                            Ver todas >
                        </Link>
                        <br />
                        <br />

                        {purchaseList}

                    </Section>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        purchaseList: state.purchases.purchaseList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (title) => dispatch(actions.setTitle(title))
    }
}

export default withFirebase(connect(mapStateToProps, mapDispatchToProps)(PurchasesView));