
class Admin {

    constructor(payload) {
        this.displayName = null;
        this.email = null;
        this.uid = null;
        this.isAdmin = false;
        this.withFirestoreData(payload);
    }

    withFirebaseAuthData(uid, displayName, email, profileURL = null) {
        this.uid = uid;
        this.displayName = displayName;
        this.email = email;
        this.profileURL = profileURL;
    }

    withFirestoreData(payload) {
        this.uid = payload.uid;
        this.displayName = payload.displayName !== undefined ? payload.displayName : this.displayName;
        this.email = payload.email;
        this.firstName = payload.firstName;
        this.lastName = payload.lastName;
        this.profileURL = payload.profilePictureURL;
        this.isAdmin = payload.isAdmin;

        if (this.displayName === null || this.displayName === undefined) {
            this.displayName = this.firstName + ' ' + this.lastName;
        }
    }
}

export default Admin;