/**
 * Card.js | Component
 * A wrapper component styling the look of a card. 
 * Right now it could be substituted by a simple css class, but not sure.
 * 
 * Props:
 * - width: CSS string of width. '10px', '100%' etc.
 */

import React from 'react';
import styles from './Card.module.css';

const card = ( props ) => (
    <div className={styles.Card} style={{width: props.width}}>
        {props.children}
    </div>
);

export default card;