/**
 * Utils.js
 * An utility class with methods used throughout the application.
 * 
 */

class Utils {

    static codeLooksLikeGTIN = (value) => {
        let GTINRegex = /((?:0*)[0-9]{8}|(?:0*)[0-9]{12,14})$/gm
        return GTINRegex.test(value)
    }

    static getProperGTIN = (value) => {
        let gtinCode = value.replace(/^0+(?=\d)/gm, '');
        switch (gtinCode.length) {
            case 6:
                gtinCode = '0' + gtinCode;
                break;
            case 11:
                gtinCode = '00' + gtinCode;
                break;
            default:
                break;
        }
        if (gtinCode) {
            return gtinCode;
        }
        return value
    }

    // Checks whether value is a valid GTIN code or not. Returns a boolean.
    static isValidGTIN = (value) => {
        if (!value)
            return false

        switch (value.length) {
            case 8:
                value = "000000" + value;
                break;
            case 12:
                value = "00" + value;
                break;
            case 13:
                value = "0" + value;
                break;
            case 14:
                break;
            default:
                // wrong number of digits
                return false;
        }

        var barcode = value.substring(0, value.length - 1);
        var checksum = parseInt(value.substring(value.length - 1), 10);
        var calcSum = 0;
        var calcChecksum = 0;
        const minValidLength = 8;
        const maxValidLength = 14;

        if (barcode.length < minValidLength || barcode.length > maxValidLength)
            return false;

        barcode.split('').map((number, index) => {
            number = parseInt(number, 10);
            if (value.length % 2 === 0)
                index += 1;
            if (index % 2 === 0)
                calcSum += number;
            else
                calcSum += number * 3;
            return number;
        });

        calcSum %= 10;
        calcChecksum = (calcSum === 0) ? 0 : (10 - calcSum);

        if (calcChecksum !== checksum)
            return false;
        return true;
    }

    // Formats a number into brazilian reais format. Returns a string.
    static currencyValue = (number) => {
        if (!number) return "R$ -";
        var formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });

        return formatter.format(number);
    }

    static randomID(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
}

export default Utils;