/**
 * Sidebar.js | Component
 * The left sidebar used in the Layout. 
 * 
 * Props: 
 * - isVisible: tells the sidebar if it should be visible or not.
 * - toggleSidebar(): method to hide/show the sidebar.
 */

import React from 'react'
import styles from './Sidebar.module.css'
import Logo from '../Logo/Logo'
import NavigationList from '../Navigation/NavigationList/NavigationList'
import SidebarToggle from '../Sidebar/SidebarToggle/SidebarToggle'

const Sidebar = (props) => {

    let classes = [styles.Sidebar, styles.Hidden]

    if (props.isVisible) {
        classes = [styles.Sidebar, styles.Visible]
    }

    const environment = process.env.NODE_ENV === 'production' ? null : '(Dev)';

    return (
        <div className={classes.join(' ')}>
            <div className={styles.TopHeader}>
                <div className={styles.Logo}>
                    <Logo />
                </div>
                <SidebarToggle isSidebarVisible={props.isVisible} toggleSidebar={props.toggleSidebar} color='white' />
            </div>
            <nav>
                <NavigationList />
            </nav>
            <div className={styles.Version}> Marklii Control Tower {environment} - v.1.1.7</div>
        </div>
    );
};

export default Sidebar;