/** 
 * context.js
 * 
 * The Firebase Context from the Firebase module (folder) is used to provide a Firebase instance to your entire 
 * application in the src/index.js file. Doing it this way, we can be assured that Firebase is only 
 * instantiated once and that it is injected via React’s Context API to React’s component tree.
 * 
 * Reference: https://www.robinwieruch.de/complete-firebase-authentication-react-tutorial/
 * 
 */

import React from 'react';

const FirebaseContext = React.createContext(null);

export const withFirebase = Component => props => (
    <FirebaseContext.Consumer>
        {firebase => <Component {...props} firebase={firebase} />}
    </FirebaseContext.Consumer>
);

export default FirebaseContext;

