/**
 * SidebarToggle.js | Component
 * An icon that, when clicked, toggles the visibility of the Sidebar. 
 * Currently used in the Sidebar and in the Header.
 * 
 * Props: 
 * - onClick: method for when clicking on the Toggle.
 * - isSidebarVisible: boolean indicating if the sidebar is opened.
 * - color: 'white' or 'black'. Sets the color of the icon.
 * - hideWhenVisible: Hides the toggle when the sidebar is visible.
 */

import React from 'react';
import sidebarIcon from '../../../assets/images/sidebar-icon@3x.png'
import sidebarIconWhite from '../../../assets/images/sidebar-icon-white@3x.png'
import styles from './SidebarToggle.module.css'

const sidebarToggle = ( props ) => {

    let classes = [styles.Toggle, styles.Closed];
    if (props.isSidebarVisible) {
        if (props.hideWhenVisible) {
            classes = [styles.Toggle, styles.Hidden];
        } else {
            classes = [styles.Toggle, styles.Opened];
        }
    }

    let icon = sidebarIcon;
    if (props.color === 'white') {
        icon = sidebarIconWhite
    }

    return (
        <div className={classes.join(' ')} onClick={props.toggleSidebar}>
            <img src={icon} alt='Esconder/Mostrar Menu' />
        </div>
    );
}

export default sidebarToggle;