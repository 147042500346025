/**
 * Firebase/index.js
 * 
 * This file encapsulates all Firebase exports so we can easily use it throughout the app.
 * 
 */

import FirebaseContext, { withFirebase } from './context';
import Firebase from './firebase';

export default Firebase;

export { FirebaseContext, withFirebase };