

import React, { Component } from 'react';
// Libraries
import { withFirebase } from '../../../components/Firebase';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import * as routes from '../../../config/routes';
import { withRouter } from 'react-router-dom';

import Purchase from '../../../models/Purchase';
import Button from '../../../components/UI/Button/Button';
import ProfileImage from '../../../components/Profile/ProfileImage/ProfileImage';

import styles from './AllPurchasesView.module.css';

class AllPurchasesView extends Component {

    state = {
        purchases: [],
    }
    startDocuments = [];

    componentDidMount() {
        this.props.setPageTitle("Todas as compras");
        this.getPurchases();
    }

    async getPurchases(direction) {
        let query = this.props.firebase.purchases()
            .orderBy('purchaseDate', 'desc')
            .limit(10);

        if (direction === 'previous') {
            // We need to remove twice so we can go back 1 page.
            this.startDocuments.pop();
            this.startDocuments.pop();
        }

        if (direction && this.startDocuments.length > 0) {
            query = query.startAt(this.startDocuments[this.startDocuments.length - 1]);
        }

        const purchasesSnapshot = await query.get();

        const _purchases = [];
        let _lastDoc;
        purchasesSnapshot.docs.forEach(doc => {
            _lastDoc = doc;
            const purchase = new Purchase(doc.id, doc.data());
            _purchases.push(purchase);
        });

        this.startDocuments.push(_lastDoc);
        this.setState({ purchases: _purchases });
        this.props.setPageTitle("Todas as compras | Página " + this.startDocuments.length);
    }

    rowForProduct(purchase) {
        var profileColor = 'green';
        if (purchase.hasBeenProcessed) {
            profileColor = 'gray';
        } else if (purchase.receiptStatus && purchase.receiptStatus.toLowerCase() !== 'waiting') {
            profileColor = 'red';
        } else if (purchase.receiptsUIDs.length === 0) {
            profileColor = 'yellow';
        }

        var status = '-';
        if (purchase.hasBeenProcessed) {
            status = 'Guardada';
        } else if (purchase.receiptsUIDs.length === 0) {
            status = 'Sem nota fiscal';
        } else if (purchase.receiptStatus) {
            if (purchase.receiptStatus.toLowerCase() === 'nostoreinfo') {
                status = 'Sem mercado';
            }
            if (purchase.receiptStatus.toLowerCase() === 'incomplete') {
                status = 'Incompleta';
            }
            if (purchase.receiptStatus.toLowerCase() === 'invalid') {
                status = 'Inválida';
            }
        } else if (purchase.receiptsUIDs.length > 0) {
            status = 'Aguardando processamento...';
        }

        let location = <div>-</div>;
        if (purchase.city)
            location = <div>{purchase.city + ', ' + purchase.state}</div>;

        return (
            <tr key={purchase.uid} onClick={() => { this.props.history.push(routes.READ_PURCHASES + '/' + purchase.uid) }}>
                <td></td>
                <td>{purchase.purchaseDate.toLocaleDateString("pt-BR")}</td>
                <td>
                    <div className={styles.ProfileContainer}>
                        <ProfileImage
                            displayName={purchase.purchaserDisplayName}
                            imgURL={purchase.purchaserProfileURL}
                            color={profileColor}
                        />
                    </div>
                </td>
                <td>{purchase.purchaserDisplayName}</td>
                <td>{location}</td>
                <td>{purchase.storeName}</td>
                <td>{status}</td>
                <td>{purchase.purchasers.length}</td>
                <td>{purchase.hasGivenPoints ? "Sim" : "Não"}</td>
                <td>{purchase.quantityOfItems}</td>
                <td>R$ {purchase.amountPaid.toFixed(2)}</td>
            </tr >
        );
    }

    render() {



        return (
            <div className={styles.Container}>
                <div className={styles.TopButtons}>
                    <div><Button size="small" icon='back' onClick={() => this.getPurchases('previous')} disabled={this.startDocuments.length === 1} /></div>
                    <div><Button size="small" icon='forward' onClick={() => this.getPurchases('next')} /></div>
                </div>
                <div className={styles.TableContainer}>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Data</th>
                                <th colSpan="2">Markliier</th>
                                <th>Cidade</th>
                                <th>Mercado</th>
                                <th>Status</th>
                                <th>Compradores</th>
                                <th>Pontos</th>
                                <th>Itens</th>
                                <th>Valor</th>
                            </tr>
                            <tr>
                                <th colSpan='10'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.purchases.map(product => {
                                return this.rowForProduct(product);
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        purchaseList: state.purchases.purchaseList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (title) => dispatch(actions.setTitle(title))
    }
}

export default withFirebase(withRouter(connect(mapStateToProps, mapDispatchToProps)(AllPurchasesView)));