/**
 * firebase.js
 * 
 * A Firebase class to initialize firebase with the right configuration, only once.
 * 
 * 
 */

import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

const prodConfig = {
    apiKey: process.env.REACT_APP_PROD_API_KEY,
    authDomain: process.env.REACT_APP_PROD_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_PROD_DATABASE_URL,
    projectId: process.env.REACT_APP_PROD_PROJECT_ID,
    storageBucket: process.env.REACT_APP_PROD_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_PROD_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_PROD_MESSAGING_APP_ID,
};

const devConfig = {
    apiKey: process.env.REACT_APP_DEV_API_KEY,
    authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
    projectId: process.env.REACT_APP_DEV_PROJECT_ID,
    storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_DEV_MESSAGING_APP_ID,
};

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

class Firebase {
    constructor() {
        app.initializeApp(config);

        this.auth = app.auth();
        this.db = app.firestore();
        this.storage = app.storage();
        this.functions = app.functions();
        this.firestore = app.firestore;

        // *** Cloud Functions ***
        this.getNFeData = this.functions.httpsCallable('getNFeData');
    }

    // *** Auth API ***

    doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);
    doSignOut = () => this.auth.signOut();
    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);            // not yet implemented
    doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);     // not yet implemented

    // *** Firestore API ***

    user = uid => this.db.collection('Users').doc(uid);
    users = () => this.db.collection('Users');

    purchase = uid => this.db.collection('Purchases').doc(uid);
    purchases = () => this.db.collection('Purchases');

    store = uid => this.db.collection('Stores').doc(uid);
    stores = () => this.db.collection('Stores');

    historyItem = (uid) => this.db.collection('History').doc(uid);
    history = () => this.db.collection('History');

    catalogProduct = (uid) => this.db.collection('Catalog').doc(uid);
    catalogProducts = () => this.db.collection('Catalog');

    error = (uid) => this.db.collection('Errors').doc(uid);
    errors = () => this.db.collection('Errors');

    metricsOf = (uid) => this.db.collection('Metrics').doc(uid);

    doGetReceiptURL = uid => this.storage.ref('Receipts').child(uid + '.jpg').getDownloadURL();

    doGetPriceTagURL = uid => this.storage.ref('Prices').child(uid + '.jpg').getDownloadURL();

    doSetOnPurchase = (uid, dictionary, successMessage = 'Purchase updated.', errorMessage = '') =>
        this.purchase(uid).set(dictionary, { merge: true }).then(() => {
            console.log(successMessage);
        }).catch(error => {
            console.log(errorMessage, error.message);
        });

    doAddHistoryItem = (product) => this.history().add(product).then(docRef => {
        console.log("History document created. ID: ", docRef.id);
    }).catch(error => {
        console.error("Error adding document: ", error);
    });

    doDeleteHistoryItem = (product) => this.historyItem(product.uid).delete().then(() => {
        console.log("History document deleted. ID: ", product.uid);
    }).catch(error => {
        console.error(error);
    });

    doAddCatalogProduct = (product) => this.catalogProducts().add(product).then(docRef => {
        console.log("Catalog document created. ID: ", docRef.id);
        return docRef.id;
    }).catch(error => {
        console.error("Error adding document: ", error);
    });

}

export default Firebase;