/**
 * Purchase.js | Data Model
 * An object representing a Purchase.
 * 
 * 
 * Properties:
 * - 
 * - 
*/

class Purchase {

    constructor(uid, jsonPayload) {

        this.uid = uid;
        this.uploadDate             = jsonPayload.uploadDate.toDate();
        this.purchaseDate           = jsonPayload.purchaseDate ? jsonPayload.purchaseDate.toDate() : null;
        this.pantryID               = jsonPayload.pantryID ? jsonPayload.pantryID : null;
        this.purchaserID            = jsonPayload.purchaserID ? jsonPayload.purchaserID : null;
        this.purchasers             = jsonPayload.purchasers ? jsonPayload.purchasers : [];
        this.purchaserDisplayName   = jsonPayload.purchaserDisplayName ? jsonPayload.purchaserDisplayName : null; // new
        this.purchaserProfileURL    = jsonPayload.purchaserProfileURL ? jsonPayload.purchaserProfileURL : null; // new
        this.hasGivenPoints         = jsonPayload.hasGivenPoints ? jsonPayload.hasGivenPoints : null;
        this.amountPaid             = jsonPayload.amountPaid ? jsonPayload.amountPaid : 0;
        this.taxesPaid              = jsonPayload.taxesPaid ? jsonPayload.taxesPaid : null;
        this.quantityOfItems        = jsonPayload.quantityOfItems ? jsonPayload.quantityOfItems : 0;
        this.amountSaved            = jsonPayload.amountSaved ? jsonPayload.amountSaved : null;
        this.purchaseLocation       = jsonPayload.purchaseLocation ? jsonPayload.purchaseLocation : null;
        this.storeID                = jsonPayload.storeID ? jsonPayload.storeID : null;
        this.storeName              = jsonPayload.storeName ? jsonPayload.storeName : null;
        this.storeAddress           = jsonPayload.storeAddress ? jsonPayload.storeAddress : null;
        this.city                   = jsonPayload.city ? jsonPayload.city : null; // new
        this.state                  = jsonPayload.state ? jsonPayload.state : null; // new
        this.paymentMethod          = jsonPayload.paymentMethod ? jsonPayload.paymentMethod : null;
        this.receiptStatus          = jsonPayload.receiptStatus ? jsonPayload.receiptStatus : null;
        this.receiptsUIDs           = jsonPayload.receiptsUIDs ? jsonPayload.receiptsUIDs : [];
        this.receiptsURLs           = jsonPayload.receiptsURLs ? jsonPayload.receiptsURLs : [];
        this.hasBeenProcessed       = jsonPayload.hasBeenProcessed;
        this.processingDate         = jsonPayload.processingDate ? jsonPayload.processingDate.toDate() : null;
        this.payloadString          = jsonPayload.payloadString ? jsonPayload.payloadString : null; // Payload do QR Code da nota fiscal eletrônica.
        this.nfeAccessKey           = jsonPayload.nfeAccessKey ? jsonPayload.nfeAccessKey : null;
        
        this.formattedDate          = this.formattedDate();
        this.amountPaidFormatted    = this.currencyValue(this.amountPaid);
        this.textDescription        = this.textDescription();
        this.date                   = this.purchaseDate ? this.purchaseDate : this.uploadDate;

    }

    extractNFeAccessKey = () => {
        let parameters = this.payloadString.split('=');
        if (parameters[1]) {
            parameters = parameters[1].split('|');
            if (parameters[0].length === 44) {
                this.nfeAccessKey = parameters[0];
            }
        }
    }

    textDescription() {
        if (this.purchaserDisplayName !== null) {
            return this.purchaserDisplayName;
        }
        if (this.storeName !== null) {
            return this.storeName;
        }
        return 'Compra de ' + this.uploadDate.toLocaleDateString("pt-BR");
    }

    formattedDate() {
        if (this.uploadDate.getDate() === new Date().getDate()) {
            return 'Hoje';
        }

        return this.uploadDate.toLocaleDateString('pt-BR');
    }

    currencyValue(number) {
        var formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });

        return formatter.format(number);
    }

}

export default Purchase;