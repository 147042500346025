/**
 * catalogActions.js | Redux Actions File
 * All catalog-related actions that manage global state.
 * 
 */

import * as actionTypes from './types';

export const addCatalogItem = (catalogItem) => {
    return {
        type: actionTypes.ADD_CATALOG_ITEM,
        catalogItem: catalogItem
    };
};

export const removeCatalogItem = (catalogItem) => {
    return {
        type: actionTypes.REMOVE_CATALOG_ITEM,
        catalogItem: catalogItem
    };
};

export const updateCatalogItem = (catalogItem) => {
    return {
        type: actionTypes.UPDATE_CATALOG_ITEM,
        catalogItem: catalogItem
    };
};