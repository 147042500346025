import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import User from '../../models/User';
import moment from 'moment';
import 'moment/locale/pt-br';

import { withFirebase } from '../../components/Firebase';
import styles from './UsersView.module.css';

import Section from '../../components/UI/Section/Section';
import ProfileImage from '../../components/Profile/ProfileImage/ProfileImage';

class UsersView extends PureComponent {
    state = {
        users: []
    }

    componentDidMount() {
        this.props.setPageTitle("Markliiers");
        this.listenToUsers();
    }

    componentWillUnmount() {
        console.log('Removing users listener');
        this.removeUsersListener();
    }

    listenToUsers() {

        this.removeUsersListener = this.props.firebase.users()
            .orderBy('lastSeenDate', 'desc')
            .limit(10)
            .onSnapshot(snapshot => {
                if (snapshot.docs.length > 0) {
                    this.setState({ users: snapshot.docs.map((doc) => new User(doc.id, doc.data())) });
                }
            });
    }

    rowForUser(user) {
        const name = user.displayName ? `${user.displayName} (${user.nickname})` : user.nickname;
        const lastSeen = moment(user.lastSeenDate).locale('pt-br').startOf('min').fromNow();
        const userSince = moment(user.firstSeenDate).locale('pt-br').startOf('min').fromNow(true);
        const location = user.city ? `${user.city}, ${user.state}` : 'Sem localização';

        return (
            <tr key={user.uid} className={styles.UserRow}>
                <td>
                    <ProfileImage
                        displayName={user.displayName || user.nickname}
                        imgURL={user.profilePictureURL} />
                </td>
                <td>
                    <div className={styles.Name}>{name}</div>
                    <div className={styles.UserSince}>{location} - {userSince}</div>
                    <div className={styles.Uid}>{user.uid}</div>
                </td>
                <td><div className={styles.UserSince}>{lastSeen}</div></td>
            </tr>
        );
    }

    render() {
        return (
            <Section title="Vistos por último">
                <table>
                    <tbody>
                        {this.state.users.map(user => {
                            return this.rowForUser(user);
                        })}
                    </tbody>
                </table>
            </Section>
        );
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (title) => dispatch(actions.setTitle(title))
    }
}

export default withFirebase(connect(null, mapDispatchToProps)(UsersView));