/**
 * types.js
 * Constants used to identify all Redux actions of the App.
 * 
 */

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const ADD_PURCHASE = 'ADD_PURCHASE';
export const REMOVE_PURCHASE = 'REMOVE_PURCHASE';
export const UPDATE_PURCHASE = 'UPDATE_PURCHASE';
export const SET_UNPROCESSED_NUMBER = 'SET_UNPROCESSED_NUMBER';

export const ADD_CATALOG_ITEM = 'ADD_CATALOG_ITEM';
export const REMOVE_CATALOG_ITEM = 'REMOVE_CATALOG_ITEM';
export const UPDATE_CATALOG_ITEM = 'UPDATE_CATALOG_ITEM';

export const SET_TITLE = 'SET_TITLE';