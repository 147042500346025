/**
 * PurchaseStore.js | Component
 * A component used for processing receipts, which updates the store information of a purchase.
 * 
 * Props: 
 * - purchase: [Purchase] Get cnpj from automatic reader.
 * - identifiedCnpj: [String] (Optional) The string identified as date from FirebaseML text.
 */


import React, { Component } from 'react';
import TitleSubtitle from '../../../../components/UI/TitleSubtitle/TitleSubtitle';
import Input, { InputElement } from '../../../../components/UI/Input/Input';
import Button from '../../../../components/UI/Button/Button';
import styles from './PurchaseStore.module.css';
import { withFirebase } from '../../../../components/Firebase';
import Store from '../../../../models/Store';
import Fuse from 'fuse.js';

class PurchaseStore extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stores: [],
            filteredStores: [],
            identifiedCnpj: null
        };

        this.storeInput = new InputElement('store', 'input', 'text', 'Pesquise por nome ou CNPJ', this.props.identifiedCnpj, null, { 'required': true });
        this.loadStores = this.loadStores.bind(this);
        this.inputChanged = this.inputChanged.bind(this);
    }

    // Settings for the Fuse fuzzy-search library. 
    options = {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
            "name",
            "cnpj",
            "registryName",
            "street",
            "city"
        ]
    };

    storesRef = React.createRef();

    focusOnInput() {
        this.storesRef.current.focus();
    }

    loadStores = () => {
        this.props.firebase.stores().orderBy('name', 'asc').get().then(result => {
            let stores = [];

            result.docs.forEach(element => {
                const store = new Store(element.id, element.data());
                if (store.uid === this.props.purchase.storeID) {
                    this.selectStore(store);
                }
                stores = stores.concat(store);
            });

            this.setState({
                filteredStores: stores,
                stores: stores
            });
        });
    }

    filterStores = (filter) => {
        this.setState({ filteredStores: filter.trim() === '' ? this.state.stores : new Fuse(this.state.stores, this.options).search(filter) });
    }

    selectStore = (store) => {
        this.setState({
            selectedStore: store
        });
    }

    inputChanged = (event) => {
        this.filterStores(event.target.value);
    }

    componentDidMount() {
        this.focusOnInput();
        this.loadStores();
    }

    componentDidUpdate() {
        if (this.props.identifiedCnpj && this.state.identifiedCnpj === null) {
            this.storeInput.elementConfig.defaultValue = this.props.identifiedCnpj;
            this.storeInput.updateInputValue(this.props.identifiedCnpj);
            setTimeout(() => {
                this.filterStores(this.props.identifiedCnpj);
            }, 50);
            this.setState({ identifiedCnpj: this.props.identifiedCnpj });
        }
    }

    render() {
        let smartSuggestion = null;
        if (this.props.identifiedCnpj) {
            smartSuggestion = <div className={styles.SmartSuggestionContainer}>Leitura Automática: <div className={styles.SmartSuggestion}>{this.props.identifiedCnpj}</div></div>
        }
        return (
            <div className={[styles.Container, 'Card'].join(' ')}>
                <form autoComplete='off' onSubmit={(e) => { e.preventDefault(); this.props.onSubmit(this.state.selectedStore) }}>
                    <TitleSubtitle title='Em qual estabelecimento?' subtitle='Selecione abaixo, adicione um novo ou carregue os dados da NFe' />
                    {smartSuggestion}
                    <Input
                        key={this.storeInput.key}
                        name={this.storeInput.key}
                        ref={this.storesRef}
                        element={this.storeInput}
                        onChange={(event) => this.inputChanged(event)}
                    />
                    <div className={styles.List}>
                        {this.state.filteredStores.map(store => (
                            <div key={"storeDiv" + store.uid} onClick={() => this.selectStore(store)} className={styles.ListItem + ' ' + (this.state.selectedStore === store ? styles.SelectedItem : '')}>
                                <div className={styles.LeftDetails}>
                                    <div className={styles.StoreName}>{store.name}</div>
                                    <div>{store.registryName}</div>
                                    <div className={styles.SmallDetails}>{store.cnpj}</div>
                                </div>
                                <div className={styles.RightDetails}>
                                    <div>{store.city}</div>
                                    <div className={styles.SmallDetails}>{store.street + ', ' + store.number}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.SaveButton}>
                        <Button shouldSubmit disabled={!this.state.selectedStore}>Salvar</Button>
                    </div>
                </form>
            </div>
        );
    }
}

export default withFirebase(PurchaseStore);