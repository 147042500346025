/**
 * appActions.js | Redux Actions File
 * All app actions that manage global state.
 * 
 */

import * as actionTypes from './types';

export const setTitle = (title) => {
    return {
        type: actionTypes.SET_TITLE,
        title: title
    };
};