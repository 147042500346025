import * as actionTypes from '../actions/types';
import { updateObject } from '../utility';

const initialState = {
    purchaseList: [],
    unprocessedPurchases: null
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.ADD_PURCHASE: return addPurchase(state, action);
        case actionTypes.REMOVE_PURCHASE: return removePurchase(state, action);
        case actionTypes.UPDATE_PURCHASE: return updatePurchase(state, action);

        case actionTypes.SET_UNPROCESSED_NUMBER: return setUnprocessedNumber(state, action);

        default:
            return state;
    };

};

const setUnprocessedNumber = (state, action) => {
    return updateObject(state, { unprocessedPurchases: action.numberOfUnprocessedReceipts });
}

const addPurchase = (state, action) => {
    let newList = state.purchaseList.concat(action.purchase);
    newList.sort((a, b) => b.purchaseDate - a.purchaseDate);
    return updateObject(state, { purchaseList: newList });
}

const removePurchase = (state, action) => {
    let updatedArray = [];
    if (action.purchase) {
        updatedArray = state.purchaseList.filter(purchase => purchase.uid !== action.purchase.uid);
    }
    return updateObject(state, { purchaseList: updatedArray });
}

const updatePurchase = (state, action) => {
    const updatedArray = state.purchaseList.map(purchase => {
        if (purchase.uid === action.purchase.uid) {
            return action.purchase;
        } else {
            return purchase;
        }
    });
    return updateObject(state, { purchaseList: updatedArray });
}

export default reducer;