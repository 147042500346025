/**
 * index.js | Reducer
 * This file combines all different reducers into one package. 
 * 
 * How to use it:
 * Every time you create a new reducer, import it here and export inside combineReducers().
 * 
 */

import { combineReducers } from "redux";
import * as actionTypes from '../actions/types';

import auth from "./authReducer";
import purchases from "./purchaseReducer";
import catalog from './catalogReducer';
import app from './appReducer';

const allReducers = combineReducers({
    app,
    auth,
    purchases,
    catalog
});

const rootReducer = (state, action) => {

    // This resets all states to its initial state, with no data.
    if (action.type === actionTypes.AUTH_LOGOUT) {
        state = undefined
    }

    return allReducers(state, action)
}

export default rootReducer;
