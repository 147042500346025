
class User {

    constructor(uid, payload) {
        this.displayName = null;
        this.email = null;
        this.uid = uid;
        this.isAdmin = false;
        this.withFirestoreDataa(payload);
    }

    withFirestoreDataa(payload) {
        // this.uid = payload.uid;
        this.facebookID = payload.facebookID ? payload.facebookID : null;
        this.email = payload.email;
        this.firstName = payload.firstName;
        this.lastName = payload.lastName ? payload.lastName : null;
        this.displayName = payload.displayName !== undefined ? payload.displayName : this.displayName;
        this.nickname = payload.nickname ? payload.nickname : null;
        this.gender = payload.gender ? payload.gender : null;
        this.city = payload.city ? payload.city : null;
        this.state = payload.state ? payload.state : null;
        this.country = payload.country ? payload.country : null;
        this.isPremium = payload.isPremium;
        this.providers = payload.providers ? payload.providers : null;
        this.profilePictureURL = payload.profilePictureURL ? payload.profilePictureURL : null;
        this.firstSeenDate = payload.firstSeenDate ? payload.firstSeenDate.toDate() : null;
        this.registrationDate = payload.registrationDate ? payload.registrationDate.toDate() : null;

        this.lastSeenDate = payload.lastSeenDate ? payload.lastSeenDate.toDate() : null;
        this.pantryID = payload.pantryID;

        this.profileURL = payload.profilePictureURL;
        this.isAdmin = payload.isAdmin;

        if (this.displayName === null || this.displayName === undefined) {
            if (this.firstName)
                this.displayName = this.firstName + ' ' + (this.lastName || '');
        }
    }
}

export default User;