/**
 * Steps.js | UI Component
 * A visualization of steps.
 * 
 * Props: 
 * - current: [number] to set the current step, counting from 0. 
 * - direction: [string] to specify the direction of the step bar, horizontal or vertical.
 * - labelPlacement: [string] place title and description with horizontal or vertical direction. (NOT DONE YET)
 * - initial: [number] set the initial step, counting from 0. (NOT DONE YET)
 */

import React from 'react';
import styles from './Steps.module.css';
import Step from './Step/Step';

const steps = (props) => {

    let stepList = props.children.map((step, index) => (
        <Step 
            key={index} 
            status={props.current === index ? 'current' : props.current > index ? 'done' : 'waiting'} 
            number={index + 1} 
            {...step.props} 
        />
    ));

    let containerClasses = [styles.Container];
    if (props.direction === 'vertical') {
        containerClasses.push(styles.Vertical);
    }

    return (
        <div className={containerClasses.join(' ')}>
            {stepList}
        </div>
    );
}

export default steps;