/**
 * AuthView.js | Container
 * The authentication view of the app. Enables the user to log in.
 * 
 * Local state:
 * - isAuthenticating: tells the UI to disable the form and button.
 * - error: Error messages from Firebase, to be displayed if any.
 * - validationTrigger: used to update the UI when typing into the login form.
 * - formIsValid: tells the UI to enable submit button.
 * 
 * Global state actions:
 * - onAuthSuccess: saves the authenticated user to the global state.
 * 
 */

import React, { Component } from 'react';

// Libraries
import { connect } from 'react-redux';
import { withFirebase } from '../../components/Firebase';

// Components
import Logo from '../../components/Logo/Logo';
import Input, { InputElement } from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import Notification from '../../components/Notification/Notification';

// CSS
import styles from './AuthView.module.css';
import { authSuccess } from '../../store/actions/authActions';
import Admin from '../../models/Admin';


class AuthView extends Component {

    state = {
        isAuthenticating: false,
        error: null,
        validationTrigger: false,
        formIsValid: false,
    }

    // This variable holds the configuration for all the elements in the form.
    // It needs to be a dictionary so we can access each element later with ease, to update the inputted value.
    loginForm = {
        'email': new InputElement('email', 'input', 'email', 'Usuário', null, null, { 'required': true }),
        'password': new InputElement('password', 'input', 'password', 'Senha', null, null, { 'required': true, 'minLength': 6 })
    };

    // Authenticates with Firebase Auth.
    onSubmit = (event) => {
        event.preventDefault();
        this.setState({ isAuthenticating: true });

        const email = this.loginForm.email.value;
        const password = this.loginForm.password.value;

        // Authenticate with Firebase.
        console.log('[Auth] Signing in...')
        this.props.firebase.doSignInWithEmailAndPassword(email, password).then(authUser => {

            let admin = new Admin({});
            admin.withFirebaseAuthData(authUser.user.uid, authUser.user.displayName, authUser.user.email, authUser.user.profileURL);

            const userUID = authUser.user.uid;
            
            // Retrieves user information from Firestore.
            console.log('[Auth] Getting user info...')
            this.props.firebase.user(userUID).get().then(doc => {
                if (doc.exists) {
                    admin.withFirestoreData(doc.data());

                    // Checks if user has access to Marklii Control Tower (database flag isAdmin).
                    if (admin.isAdmin) {
                        this.props.onAuthSuccess(admin); // Runs the redux auth action, to save the user data on the global state.
                    } else {
                        console.log('[Auth] User is not an admin...')
                        this.props.firebase.doSignOut(); 
                        let error = new Error('Sorry, you don\'t have access.');
                        this.setState({ isAuthenticating: false, error: error });
                    }
                }
            }).catch(error => {
                console.log(error.message);
                this.setState({ isAuthenticating: false, error: error });
            });
        }).catch(error => {
            this.setState({ isAuthenticating: false, error: error });
            this.focusOnInput();
        });
    }

    inputChanged(e, elementKey) {

        // Update value.
        this.loginForm[elementKey].updateInputValue(e.target.value);

        // Check if all elements are valid.
        let formIsValid = true;
        for (let key in this.loginForm) {
            formIsValid = this.loginForm[key].isValid && formIsValid;
        }

        this.setState((prevState) => ({
            validationTrigger: !prevState.validationTrigger,
            formIsValid: formIsValid
        }));
    }

    myRefs = {}

    setRef = ref => {
        if (ref === null) return;
        this.myRefs[ref.name] = ref;
    }

    setFocus = name => {
        this.myRefs[name].focus();
    }

    componentDidMount() {
        this.focusOnInput();
    }

    focusOnInput() {
        if (this.state.error !== null) { 
            if (this.state.error.code === 'auth/wrong-password') {
                this.setFocus('password');
                return;
            }
        }

        this.setFocus('email');
    }

    render() {
        // We transform the loginForm dictionary into an array, so we can loop through it. 
        const formElementsArray = [];
        for (let key in this.loginForm) {
            formElementsArray.push(this.loginForm[key]);
        }

        const isButtonDisabled = (this.state.isAuthenticating || !this.state.formIsValid) ? true : false;
        const disabledText = this.state.isAuthenticating ? 'Autenticando...' : null;

        let errorMessage = null;
        if (this.state.error) {
            errorMessage = <Notification isVisible>{this.state.error.message}</Notification>
        }

        return (
            <div className={[styles.Container, 'GradientBackground'].join(' ')}>
                <div className={styles.Logo}>
                    <Logo />
                </div>
                <div className={[styles.AuthBox, 'Card'].join(' ')}>
                    <fieldset disabled={this.state.isAuthenticating}>
                        <form onSubmit={this.onSubmit}>
                            {formElementsArray.map(formElement => (
                                <Input
                                    key={formElement.key}
                                    name={formElement.key}
                                    ref={this.setRef}
                                    element={formElement}
                                    onChange={(event) => this.inputChanged(event, formElement.key)}
                                />
                            ))}
                            <Button shouldSubmit type='Primary' disabled={isButtonDisabled} disabledText={disabledText}> Entrar </Button>
                        </form>
                    </fieldset>
                    {errorMessage}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onAuthSuccess: (userData) => dispatch(authSuccess(userData))
    };
}

export default withFirebase(connect(null, mapDispatchToProps)(AuthView));