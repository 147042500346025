/**
 * ProfileImage.js | Component
 * Round profile image of a user. If no image is available, show initials.
 * 
 * Props:
 * - imgURL
 * - displayName
 * - color: yellow, green, red
 */

import React from 'react';
import styles from './ProfileImage.module.css';

const profileImage = (props) => {

    let initials = '-';
    if (props.displayName) {
        const name = props.displayName;
        const nameArray = name.split(' ');
        initials = nameArray[0].charAt(0);
        initials += nameArray[nameArray.length - 1].charAt(0);
    }

    let element = null;
    if (props.imgURL && props.imgURL.length > 10) {
        element = (
            <object className={styles.TopImage} data={props.imgURL} type='image/jpeg' title={props.displayName}>
                <span></span>
            </object>
        );
    }

    let circleClasses = [styles.ProfileCircle];
    if (props.color === 'green') {
        circleClasses.push(styles.Green);
    }
    if (props.color === 'yellow') {
        circleClasses.push(styles.Yellow);
    }
    if (props.color === 'red') {
        circleClasses.push(styles.Red);
    }
    if (props.color === 'gray') {
        circleClasses.push(styles.Gray);
    }

    return (
        <div className={circleClasses.join(' ')}>
            { element }
            <div className={styles.Initials}>{initials.toUpperCase()}</div>
        </div>
    );
};

export default profileImage;