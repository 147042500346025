/**
 * ProductItem.js | Component
 * A card showing key information about a product.
 * 
 * Props:
 * - product: [Product].
 * - isSelected: [Boolean] informs if this item is currently selected.
 * - onClick: [function]. what function should be performed on click.
 */

import React from 'react';
import styles from './ProductItem.module.css';
import priceTagIcon from '../../../../../assets/images/price-tag-dark-icon@3x.png';

const productItem = (props) => {

    let details = [
        props.product.displayPackageQuantityAndContent()
    ]

    if (props.product.brand) { details.push(props.product.brand) }

    if (props.product.tags) {
        details.push(...props.product.tags);
    }

    let classes = ['Card', styles.ListItem];
    if (props.product.isIncomplete) {
        classes.push(styles.Incomplete);
    }
    if (!props.product.isPriceConfirmed) {
        classes.push(styles.PriceNotConfirmed);
    }

    if (props.isSelected) {
        classes.push(styles.Selected);
    }

    let priceTag = <div className={styles.NoPriceTag}></div>;
    if (props.product.productInCartPriceTag)
        priceTag = <img className={styles.PriceTagIcon} src={priceTagIcon} alt='Tem imagem do preço' />;

    return (
        <div className={classes.join(' ')} onClick={() => props.onClick()}>
            <div className={styles.Left}>{props.product.itemNumber}</div>
            <div className={styles.MiddleGap}></div>
            <div className={styles.Middle}>
                <div>{props.product.displayName()}</div>
                <div className={styles.SmallDetails}>{details.join(', ')}</div>
            </div>
            <div className={styles.MiddleGap}></div>
            <div className={styles.Right}>
                <div className={styles.Price}>{props.product.displayTotalPrice()}</div>
                <div className={styles.SmallDetails}>{props.product.displayQuantity()} x {props.product.displayPriceWithDiscount()}</div>
            </div>
            <div>{priceTag}</div>
        </div>
    );
}

export default productItem;