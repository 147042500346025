/**
 * ProductList.js | Component
 * A component used for showing the products of a purchase.
 * 
 * Props: 
 * - purchase: [Purchase] the purchase from which we will listen for products.
 * - selectedProduct: [Product] which product is currently selected.
 * - selectProduct: [function] selects an item to be updated.
 * - productList: [[Product]] array of products added to the purchase.

 */

import React, { Component } from 'react';
import ProductItem from './ProductItem/ProductItem';
import styles from './ProductList.module.css';
import Utils from '../../../../utils/Utils';

class ProductList extends Component {

    render() {
        let content = <div>Carregando...</div>
        let uid = this.props.selectedProduct ? this.props.selectedProduct.uid : null;
        let amount = null;

        if (this.props.purchase) {
            content = <div>Nenhum produto inserido.</div>;
            if (this.props.productList.length > 0) {
                content = (
                    <div>
                        {this.props.productList.map(product => (
                            <ProductItem key={product.uid} isSelected={uid === product.uid} product={product} onClick={() => this.props.selectProduct(product)} />
                        ))}
                    </div>
                );
            }
            amount = this.props.productList.reduce((partialSum, item) => partialSum + item.totalPrice(), 0).toFixed(2);
        }

        return (
            <div className={styles.Container}>
                <div className={styles.Title}>
                    <h3>Lista de Produtos</h3>
                    <div className={styles.TotalAmount}>{Utils.currencyValue(amount)}</div>
                </div>
                <div className={styles.ProductList}>
                    {content}
                </div>
            </div>
        );
    }
}

export default ProductList;