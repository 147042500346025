import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
// import moment from 'moment';

import { withFirebase } from '../../components/Firebase';

class PricesView extends PureComponent {
    state = {
        users: []
    }

    componentDidMount() {
        this.props.setPageTitle("Preços");
        this.listenToUsers();
    }

    componentWillUnmount() {
        // stop listening.
    }

    listenToUsers() {

    }

    render() {
        return (
            <div></div>
        );
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (title) => dispatch(actions.setTitle(title))
    }
}

export default withFirebase(connect(null, mapDispatchToProps)(PricesView));