/**
 * Header.js | Component
 * The top header displayed in the basic layout. 
 * 
 * Props:
 * - pageTitle: the title we want to show in the header. Instead of prop, could be retrieved from global state (should we do this way?).
 * 
 * Children components props:
 * - toggleSidebar: method for when clicking on the Toggle.
 * - isSidebarVisible: boolean indicating if the sidebar is opened.
 */

import React from 'react';
import styles from './Header.module.css';
import SidebarToggle from '../Sidebar/SidebarToggle/SidebarToggle';
import CurrentUser from '../Header/CurrentUser/CurrentUser';

const Header = ( props ) => (
    <div className={styles.Header}>
        <SidebarToggle isSidebarVisible={props.isSidebarVisible} toggleSidebar={props.toggleSidebar} color='black' hideWhenVisible />
        <div className={styles.PageTitle}>{props.pageTitle}</div>
        <div className={styles.Profile}>
            <CurrentUser />    
        </div>
    </div>
);

export default Header;