/* eslint-disable no-unused-vars */
/**
 * Catalog.js | Container
 * Used to visualize and manage Marklii's catalog of products.
 * 
 * Redux state:
 * - catalog: [Product] Array of products loaded from Catalog collection in Firestore.
 * 
 * Local state: 
 * 
 * 
 */

import React, { PureComponent } from 'react';

// Libraries
import { withFirebase } from '../../components/Firebase';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import Product from '../../models/Product';
import Aux from '../../hoc/Aux';

import Button from '../../components/UI/Button/Button';
import Tag from '../../components/UI/Tag/Tag';
import InputText from '../../components/UI/Input/InputText/InputText';
import EditCatalogItem from '../EditCatalogItem/EditCatalogItem';

import isFemaleIcon from '../../assets/images/isFemale-icon@3x.png';
import isByWeightIcon from '../../assets/images/isByWeight-icon@3x.png';
import settingsIcon from '../../assets/images/settings-icon@3x.png';
import removeIcon from '../../assets/images/delete-icon@3x.png';

import styles from './CatalogView.module.css';

class CatalogView extends PureComponent {

    state = {
        filteredCatalog: [],

        // isEditButtonEnabled: false,
        // isMergeButtonEnabled: false,
        // isDeleteButtonEnabled: false,

        selectedProducts: [], // This array holds UIDs (markliiCode) only. Not the whole product object.

        // Config
        isEditingProduct: false,
        numberOfRows: 10,
        isSettingsOpen: false,
        availableProperties: [
            "isIncomplete",
            "GTIN",
            "genericName",
            "genericType",
            "isFemaleGenericProduct",
            "productVariation",
            "alsoKnownAs",
            "avgDaysUntilDueDate",
            "storeSection",
            "packageQuantity",
            "contentOfOneUnit",
            "contentUnit",
            "totalContent",
            "isPriceByWeight",
            "wordForOneUnit",
            "isFemaleWordForOneUnit",
            "brand",
            "manufacturer",
            "tags"
        ],
        visibleProperties: [
            "isIncomplete",
            "GTIN",
            "genericName",
            "genericType",
            "isFemaleGenericProduct",
            "productVariation",
            "alsoKnownAs",
            "avgDaysUntilDueDate",
            "storeSection",
            "packageQuantity",
            "contentOfOneUnit",
            "contentUnit",
            "totalContent",
            "isPriceByWeight",
            "wordForOneUnit",
            "isFemaleWordForOneUnit",
            "brand",
            "manufacturer",
            "tags"
        ],

        // Inputs: all inputs must be named [property name]+Input, for example: isIncompleteInput, genericNameInput...
        // Input values depend on the type, either string or boolean.
        isIncompleteInput: false,
        GTINInput: "",
        genericNameInput: "",
        genericTypeInput: "",
        isFemaleGenericProductInput: false,
        productVariationInput: "",
        alsoKnownAsInput: "",
        avgDaysUntilDueDateInput: "",
        storeSectionInput: "",
        packageQuantityInput: "",
        contentOfOneUnitInput: "",
        contentUnitInput: "",
        isPriceByWeightInput: false,
        wordForOneUnitInput: "",
        isFemaleWordForOneUnitInput: false,
        brandInput: "",
        manufacturerInput: "",
        tagsInput: "",


        // Filters: all filters must be named [property name]+Filter, for example: isIncompleteFilter, genericNameFilter...
        // All filters must be arrays.
        isIncompleteFilter: [],
        GTINFilter: [],
        genericNameFilter: [],
        genericTypeFilter: [],
        isFemaleGenericProductFilter: [],
        productVariationFilter: [],
        alsoKnownAsFilter: [],
        avgDaysUntilDueDateFilter: [],
        storeSectionFilter: [],
        packageQuantityFilter: [],
        contentOfOneUnitFilter: [],
        contentUnitFilter: [],
        isPriceByWeightFilter: [],
        wordForOneUnitFilter: [],
        isFemaleWordForOneUnitFilter: [],
        brandFilter: [],
        manufacturerFilter: [],
        tagsFilter: [],

        alsoKnownAsAndOperator: false,
        tagsAndOperator: false,

        // Suggestions
        dictionaryOfSuggestions: {}
    }











    /*************************************************
     **  Lifecycle methods.
     *************************************************/

    UNSAFE_componentWillMount() {
        this.setupSuggestions();
    }

    componentDidMount() {
        this.setPageTitle();
        this.filterCatalog();
    }

    /**
     * The timeoutHandle is used to avoid calling filterCatalog() and setupSuggestions() repeatedly every time props are received.
     * This is specially useful when updating or deleting several products, since the CatalogReducer updates the state on each change.
    */
    timeoutHandle = null;
    UNSAFE_componentWillReceiveProps() {
        window.clearTimeout(this.timeoutHandle);
        this.timeoutHandle = window.setTimeout(() => {
            this.filterCatalog();
            this.setupSuggestions();
        }, 300);
    }





    /*************************************************
     **  Action methods.
     *************************************************/

    /**
     * Updates the page header title, based on selected products.
     */
    setPageTitle() {
        let title = this.state.selectedProducts.length === 0 ? "Catálogo" : "Catálogo (" + this.state.selectedProducts.length + " produto selecionado)";
        if (this.state.selectedProducts.length > 1) {
            title = "Modo Edição: " + this.state.selectedProducts.length + " produtos selecionados";
        }
        this.props.setPageTitle(title);
    }

    toggleSettings = () => {
        this.setState(prevState => ({ isSettingsOpen: !prevState.isSettingsOpen }));
    }

    /**
     * Filters the catalog based on what was selected by the user. 
     */
    filterCatalog() {

        let filteredCatalog = this.props.catalog;
        let catalogWasFiltered = false;

        this.state.visibleProperties.forEach(propertyName => {
            const filterName = propertyName + 'Filter';
            if (this.state[filterName]) {
                if (this.state[filterName].length > 0) {
                    catalogWasFiltered = true;

                    let product = new Product({});
                    const isCheckbox = typeof product[propertyName] === "boolean";
                    const isNumber = typeof product[propertyName] === "number" || propertyName === "avgDaysUntilDueDate";

                    let allowedValues = [];
                    let forbiddenValues = [];

                    if (isCheckbox) {
                        allowedValues = null;
                        forbiddenValues = null;
                    } else if (isNumber) {
                        allowedValues = this.state[filterName].filter(element => !element.startsWith('!')).map(element => element === "null" ? null : Number(element));
                        forbiddenValues = this.state[filterName].filter(element => element.startsWith('!')).map(element => element.replace('!', '')).map(element => element === "null" ? null : Number(element));
                    } else {
                        allowedValues = this.state[filterName].filter(element => !element.startsWith('!')).map(element => element === "null" ? null : element);
                        forbiddenValues = this.state[filterName].filter(element => element.startsWith('!')).map(element => element.replace('!', '')).map(element => element === "null" ? null : element);
                    }

                    if (propertyName === 'alsoKnownAs' || propertyName === 'tags') {
                        filteredCatalog = filteredCatalog.filter(product => {

                            if (this.state[propertyName + 'AndOperator']) {
                                const productContainsAllForbiddenValues = forbiddenValues.every(r => !product[propertyName].includes(r));
                                const productContainsAllAllowedValues = allowedValues.every(r => product[propertyName].includes(r));

                                if (!productContainsAllForbiddenValues || !productContainsAllAllowedValues)
                                    return false;
                            } else {
                                if (product[propertyName].some(r => forbiddenValues.includes(r)) || (allowedValues.length > 0 && !product[propertyName].some(r => allowedValues.includes(r))))
                                    return false
                            }

                            return true
                        });
                    } else if (isCheckbox) {
                        filteredCatalog = filteredCatalog.filter(product => this.state[filterName].includes(product[propertyName]));
                    } else {
                        filteredCatalog = filteredCatalog.filter(product => {
                            if (forbiddenValues.includes(product[propertyName]) || (allowedValues.length > 0 && !allowedValues.includes(product[propertyName])))
                                return false;
                            return true;
                        });
                    }
                }
            }
        });

        // Deselect products that are no longer being presented (because it got out of the filter).
        this.state.selectedProducts.forEach(uid => {
            if (!filteredCatalog.map(product => product.uid).includes(uid)) {
                this.selectProduct(uid);
            }
        })

        this.setState({ filteredCatalog: catalogWasFiltered ? filteredCatalog : this.props.catalog });
    }


    /**
     * If all visible rows are selected, it deselects all rows. 
     * If there are at least one unselected, it selects all.
     */
    selectAllVisibleProducts = () => {
        let totalVisibleItems = this.state.filteredCatalog.length < this.state.numberOfRows ? this.state.filteredCatalog.length : this.state.numberOfRows;

        if (this.state.selectedProducts.length !== totalVisibleItems) {
            // if there's still unselected rows, we should select all.
            let uidsOfAllVisibleProduct = this.state.filteredCatalog.slice(0, this.state.numberOfRows).map(product => product.uid);
            this.setState({ selectedProducts: uidsOfAllVisibleProduct }, () => { this.setPageTitle() });
        } else {
            // else if all rows are already selected, deselect all.
            this.setSelectedProductsState([]);
        }
    }

    /**
     * Removes the given text from propertyName's filter state.
     * 
     * @param {string} text The filter value that should be removed from state.
     * @param {string} propertyName The name of the property which filters will be updated.
     */
    removeFilter = (text, propertyName) => {
        const filterName = propertyName + 'Filter';
        if (this.state[filterName]) {
            let cleanedFilter = this.state[filterName].filter(filterText => filterText !== text);
            this.setState({ [filterName]: cleanedFilter }, () => {
                this.filterCatalog();
            });
            this.setSelectedProductsState([]);
        }
    }

    /**
     * Sets all filled filters to empty. If checkbox filters are set, it sets them back to false.
     */
    removeAllFilters = () => {
        let dictionaryOfEmptyFilters = {};
        this.state.visibleProperties.forEach(property => {
            if (this.state[property + 'Filter'] && this.state[property + 'Filter'].length > 0)
                dictionaryOfEmptyFilters[property + 'Filter'] = [];
            if (this.state[property + 'Input'] && this.state[property + 'Input'] === true)
                dictionaryOfEmptyFilters[property + 'Input'] = false;
        });

        this.setState(dictionaryOfEmptyFilters, () => {
            this.filterCatalog();
        });
    }

    /**
     * Adds the given text from propertyName's filter state.
     * 
     * @param {string} inputProps All props sent to TextInput, including propertyName.
     * @param {string} suggestion The filter value that should be added to state.
     */
    addFilter = (inputProps, suggestion) => {

        // If there are more than 1 product selected, CatalogView is in bulk editing mode. 
        // In this case, we only add the suggestion to the input, not to the filter array.
        const shouldAddFilter = this.state.selectedProducts.length <= 1;
        const propertyName = inputProps.propertyName;

        if (shouldAddFilter) {
            this.setState((prevState) => ({
                [propertyName + 'Filter']: prevState[propertyName + 'Filter'].concat(String(suggestion)),
                [propertyName + 'Input']: ""
            }), () => {
                this.filterCatalog();
            });
            this.setSelectedProductsState([]);
        } else {
            this.setState({ [propertyName + 'Input']: String(suggestion) })
        }

    }

    /**
     * Selects a product. The product's UID will be stored in selectedProducts state.
     * 
     * @param {string} uid The UID of the catalog product.
     */
    selectProduct = (uid) => {
        if (this.state.selectedProducts.includes(uid)) {
            const updatedArray = this.state.selectedProducts.filter(catalogUID => catalogUID !== uid);
            this.setSelectedProductsState(updatedArray);
        } else {
            this.setSelectedProductsState(this.state.selectedProducts.concat(uid));
        }
    }

    /**
     * Updates state with array of selected products. After state is updated, page title is updated.
     * 
     * @param {array} selectedProducts 
     */
    setSelectedProductsState(selectedProducts) {
        this.setState({ selectedProducts: selectedProducts || [] }, () => { this.setPageTitle() });
    }

    deselectAllProducts = () => {
        this.setSelectedProductsState([]);
    }

    /**
     * Increases state's variable numberOfRows by 20.
     */
    showMoreRows = () => {
        this.setState((prevState) => ({ numberOfRows: prevState.numberOfRows + 30 }));
    }

    /**
     * Show all possible rows.
     */
    showAllRows = () => {
        this.setState((prevState) => ({ numberOfRows: prevState.filteredCatalog.length, isSettingsOpen: false }));
    }

    /**
     * Shows or hides a column from the catalog table.
     */
    toggleColumn = (e) => {
        let visibleProperties = [];
        if (this.state.visibleProperties.includes(e.target.name)) {
            visibleProperties = this.state.visibleProperties.filter(property => property !== e.target.name);
        } else {
            visibleProperties = [...this.state.visibleProperties, e.target.name];
        }
        visibleProperties = this.state.availableProperties.filter(property => visibleProperties.includes(property));
        this.setState({ visibleProperties: visibleProperties });
    }

    /**
     * Updates inputs' state.
     * 
     * @param {event} event Target name should be the property name.
     */
    handleInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    /**
     * Updates Filter and Input state for given property based on checkbox value. 
     * Catalog is filtered afterwards.
     * 
     * @param {event} event
     * @param {string} checkboxPropertyName
     */
    setCheckboxFilter = (event, checkboxPropertyName) => {
        const shouldFilterCatalog = this.state.selectedProducts.length <= 1;

        if (shouldFilterCatalog) {
            this.setSelectedProductsState([]);
            this.setState({ [checkboxPropertyName + 'Filter']: [event.target.checked], [checkboxPropertyName + 'Input']: event.target.checked }, () => {
                this.filterCatalog();
            });
        } else {
            this.setState({ [checkboxPropertyName + 'Input']: event.target.checked }, () => {
                this.editSelectedProductsInBulk(checkboxPropertyName);
            });
        }

    }

    /**
     * Created the dictionary of suggestions for each visible property.
     * Called by componentWillMount().
     */
    setupSuggestions() {
        this.state.availableProperties.forEach(propertyName => {
            if (this.state[propertyName + 'Input'] !== undefined) {
                this.getSuggestionsFor(propertyName);
            }
        });
    }

    /**
     * Returns an array of strings containing suggestions for the given property.
     * Called by setupSuggestions()
     * 
     * @param {string} propertyName The name of the property for which suggestions will be generated. 
     */
    getSuggestionsFor(propertyName) {

        let suggestions = [];

        switch (propertyName) {
            case 'tags':
                const arrayOfTagsArray = this.props.catalog.map(product => product[propertyName] ? product[propertyName] : 'null');
                suggestions = [...new Set([].concat.apply([], arrayOfTagsArray))];
                break;

            case 'alsoKnownAs':
                const arrayOfAkaArray = this.props.catalog.map(product => product[propertyName] ? product[propertyName] : 'null');
                suggestions = [...new Set([].concat.apply([], arrayOfAkaArray))];
                break;

            default:
                suggestions = [...new Set(this.props.catalog.map(product => product[propertyName] ? product[propertyName] : 'null'))];
                break;
        }

        this.setState(prevState => ({
            dictionaryOfSuggestions: {
                ...prevState.dictionaryOfSuggestions,
                [propertyName]: suggestions.sort((a, b) => String(a).localeCompare(b, 'pt', { 'sensitivity': 'base' }))
            }
        }));
    }








    /*************************************************
     **  Render supporting methods.
     *************************************************/


    /**
     * Returns all the rows for all products in filteredCatalog, limited by the number of rows setting.
     */
    tableRows = () => {
        let tableRows = <tr><td colSpan={this.state.visibleProperties.length + 1}>Nenhum produto encontrado.</td></tr>

        if (this.state.filteredCatalog.length > 0) {
            tableRows = (
                this.state.filteredCatalog.slice(0, this.state.numberOfRows).map(product => {
                    return this.rowForProduct(product);
                })
            );
        }

        return tableRows;
    }

    /**
     * Returns a row with the information of a given product, with presented columns based on state configurations.
     * 
     * @param {product} product 
     */
    rowForProduct(product) {
        const nullWord = <span className={styles.Null}>null</span>;
        const isSelected = this.state.selectedProducts.includes(product.uid);
        const incompleteClass = product.isIncomplete ? styles.Incomplete : null;
        const isFemaleWordIcon = product.isFemaleGenericProduct ? <div className={styles.CenterDiv}><img className={styles.IsFemaleIconRow} src={isFemaleIcon} alt='Palavra feminina' /></div> : null;
        const isPriceByWeight = product.isPriceByWeight ? <div className={styles.CenterDiv}><img className={styles.ByWeightIconRow} src={isByWeightIcon} alt='Preço por peso' /></div> : null;

        return (
            <tr key={product.uid} onClick={() => this.selectProduct(product.uid)} className={isSelected ? styles.SelectedRow : null}>
                <td><div className={styles.CenterDiv}><input type='checkbox' checked={isSelected} readOnly /></div></td>
                {this.state.visibleProperties.map(propertyName => {

                    switch (propertyName) {
                        case "isIncomplete":
                            return <td key={product.uid + propertyName}><div className={incompleteClass}></div></td>
                        case "genericName":
                            return <td key={product.uid + propertyName}>{product.genericName}</td>
                        case "genericType":
                            return <td key={product.uid + propertyName}>{product.genericType ? product.genericType : nullWord}</td>
                        case "isFemaleGenericProduct":
                            return <td key={product.uid + propertyName}>{product.isFemaleGenericProduct ? isFemaleWordIcon : null}</td>
                        case "productVariation":
                            return <td key={product.uid + propertyName}>{product.productVariation ? product.productVariation : nullWord}</td>
                        case "alsoKnownAs":
                            return <td key={product.uid + propertyName}>{product.alsoKnownAs ? product.alsoKnownAs.join(', ') : nullWord}</td>
                        case "avgDaysUntilDueDate":
                            return <td key={product.uid + propertyName} className={styles.NoLineBreak}>{product.avgDaysUntilDueDate ? product.avgDaysUntilDueDate : nullWord}</td>
                        case "storeSection":
                            return <td key={product.uid + propertyName} className={styles.NoLineBreak}>{product.storeSection ? product.storeSection : nullWord}</td>
                        case "packageQuantity":
                            return <td key={product.uid + propertyName}>{product.packageQuantity ? product.packageQuantity : nullWord}</td>
                        case "contentOfOneUnit":
                            return <td key={product.uid + propertyName}>{product.contentOfOneUnit ? product.contentOfOneUnit : nullWord}</td>
                        case "contentUnit":
                            return <td key={product.uid + propertyName}>{product.contentUnit ? product.contentUnit : nullWord}</td>
                        case "totalContent":
                            return <td key={product.uid + propertyName}>{product.displayTotalContent()}</td>
                        case "isPriceByWeight":
                            return <td key={product.uid + propertyName}>{isPriceByWeight}</td>
                        case "wordForOneUnit":
                            return <td key={product.uid + propertyName}>{product.wordForOneUnit ? product.wordForOneUnit : nullWord}</td>
                        case "isFemaleWordForOneUnit":
                            return <td key={product.uid + propertyName}>{product.isFemaleWordForOneUnit ? isFemaleWordIcon : null}</td>
                        case "brand":
                            return <td key={product.uid + propertyName} className={styles.NoLineBreak}>{product.brand ? product.brand : nullWord}</td>
                        case "manufacturer":
                            return <td key={product.uid + propertyName} className={styles.NoLineBreak}>{product.manufacturer ? product.manufacturer : nullWord}</td>
                        case "tags":
                            return (
                                <td key={product.uid + propertyName}>
                                    <div className={styles.TagsContainer}>
                                        {product.tags.map(tag => {
                                            return <Tag color='blank' key={tag}>{tag}</Tag>
                                        })}
                                    </div>
                                </td>
                            );
                        case "GTIN":
                            return <td key={product.uid + propertyName}><span className={styles.Code}>{product.GTIN ? product.GTIN : nullWord}</span></td>
                        case "productDescription":
                            return <td key={product.uid + propertyName}>{product.productDescription ? product.productDescription : nullWord}</td>
                        default:
                            return <td key={product.uid + propertyName}></td>
                    }

                })}
            </tr>
        );
    }

    onFilterTagClick = (props) => {
        if (props.isCheckbox)
            return;

        const newFilter = this.state[props.propertyName + 'Filter'].map(element => {
            if (element === props.text) {
                if (element.startsWith("!"))
                    return element.replace('!', '');
                return '!' + element;
            }
            return element;
        });
        this.setState({ [props.propertyName + 'Filter']: newFilter }, () => {
            this.filterCatalog();
        })
    }

    /**
     * Called by the filter Tag to handle the onRemoval method.
     */
    onFilterTagRemoval = (props) => {
        this.removeFilter(props.text, props.propertyName);
        if (props.isCheckbox)
            this.setState({ [props.propertyName + 'Input']: false });
    }

    onOperatorChange = (props) => {
        this.setState((prevState) => ({ [props.propertyName + 'AndOperator']: !prevState[props.propertyName + 'AndOperator'] }), () => {
            this.filterCatalog();
        });
    }

    /**
     * Returns the div element containing tags for each filter element
     * 
     * @param {String} propertyName 
     */
    showSelectedFilters(propertyName) {

        const filterName = propertyName + 'Filter';
        if (this.state[filterName] && this.state[filterName].length > 0) {
            let product = new Product({});
            const isCheckbox = typeof product[propertyName] === "boolean";
            let andOrOption = null;
            if (["tags", "alsoKnownAs"].includes(propertyName) && this.state[filterName].length > 1) {
                andOrOption = <Tag propertyName={propertyName} onClick={this.onOperatorChange} color='blank'>{this.state[propertyName + 'AndOperator'] ? "E" : "OU"}</Tag>;
            }
            return (
                <div className={styles.TagsContainer}>
                    {this.state[filterName].map((text, index) => {
                        return (
                            <Aux key={text + index}>
                                <Tag text={text} propertyName={propertyName} isCheckbox={isCheckbox} onClick={this.onFilterTagClick} onRemoval={this.onFilterTagRemoval} color={String(text).startsWith('!') ? 'red' : 'blue'}>{isCheckbox ? text ? 'S' : 'N' : text ? text : 'null'}</Tag>
                                {index === this.state[filterName].length - 1 ? null : andOrOption}
                            </Aux>
                        );
                    })}
                </div>
            );
        }
        return null;
    }

    /**
     * Returns the show more button, in case there are more products to show.
     */
    showMoreButton() {
        let showMoreButton = null;
        const hiddenProducts = this.state.filteredCatalog.length - this.state.numberOfRows;
        if (hiddenProducts > 0) {
            showMoreButton = (
                <div>
                    <Button size='small' type='Default' onClick={this.showMoreRows}>↓ Mostrar mais 30 (de {hiddenProducts} restantes) ↓</Button>
                </div>
            );
        }
        return showMoreButton;
    }

    tableHeader() {

        return (
            <tr>
                <th>
                    <div className={[styles.SettingsContainer, styles.CenterDiv].join(' ')}>
                        <img className={styles.SettingsIcon} src={settingsIcon} alt='Configurações' onClick={this.toggleSettings} />
                        <div className={[styles.SettingsBox, this.state.isSettingsOpen ? styles.SettingsOpen : null].join(' ')}>
                            <b>Configurações:</b> <br /><br />
                            <div className={styles.PropertyList}>
                                {this.state.availableProperties.map(property => {
                                    return <label key={property} className={styles.PropertyOption}><input type='checkbox' name={property} onClick={this.toggleColumn} checked={this.state.visibleProperties.includes(property)} readOnly /> {property} </label>
                                })}
                            </div>
                            <Button size="small" type='Default' onClick={this.showAllRows}>Mostrar todas as {this.state.filteredCatalog.length} linhas</Button>
                        </div>
                    </div>
                </th>
                {this.state.visibleProperties.map(property => {

                    switch (property) {
                        case "isIncomplete":
                            return <th key={property}><div className={styles.Incomplete}></div></th>;
                        case "genericName":
                            return <th key={property} className={styles.NoLineBreak}>Produto Genérico</th>;
                        case "genericType":
                            return <th key={property} className={styles.NoLineBreak}>Tipo Genérico</th>;
                        case "isFemaleGenericProduct":
                            return <th key={property}><div className={styles.CenterDiv} title="A palavra que descreve o produto é feminina?"><img className={styles.IsFemaleIcon} src={isFemaleIcon} alt='Palavra feminina?' /></div></th>
                        case "productVariation":
                            return <th key={property} title="Variação, sabor, cor.">Variação</th>;
                        case "alsoKnownAs":
                            return <th key={property}>Outros Nomes</th>;
                        case "avgDaysUntilDueDate":
                            return <th key={property}>Dias até estragar</th>;
                        case "storeSection":
                            return <th key={property}>Seção</th>;
                        case "packageQuantity":
                            return <th key={property} className={styles.NarrowColumn}>Qtde.</th>;
                        case "contentOfOneUnit":
                            return <th key={property} className={styles.NarrowColumn}>1 un.</th>;
                        case "contentUnit":
                            return <th key={property} className={styles.NarrowColumn}>Un.</th>;
                        case "totalContent":
                            return <th key={property} className={styles.NarrowColumn}>Total</th>;
                        case "isPriceByWeight":
                            return <th key={property}><div className={styles.CenterDiv} title="O produto é vendido por peso?"><img className={styles.ByWeightIcon} src={isByWeightIcon} alt='Preço por peso?' /></div></th>;
                        case "wordForOneUnit":
                            return <th key={property}>Palavra para 1 un.</th>;
                        case "isFemaleWordForOneUnit":
                            return <th key={property}><div className={styles.CenterDiv} title="A palavra que descreve a unidade é feminina?"><img className={styles.IsFemaleIcon} src={isFemaleIcon} alt='Palavra feminina?' /></div></th>;
                        case "brand":
                            return <th key={property}>Marca</th>;
                        case "manufacturer":
                            return <th key={property}>Empresa</th>;
                        case "tags":
                            return <th key={property}>Características</th>;
                        case "GTIN":
                            return <th key={property}>GTIN</th>;
                        case "productDescription":
                            return <th key={property}>Descrição:</th>;
                        default:
                            return <th key={property}>{property}</th>;
                    }
                })}
            </tr>
        );
    }


    /**
     * Returns the filter row of the table header. Each column contains any selected filter for its property.
     */
    selectedFiltersRow() {
        let removeButton = null
        let hasFilters = false;

        this.state.visibleProperties.forEach(property => {
            if (this.state[property + 'Filter'] && this.state[property + 'Filter'].length > 0)
                hasFilters = true;
        });

        if (hasFilters) {
            removeButton = (
                <div className={styles.CenterDiv} title="Remover todos os filtros.">
                    <div className={styles.RemoveBox} onClick={this.removeAllFilters}>
                        <img src={removeIcon} className={styles.RemoveIcon} alt='Excluir'></img>
                    </div>
                </div>
            );
        }
        return (
            <tr>
                <th>{removeButton}</th>
                {this.state.visibleProperties.map(property => {
                    return <th key={property}>{this.showSelectedFilters(property)}</th>
                })}
            </tr>
        );
    }

    inputsRow() {

        const areAllRowsSelected = this.state.selectedProducts.length === this.state.numberOfRows;
        // const shouldShowSuggestions = this.state.selectedProducts.length <= 1;

        return (
            <tr>
                <th><div className={styles.CenterDiv}><input type='checkbox' name='selectAllCheckbox' onChange={this.selectAllVisibleProducts} checked={areAllRowsSelected} /></div></th>
                {this.state.visibleProperties.map(propertyName => {
                    let product = new Product({});
                    const isCheckbox = typeof product[propertyName] === "boolean";
                    const inputName = propertyName + 'Input';

                    if (isCheckbox) {
                        return (
                            <th key={propertyName}>
                                <div className={styles.CenterDiv}>
                                    <input type='checkbox' name={inputName} checked={this.state[inputName]} onChange={(e) => (this.setCheckboxFilter(e, propertyName))} />
                                </div>
                            </th>
                        );
                    } else if (this.state[inputName] === undefined) {
                        return <th key={propertyName}></th>
                    } else {
                        return (
                            <th key={propertyName}>
                                <InputText
                                    name={inputName}
                                    value={this.state[inputName]}
                                    onChange={this.handleInputChange}
                                    autoComplete="off"

                                    propertyName={propertyName}

                                    suggestions={this.state.dictionaryOfSuggestions[propertyName]}
                                    onSuggestionSelection={this.addFilter}
                                />
                            </th>
                        );
                    }

                })}
            </tr>
        );
    }

    /**
     * Updates state to inform that one product is being edited with the EditCatalog component.
     */
    editOneProduct = () => {
        this.setState({ isEditingProduct: true });
    }

    /**
     * Confirms if the user is sure about editing in bulk, with a custom message informing everything that will be updated.
     * 
     * @param {string} checkboxPropertyName (Optional) Informs the name of a boolean property. Should be passed only if the property is boolean.
     */
    confirmBulkEditing(checkboxPropertyName) {
        let message = "Tem certeza de que deseja alterar os " + this.state.selectedProducts.length + " produtos selecionados?"
        message += "\n\n"
        message += "Os seguintes campos serão salvos como: \n\n"

        if (checkboxPropertyName) {
            message += "      " + checkboxPropertyName + ": " + this.state[checkboxPropertyName + 'Input'] + "\n"
        } else {
            for (const propertyName of this.state.visibleProperties) {
                if (this.state[propertyName + 'Input'] && this.state[propertyName + 'Input'] !== "") {
                    message += "      " + propertyName + ": " + this.state[propertyName + 'Input'] + "\n"
                }
            }
        }

        if (window.confirm(message)) {
            return window.confirm("NÃO É POSSÍVEL fazer um ctrl+z nessa operação. \n\nEla afetará todo o histórico de produtos mapeados por esses markliiCodes.\n\nVocê tem certeza absoluta?");
        }
    }

    /**
     * Updates catalog products in bulk. 
     * 
     * @param {string} checkboxPropertyName (Optional) Informs the name of a boolean property. Should be passed only if the property is boolean.
     */
    editSelectedProductsInBulk = (checkboxPropertyName) => {

        // A transaction is atomic and so it will either successfully complete all of the writes, or it will fail all of the writes.
        // That's why we can add Catalog writes and History writes in the same batch. 
        // That's the guarantee that History products will only be updated if their Catalog correspondents are also updated.

        if (this.confirmBulkEditing(checkboxPropertyName)) {

            let dataDictionary = this.dictionaryWithUpdates(checkboxPropertyName);

            let catalogDocumentReferences = [];
            let historyQueries = [];
            let historyDocumentReferences = [];

            this.state.selectedProducts.forEach(markliiCode => {
                // Gets references to all Catalog documents. 
                catalogDocumentReferences.push(this.props.firebase.catalogProduct(markliiCode));
                // Creates queries for History documents for each markliiCode.
                historyQueries.push(this.props.firebase.history().where("markliiCode", "==", markliiCode).get());
            });

            this.props.setPageTitle("Salvando... ⏳");

            this.props.firebase.db.runTransaction(async (transaction) => {
                const results = await Promise.all(historyQueries);

                results.forEach(querySnapshot => {
                    querySnapshot.forEach(document => {
                        const itemDoc = this.props.firebase.historyItem(document.id);
                        historyDocumentReferences.push(itemDoc);
                        transaction.update(itemDoc, dataDictionary);
                        // console.log('Updating ' + document.id, 'for markliiCode ' + document.data().markliiCode);
                    });
                });

                catalogDocumentReferences.forEach(catalogDocRef => {
                    transaction.update(catalogDocRef, dataDictionary);
                });

                return { catalogUpdates: catalogDocumentReferences.length, historyUpdates: historyDocumentReferences.length };
            }).then(results => {
                console.log(results.catalogUpdates + ' catalog products and ' + results.historyUpdates + ' history items updated.');

                // If updated property is from text input, clear values from it.
                if (!checkboxPropertyName) {
                    const properties = Object.keys(dataDictionary);
                    properties.forEach(propertyName => {
                        this.setState({ [propertyName + 'Input']: "" })
                    });
                }
                this.setPageTitle()

            }).catch(error => {
                this.setPageTitle()
                console.error(error);
            });

        }
    }

    /**
     * Generates and returns a dictionary containing the fields and values that should be updated.
     * The dictionary is generated considering the types of each property (string, boolean, array, number), so it is saved correctly on Firestore.
     * 
     * @param {string} checkboxPropertyName (Optional) If the property being saved is boolean, this parameter should inform the the property name.
     */
    dictionaryWithUpdates(checkboxPropertyName) {
        let dataDictionary = {};
        let product = new Product({});

        if (checkboxPropertyName) {
            dataDictionary[checkboxPropertyName] = this.state[checkboxPropertyName + 'Input'];
        } else {
            for (const propertyName of this.listOfTextInputsWithValue()) {
                const inputName = propertyName + 'Input';
                if (this.state[inputName] && this.state[inputName] !== "") {
                    if (propertyName === "tags" || propertyName === "alsoKnownAs") {
                        if (this.state[inputName].includes("remove:")) {
                            dataDictionary[propertyName] = this.props.firebase.firestore.FieldValue.arrayRemove(...this.state[inputName].replace(/remove:/g, '').split(',').map(str => str.trim()));
                        } else {
                            dataDictionary[propertyName] = this.props.firebase.firestore.FieldValue.arrayUnion(...this.state[inputName].split(',').map(str => str.trim()));
                        }
                    } else {
                        const propertyType = propertyName === "avgDaysUntilDueDate" || propertyName === "contentOfOneUnitInput" || propertyName === "packageQuantityInput"
                            ? "number"
                            : typeof product[propertyName] === "undefined"
                                ? "string"
                                : typeof product[propertyName];
                        const value = this.state[inputName] === "null" ? null : propertyType === "number" ? Number(this.state[inputName].replace(',', '.')) : this.state[inputName];
                        dataDictionary[propertyName] = value;
                    }

                }
            }
        }

        return dataDictionary;
    }

    /**
     * Deletes all selected products from the Catalog collection in Firestore.
     */
    deleteSelectedCatalogItems = () => {
        if (this.state.selectedProducts.length === 0)
            return;

        if (window.confirm("Você tem certeza ABSOLUTA que deseja excluir os " + this.state.selectedProducts.length + " produtos selecionados?\n\nEsta ação é IRREVERSÍVEL!")) {
            const numberOfDeletions = this.state.selectedProducts.length;
            var batch = this.props.firebase.db.batch();
            this.state.selectedProducts.forEach(markliiCode => {
                batch.delete(this.props.firebase.catalogProduct(markliiCode));
            });
            batch.commit().then(() => {
                console.log(numberOfDeletions + " produtos foram excluídos com sucesso.");
            }).catch(error => {
                console.error(error);
            });
        }
    }

    /**
     * Checks whether there are any content in the filter inputs. 
     * It's used to figure out if the bulk editing button should be enabled.
     * 
     * @returns {boolean} true or false
     */
    isThereAnyInputTyped() {
        return this.listOfTextInputsWithValue().length > 0;
    }

    stopEditing = () => {
        this.setState({ isEditingProduct: false });
    }

    /**
     * Returns an array of property names which inputs are not empty. Used for saving in bulk.
     * 
     * @returns {array} array of strings containing property names.
     */
    listOfTextInputsWithValue() {
        let inputs = [];

        for (const propertyName of this.state.visibleProperties) {

            if (!this.state[propertyName + 'Input'])
                continue;

            if (this.state[propertyName + 'Input'] === false || this.state[propertyName + 'Input'] === true)
                continue;

            if (this.state[propertyName + 'Input'] !== "") {
                inputs.push(propertyName);
            }
        }

        return inputs;
    }



    render() {

        const isEditButtonEnabled = this.state.selectedProducts.length === 1;
        // const isMergeButtonEnabled = this.state.selectedProducts.length > 1;
        const isDeleteButtonEnabled = this.state.selectedProducts.length > 0;
        const isUpdateButtonEnabled = this.state.selectedProducts.length > 1 && this.isThereAnyInputTyped();

        let bulkUpdateTitle = "Atualizar produtos";
        if (isUpdateButtonEnabled) {
            bulkUpdateTitle = "Atualizar " + this.state.selectedProducts.length + " produtos";
        }

        let editItemView = null;
        if (this.state.isEditingProduct && this.state.selectedProducts.length === 1) {
            editItemView = (
                <EditCatalogItem
                    markliiCode={this.state.selectedProducts[0]}
                    onSave={this.stopEditing}
                    finishEditing={this.stopEditing}
                />
            );
        }

        return (
            <div className={styles.Container}>
                <div className={styles.EditItemContainer}>
                    <div className={styles.EditItem}>
                        {editItemView}
                    </div>
                </div>
                <div className={styles.TopButtons}>
                    <Button size="small" disabled={!isEditButtonEnabled} onClick={this.editOneProduct}>Editar</Button>
                    <Button size="small" type='Default' disabled>Juntar</Button>
                    <Button size="small" type='RedAlert' disabled={!isDeleteButtonEnabled} onClick={this.deleteSelectedCatalogItems}>Excluir</Button>
                    <Button size="small" disabled={!isUpdateButtonEnabled} onClick={this.editSelectedProductsInBulk}>{bulkUpdateTitle}</Button>
                    <Button size="small" type='Default' disabled={!isDeleteButtonEnabled} onClick={this.deselectAllProducts}>Desfazer Seleção</Button>
                </div>
                <div className={styles.TableContainer}>
                    <table>
                        <thead>
                            {this.tableHeader()}

                            {this.inputsRow()}

                            {this.selectedFiltersRow()}

                            <tr>
                                <th colSpan={this.state.visibleProperties.length + 1}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.tableRows()}
                        </tbody>
                    </table>
                </div>
                {this.showMoreButton()}
                <div className={styles.LinkToGuidelines}>
                    <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/presentation/d/1OosvByIbdLWTDplEoeSR1bvQL8JFDndOYdQCbWJx0dA/edit?usp=sharing">Guia para edição do Catálogo</a>
                </div>
            </div>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        catalog: state.catalog.catalog
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (title) => dispatch(actions.setTitle(title))
    }
}

export default withFirebase(connect(mapStateToProps, mapDispatchToProps)(CatalogView));