/**
 * ProcessingView.js | Container
 * The place we process new receipts.
 * 
 * Local state:
 * - 
 * 
 * Global state subscriptions:
 * - 
 * - 
 */

import React, { Component } from 'react';

// Libraries
import { withFirebase } from '../../../components/Firebase';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import moment from 'moment';

import Steps from '../../../components/UI/Steps/Steps';
import Step from '../../../components/UI/Steps/Step/Step';
import Button from '../../../components/UI/Button/Button';
import TitleSubtitle from '../../../components/UI/TitleSubtitle/TitleSubtitle';
import Purchase from '../../../models/Purchase';
import Image from '../../../components/UI/Image/Image';
import PurchaseDate from './PurchaseDate/PurchaseDate';
import ProductList from './ProductList/ProductList';

import styles from './ProcessingView.module.css';
import inputStyles from '../../../components/UI/Input/Input.module.css';
import PurchaseStore from './PurchaseStore/PurchaseStore';
import PurchaseAmount from './PurchaseAmount/PurchaseAmount';
import AddPurchaseItem from './AddPurchaseItem/AddPurchaseItem';
import EditCatalogItem from '../../EditCatalogItem/EditCatalogItem';
import AddBulkPurchaseItem from './AddBulkPurchaseItem/AddBulkPurchaseItem';
import HistoryItem from '../../../models/HistoryItem';

class ProcessingView extends Component {

    state = {
        purchaseUID: this.props.match.params.uid,
        purchase: null,
        isLoading: true,
        stepOverride: null,
        productList: [],
        selectedProduct: null,
        nfeAccessKey: '',
        hasBeenProcessed: false,

        identifiedDoc: null, // Object
        identifiedDate: null, // Date
        identifiedCnpj: null, // String
        identifiedTotalValues: null, // Array of Numbers
    }

    componentDidMount() {
        this.props.setPageTitle('Compras > Guardando Nova Compra');
        this.listenToPurchase()
        if (this.state.purchase)
            this.listenToPurchaseItems();
        this.getFirebaseMLText()
    }

    componentDidUpdate() {
        if (this.state.purchase && !this.removeListListener)
            this.listenToPurchaseItems();
    }

    componentWillUnmount() {
        this.removeListener();
        if (this.removeListListener)
            this.removeListListener();
    }

    listenToPurchase() {
        console.log('Listening to purchase ', this.props.match.params.uid);

        this.removeListener = this.props.firebase.purchase(this.state.purchaseUID).onSnapshot(doc => {
            if (doc.exists) {
                const purchase = new Purchase(doc.id, doc.data());

                // if (purchase.nfeAccessKey === null && purchase.payloadString) {
                //     purchase.extractNFeAccessKey();
                //     this.props.firebase.doSetOnPurchase(doc.id, { nfeAccessKey: purchase.nfeAccessKey }, '[ProcessingView] NFe extracted and saved.', '[ProcessingView]');
                // } else if (purchase.nfeAccessKey) {
                //     console.log('Electronic receipt is available.');
                //     this.setState({ nfeAccessKey: purchase.nfeAccessKey.match(/.{1,4}/g).join(' ') });
                // }

                this.setState({ purchase: purchase, hasBeenProcessed: purchase.hasBeenProcessed });

                if (purchase.receiptsURLs === null || purchase.receiptsURLs.length === 0) {
                    this.loadReceiptURL()
                }

                if (purchase.purchaserDisplayName === null || purchase.pantryID === null) {
                    this.loadPurchaserData(purchase.purchaserID);
                }

            }
        })
    }

    listenToPurchaseItems() {
        this.removeListListener = this.props.firebase.history().where('purchaseID', '==', this.state.purchase.uid).onSnapshot(snapshot => {
            if (snapshot.docs.length > 0) {
                snapshot.docChanges().forEach(change => {

                    const product = new HistoryItem(change.doc.id, change.doc.data());

                    let updatedArray = [];

                    if (change.type === 'added') {
                        updatedArray = [...this.state.productList, product];
                    }
                    if (change.type === 'modified') {
                        updatedArray = this.state.productList.map(item => {
                            if (item.uid === product.uid) {
                                return product;
                            } else {
                                return item;
                            }
                        });
                    }
                    if (change.type === 'removed') {
                        updatedArray = this.state.productList.filter(item => item.uid !== product.uid);
                    }

                    updatedArray.sort((a, b) => a.itemNumber - b.itemNumber);
                    this.setState({ productList: updatedArray });
                });
            } else {
                this.setState({ productList: [] });
            }
            this.updatePurchaseQuantityOfItems();
        });
    }

    updatePurchaseQuantityOfItems() {
        if (this.state.purchase.quantityOfItems !== this.state.productList.length) {
            this.props.firebase.doSetOnPurchase(this.state.purchase.uid, { quantityOfItems: this.state.productList.length }, '[ProductList] Updated quantity of items.', '[ProductList]');
        }
    }

    async getFirebaseMLText() {
        let firebaseMLDoc = await this.props.firebase.purchase(this.state.purchaseUID).collection('Text').doc('FirebaseML').get();
        if (firebaseMLDoc.exists) {
            const doc = firebaseMLDoc.data();

            // 1 DATE
            const dateText = doc.text.match(/\d{2}\/\d{2}\/\d{4}/);
            if (dateText != null && dateText.length >= 1) {
                console.log("Date identified: ", dateText[0]);
                const date = moment(dateText[0], 'DD/MM/YYYY').toDate();
                this.setState({ identifiedDate: date });
            }

            // 2 CNPJ
            // const cnpjText = doc.text.match(/[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2}/);
            let cnpjText = doc.text.match(/\d{1,2}\s*[.,]\s*\d{3}\s*[.,]\s*\d{3}[|/\\]\s*\d{2,4}\s*[-–]\s*\d{1,2}/gm);
            if (cnpjText != null && cnpjText.length >= 1) {
                cnpjText = cnpjText[0].replace(' ', '');
                console.log("CNPJ identified: ", cnpjText);
                this.setState({ identifiedCnpj: String(cnpjText) });
            }

            // 3 TOTAL VALUE
            let partAfterTotal = doc.text.split('TOTAL')[1];
            if (!partAfterTotal) {
                partAfterTotal = doc.text.split('Total')[1];
                if (!partAfterTotal) {
                    partAfterTotal = doc.text;
                }
            }
            if (partAfterTotal) {
                let totalTextArray = partAfterTotal.match(/^[0-9]+([.,][0-9]{1,2})$/gm);
                if (totalTextArray != null && totalTextArray.length >= 1) {
                    // Transform all items to numbers and sort.
                    totalTextArray = totalTextArray.map(item => Number(item.replace(',', '.'))).sort((a, b) => b - a);
                    console.log("Total value identified: ", totalTextArray[0]);
                    this.setState({ identifiedTotalValues: totalTextArray })
                }
            }

            // 4 DOC (it will be used on the AddBulkPurchaseItem component)
            this.setState({ identifiedDoc: doc });

        } else {
            console.log("FirebaseML does not exist.");
        }
    }

    loadReceiptURL() {
        if (this.state.purchase.receiptsUIDs.length === 0) return;

        this.props.firebase.doGetReceiptURL(this.state.purchase.receiptsUIDs[0]).then(url => {
            const URLs = [url];
            this.props.firebase.doSetOnPurchase(this.state.purchase.uid, { receiptsURLs: URLs }, '[ProcessingView] Purchase ' + this.state.purchase.uid + ' updated successfully with receipt URL.', '[Load receipt URL]');
        }).catch(error => {
            console.log(error.message);
        })
    }

    loadPurchaserData(purchaserID) {
        this.props.firebase.user(purchaserID).get().then(doc => {
            if (doc.exists) {
                const displayName = doc.data().firstName + ' ' + doc.data().lastName;
                const profileURL = doc.data().profilePictureURL || null;
                const pantryID = doc.data().pantryID;
                this.props.firebase.doSetOnPurchase(this.state.purchase.uid, {
                    purchaserDisplayName: displayName,
                    purchaserProfileURL: profileURL,
                    pantryID: pantryID
                }, '[ProcessingView] Purchase ' + this.state.purchase.uid + ' updated successfully.', '[Load purchaser data]');
            } else {
                console.error('User does not exist.');
            }
        }).catch(error => {
            console.error(error);
        })
    }

    updateTotalAmount = (newAmount) => {
        if (!isNaN(+newAmount) && isFinite(newAmount)) {
            this.props.firebase.doSetOnPurchase(this.state.purchase.uid, { amountPaid: newAmount }, '[ProcessingView] Purchase amount updated successfully.', '[ProcessingView] Error updating total amount.');
        } else {
            console.error('New amount is not a number.');
        }
        this.setState({ stepOverride: null });
    }

    updateAutoProcessItems = (processItems, selectedItemsArray) => {
        this.setState({ autoProcessHasProcessed: processItems });
        this.setState({ stepOverride: null });
    }

    updateDate = (newDate) => {
        let date = moment(newDate, 'DD/MM/YYYY').toDate();
        const uploadDate = this.state.purchase.uploadDate;
        date.setHours(uploadDate.getHours(), uploadDate.getMinutes(), uploadDate.getSeconds(), uploadDate.getMilliseconds());
        if (isNaN(date.getTime()) === false) {
            this.props.firebase.doSetOnPurchase(this.state.purchase.uid, { purchaseDate: date }, '[ProcessingView] Date updated successfully.', '[ProcessingView] Error updating purchase date.');
        }
        this.setState({ stepOverride: null });
    }

    updateStore = (store) => {
        this.props.firebase.doSetOnPurchase(this.state.purchase.uid,
            {
                storeID: store.uid,
                storeName: store.name,
                storeAddress: store.street + ', ' + store.number,
                city: store.city,
                state: store.state,
                purchaseLocation: store.location,
            }, '[ProcessingView] Store updated successfully.', '[ProcessingView] Error updating purchase store.');

        this.setState({ stepOverride: null });
    }

    overrideStep(step) {
        this.setState({ stepOverride: step });
    }

    selectProduct = (product) => {
        // We don't allow editing a product if the purchase has already been processed or if the storeID is not set.
        if (this.state.purchase.hasBeenProcessed || !this.state.purchase.storeID) return;
        this.setState({ selectedProduct: product });
    }

    editSelectedItemInCatalog = () => {
        this.setState({ isEditingSelectedProduct: true });
    }

    inputChanged = (e) => {
        let cleanKey = e.target.value === null ? ' ' : e.target.value;
        cleanKey = cleanKey.split(' ').join('');
        if (cleanKey.length > 0)
            cleanKey = cleanKey.match(/.{1,4}/g).join(' ');
        this.setState({ nfeAccessKey: cleanKey });
    }

    saveNFeKey = () => {
        let accessKey = this.state.nfeAccessKey.split(' ').join('');
        console.log('Saving NFe access key', accessKey, '.');
        if (accessKey.trim() === '') {
            accessKey = null;
        }
        this.props.firebase.doSetOnPurchase(this.state.purchase.uid, { nfeAccessKey: accessKey }, '[ProcessingView] Access key saved.', '[ProcessingView]');
    }

    openNFeURL = () => {
        if (this.state.purchase)
            if (this.state.purchase.payloadString)
                window.open(this.state.purchase.payloadString, '_ blank');
    }

    readNFe = () => {
        // this.props.firebase.getNFeData({ accessKey: this.state.nfeAccessKey.split(' ').join('') }).then(result => {
        //     console.log(result);
        // }).catch(error => {
        //     console.error('We could not search for the GTIN.');
        // });
    }

    restartProcessing = () => {
        if (!window.confirm('Tem certeza que deseja reabrir o processamento?'))
            return;

        this.props.firebase.doSetOnPurchase(this.state.purchase.uid, { hasBeenProcessed: false, processingDate: null }, 'Purchase reopened.', '[ProcessingView]');
        this.setState({ hasBeenProcessed: false });
    }

    finishProcessing = () => {

        if (!window.confirm('Confira se o valor total é o mesmo que o valor dos itens inseridos. Deseja mesmo finalizar o processamento?'))
            return;

        this.props.firebase.history().where('purchaseID', '==', this.state.purchase.uid).get().then(querySnapshot => {

            // Get all History document IDs that need to be updated.
            const arrayOfHistoryItemsToUpdate = [];
            querySnapshot.forEach(doc => { arrayOfHistoryItemsToUpdate.push(doc.id) });

            var batch = this.props.firebase.db.batch();
            arrayOfHistoryItemsToUpdate.forEach(id => {
                const itemDoc = this.props.firebase.historyItem(id);
                batch.set(itemDoc, {
                    isLoadable: true,
                    storeID: this.state.purchase.storeID,
                    purchaseLocation: this.state.purchase.purchaseLocation,
                }, { merge: true });
            });

            batch.commit().then(() => {
                console.log(arrayOfHistoryItemsToUpdate.length, 'document(s) in History updated.');

                // If successful, update purchase hasBeenProcessed = true.
                this.props.firebase.doSetOnPurchase(this.state.purchase.uid, { hasBeenProcessed: true, processingDate: new Date() }, 'Purchase processed successfully.', '[ProcessingView]');
                this.setState({ hasBeenProcessed: true });

            }).catch(error => {
                console.error(error);
            });

        });
    }

    deletePurchase = async () => {
        if (!window.confirm('Essa ação é irreversível! \nTem certeza que deseja excluir esta compra?'))
            return;

        // Delete products without confirmed price.
        const productsToDeleteRef = this.props.firebase.history()
            .where('purchaseID', '==', this.state.purchase.uid)
            .where('isPriceConfirmed', '==', false);
        const deleteSnapshot = await productsToDeleteRef.get();
        const batch = this.props.firebase.db.batch();
        deleteSnapshot.docs.forEach(doc => {
            batch.delete(doc.ref);
        });
        await batch.commit();
        console.log(deleteSnapshot.docs.length + ' products deleted from History.');


        // Set purchase products as not loadable.
        const productsToMakeNotLoadableRef = this.props.firebase.history()
            .where('purchaseID', '==', this.state.purchase.uid);
        const updateSnapshot = await productsToMakeNotLoadableRef.get();
        const batchToUpdate = this.props.firebase.db.batch();
        updateSnapshot.docs.forEach(doc => {
            batchToUpdate.set(doc.ref, { isLoadable: false }, { merge: true });
        });
        await batchToUpdate.commit();
        console.log(updateSnapshot.docs.length + ' products updated as not loadable.');


        // Delete receipt image.
        const dateFolder = moment(this.state.purchase.purchaseDate).format('YYYY-MM');
        this.state.purchase.receiptsUIDs.forEach(async (uid) => {
            console.log('Deleting receipt ' + uid);
            try {
                await this.props.firebase.storage.ref('ReceiptsFolder').child(dateFolder).child(uid).delete();
            } catch (error) {
                console.log('Could not delete receipt: ' + error);
            }
        });


        // Delete purchase
        await this.props.firebase.purchase(this.state.purchase.uid).delete();
        console.log('Purchase deleted.');

    }

    render() {

        let current = null;
        let inputView = <div className={styles.PurchaseProcessedLabel}> Compra processada com sucesso! <span role="img" aria-label="well done">👏</span> </div>

        if (this.state.purchase) {
            if (!this.state.purchase.hasBeenProcessed) {
                current = 0; // step 1 - date
                if (this.state.purchase.purchaseDate !== null) {
                    current = 1; // step 2 - cnpj
                    if (this.state.purchase.storeID !== null) {
                        current = 2; // step 3 - total value
                        if (this.state.purchase.amountPaid !== null && this.state.purchase.amountPaid > 0) {
                            current = 4 // steps 4 and 5 - adding products (either automatically or manually)
                            if (this.state.identifiedDoc !== null && this.state.purchase.quantityOfItems === 0 && !this.state.stepOverride) {
                                current = 3
                            }
                        }
                    }
                }
            }
        }

        if (this.state.stepOverride && (this.state.stepOverride - 1 <= current) && !this.state.hasBeenProcessed) {
            current = this.state.stepOverride - 1;
        }

        if (this.state.selectedProduct)
            current = 4; // So the selected product can be seen.

        const purchase = (this.state.purchase || {});

        switch (current) {
            case 0:
                inputView = <PurchaseDate currentDate={purchase.purchaseDate ? purchase.purchaseDate : this.state.identifiedDate ? this.state.identifiedDate : purchase.uploadDate} identifiedDate={this.state.identifiedDate ? moment(this.state.identifiedDate).format('DD/MM/YYYY') : null} onSubmit={this.updateDate} />
                break;
            case 1:
                inputView = <PurchaseStore purchase={purchase} onSubmit={this.updateStore} identifiedCnpj={this.state.identifiedCnpj} />
                break;
            case 2:
                inputView = <PurchaseAmount currentAmount={purchase.amountPaid} onSubmit={this.updateTotalAmount} identifiedTotalValues={this.state.identifiedTotalValues} />
                break;
            case 3:
                inputView = <AddBulkPurchaseItem purchase={purchase} onSubmit={this.updateAutoProcessItems} identifiedDoc={this.state.identifiedDoc} productList={this.state.productList} />
                break;
            case 4:
                inputView = <AddPurchaseItem purchase={this.state.purchase} selectProduct={this.selectProduct} selectedProduct={this.state.selectedProduct} editCatalogItem={this.editSelectedItemInCatalog} productList={this.state.productList} />
                if (this.state.isEditingSelectedProduct) {
                    inputView = <EditCatalogItem markliiCode={this.state.selectedProduct.markliiCode} onSave={this.selectProduct} finishEditing={() => this.setState({ isEditingSelectedProduct: false })} />
                }

                break;
            default:
                break;
        }


        let purchaseDetails = null;
        if (!this.state.isEditingSelectedProduct) {
            purchaseDetails = (
                <div className={styles.PurchaseInformation}>
                    <TitleSubtitle
                        title={purchase.storeName ? purchase.storeName : 'Nova Compra'}
                        subtitle={purchase.storeAddress ? purchase.storeAddress : 'Aguardando processamento...'}
                    />

                    <TitleSubtitle
                        title={purchase.purchaseDate ? purchase.purchaseDate.toLocaleDateString('pt-BR') : '-'}
                        subtitle='Data da compra'
                    />

                    <TitleSubtitle title={purchase.quantityOfItems} subtitle='Itens' />

                    <TitleSubtitle title={purchase.amountPaidFormatted} subtitle='Total Gasto' />
                </div>
            );
        }

        var receiptComponent = "Nota fiscal ainda não foi guardada."

        if (purchase.receiptsUIDs !== undefined && purchase.receiptsUIDs.length > 0) {
            receiptComponent = <Image receiptsURLs={purchase.receiptsURLs} />
        }

        return (
            <div className={styles.Container}>

                <div className={styles.StepsContainer}>
                    <Steps current={current} direction='horizontal'>
                        <Step onClick={() => this.overrideStep(1)} title='Data da compra' description='Informe a data da nota fiscal' />
                        <Step onClick={() => this.overrideStep(2)} title='Estabelecimento' description='Selecione o local' />
                        <Step onClick={() => this.overrideStep(3)} title='Valor gasto' description='Informe o valor total' />
                        <Step onClick={() => this.overrideStep(4)} title='Registro automático' description='Leitura automática da nota' />
                        <Step onClick={() => this.overrideStep(5)} title='Registro manual' description='Registre produtos' />
                    </Steps>
                    <div className={styles.TopButtonsDiv}>
                        <Button size='small' type='RedAlert' onClick={this.deletePurchase}>Excluir compra</Button>
                        <Button disabled={this.state.purchase ? !this.state.purchase.nfeAccessKey : true} size='small' onClick={this.readNFe}>Carregar NFe</Button>
                        <Button size='small' type={this.state.hasBeenProcessed ? 'RedAlert' : 'Primary'} onClick={this.state.hasBeenProcessed ? this.restartProcessing : this.finishProcessing}>{this.state.hasBeenProcessed ? 'Desbloquear' : 'Finalizar'}</Button>
                    </div>
                </div>

                <div className={styles.MainContainer}>

                    <div className={styles.LeftSide}>
                        {purchaseDetails}
                        {inputView}
                    </div>

                    <div className={styles.MiddleGap}></div>

                    <div className={styles.RightSide}>
                        <h3>Nota Fiscal</h3>
                        <div className={styles.ReceiptSection}>
                            {receiptComponent}
                        </div>
                        <div className={styles.NFeKey}>
                            <div className={inputStyles.Input} >
                                <input
                                    className={inputStyles.InputElement}
                                    type='text'
                                    name='nfeAccessKey'
                                    value={this.state.nfeAccessKey}
                                    onChange={this.inputChanged}
                                />
                            </div>
                            <div className={styles.SaveButton}>
                                <Button onClick={this.saveNFeKey}>Salvar</Button>
                            </div>
                            <div className={styles.SaveButton}>
                                <Button onClick={this.openNFeURL} disabled={purchase.payloadString === null}>URL</Button>
                            </div>
                        </div>

                        <ProductList purchase={this.state.purchase} productList={this.state.productList} selectProduct={this.selectProduct} selectedProduct={this.state.selectedProduct} />
                    </div>

                </div>

            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (title) => dispatch(actions.setTitle(title))
    }
}

export default withFirebase(connect(null, mapDispatchToProps)(ProcessingView));