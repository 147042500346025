import Product from './Product';

import firebase from 'firebase/app';
import 'firebase/firestore';

class HistoryItem extends Product {

    constructor(uid, jsonPayload) {
        super(jsonPayload);

        this.uid = uid;
        this.userWhoBought = jsonPayload.userWhoBought;
        this.pantryID = jsonPayload.pantryID;
        this.purchaseID = jsonPayload.purchaseID;
        this.purchaseDate = jsonPayload.purchaseDate ? jsonPayload.purchaseDate instanceof firebase.firestore.Timestamp ? jsonPayload.purchaseDate.toDate() : jsonPayload.purchaseDate : null;
        this.purchaseLocation = jsonPayload.purchaseLocation;
        this.quantityBought = jsonPayload.quantityBought;
        this.storeID = jsonPayload.storeID;

        this.openingDate = jsonPayload.openingDate;
        this.outDate = jsonPayload.outDate;
        this.probableSpoilingDate = jsonPayload.probableSpoilingDate instanceof firebase.firestore.Timestamp ? jsonPayload.probableSpoilingDate.toDate() : jsonPayload.probableSpoilingDate ? jsonPayload.probableSpoilingDate : null;
        this.isSpoiled = jsonPayload.isSpoiled;
        this.isRecurrent = jsonPayload.isRecurrent;
        this.isLoadable = jsonPayload.isLoadable;
        this.hasSentProductPhotos = jsonPayload.hasSentProductPhotos ? jsonPayload.hasSentProductPhotos : false;
        this.isPriceConfirmed = jsonPayload.isPriceConfirmed ? jsonPayload.isPriceConfirmed : false;
        this.productInCartPriceTag = jsonPayload.productInCartPriceTag ? jsonPayload.productInCartPriceTag : null;

        this.storeCode = jsonPayload.storeCode;

        // History Information
        this.itemNumber = jsonPayload.itemNumber ? jsonPayload.itemNumber : 1;
        this.price = jsonPayload.price;
        this.discount = jsonPayload.discount ? jsonPayload.discount : 0;

        this.filter();

    }

    totalPrice() {
        return (this.price - this.discount) * this.quantityBought;
    }

    displayPriceWithDiscount() {
        if (this.discount) {
            const price = '(' + this.currencyValue(this.price) + ' – ' + this.currencyValue(this.discount) + ')';
            return this.price ? price : 'R$ -';
        } else {
            return this.displayUnitPrice();
        }
    }

    displayUnitPrice() {
        return this.price ? this.currencyValue(this.price - this.discount) : 'R$ -';
    }

    displayTotalPrice() {
        return this.price ? this.currencyValue((this.price - this.discount) * this.quantityBought) : 'R$ -';
    }

    currencyValue(number) {
        var formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        });

        return formatter.format(number);
    }

    wasBought(onPurchase, markliiCode, itemNumber, storeCode, quantity, price, discount, avgDaysUntilDueDate) {

        // catalog information
        this.uid = null;                    // we reset the uid to make sure we're not saving the item to history with a catalog uid.
        this.markliiCode = markliiCode;

        // item information
        this.itemNumber = itemNumber;
        this.storeCode = storeCode ? storeCode : this.GTIN;
        this.quantityBought = quantity;
        this.price = price;
        this.discount = discount;
        this.isPriceConfirmed = true;

        // purchase information
        this.storeID = onPurchase.storeID;
        this.purchaseID = onPurchase.uid;
        this.purchaseDate = onPurchase.purchaseDate;
        this.purchaseLocation = onPurchase.purchaseLocation;

        // if avgDaysUntilDueDate is undefined, it means the product might be just being updated, 
        // meaning that it already has a probableSpoilingDate set. 
        if (avgDaysUntilDueDate) {
            this.probableSpoilingDate = this.dateAddedBy(onPurchase.purchaseDate, avgDaysUntilDueDate);
        }

        // user information
        this.userWhoBought = onPurchase.purchaserID;
        this.pantryID = onPurchase.pantryID;
    }

    setSpoilingDate(purchaseDate, afterDays) {
        this.probableSpoilingDate = this.dateAddedBy(purchaseDate, afterDays);
    }

    dateAddedBy(oldDate, days) {
        if (oldDate && days) {
            var date = new Date(oldDate.valueOf());
            date.setDate(date.getDate() + (days || 0));
            return date;
        } else {
            return null;
        }
    }

    /// Used when editing a product that was already added to the purchase list.
    setHistoryInfoFrom(existingProduct) {

        this.uid = existingProduct.uid;
        // this.markliiCode = markliiCode;

        // item information
        this.itemNumber = existingProduct.itemNumber;
        // this.storeCode = storeCode ? storeCode : this.GTIN;
        this.quantityBought = existingProduct.quantity;
        this.price = existingProduct.price;
        this.discount = existingProduct.discount;

        // purchase information
        this.storeID = existingProduct.storeID;
        this.purchaseID = existingProduct.purchaseID;
        this.purchaseDate = existingProduct.purchaseDate;
        this.probableSpoilingDate = existingProduct.probableSpoilingDate;

        // user information
        this.userWhoBought = existingProduct.userWhoBought;
        this.pantryID = existingProduct.pantryID;
    }

}

export default HistoryItem;