import * as actionTypes from '../actions/types';
import { updateObject } from '../utility';

const initialState = {
    title: "Título"
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.SET_TITLE: return setTitle(state, action);

        default:
            return state;
    };

};

const setTitle = (state, action) => {
    return updateObject(state, { title: action.title});
}

export default reducer;