/**
 * AddBulkPurchaseItem.js | Container
 * The component used to add items in bulk, based on FirebaseML recognized text.
 * 
 * Props:
 * - purchase: [Purchase]
 * - identifiedDoc: [Object]
 * - onSubmit: [Function]
 * 
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './AddBulkPurchaseItem.module.css';

import { withFirebase } from '../../../../components/Firebase';
import Button from '../../../../components/UI/Button/Button';
import CatalogItem from '../../../../models/CatalogItem';
import Utils from '../../../../utils/Utils';

import removeIcon from '../../../../assets/images/invalid-icon@3x.png';
import HistoryItem from '../../../../models/HistoryItem';

class AddBulkPurchaseItem extends Component {

    state = {
        identifiedProducts: [],
        hasIdentifiedProducts: false
    }

    // Regex References. 
    // For some reason, using regex stored in a variable sometimes doesn't work. Not sure why.
    // 
    // integerOnlyExpressionStartToEnd = /^[0-9]+$/gm
    // integerOnlyExpressionStartToEnd = /^(\d+)$/gm
    integerOnlyExpressionAnywhere = /[0-9]+/gm
    doublesOnlyExpression = /([0-9]+([.,][0-9]{1,}))/igm
    numbersOnlyExpression = /([0-9]+([.,]?[0-9]{0,}))/igm
    contentExpression = /^[0-9]+([.,]?[0-9]{0,4})+(un|Kg|g|mL|L|cpr|cp|cm|m)/igm

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.identifiedDoc && !this.state.hasIdentifiedProducts) {
            this.identifyProducts(nextProps.identifiedDoc);
        }
    }

    componentDidMount() {
        if (this.props.identifiedDoc && !this.state.hasIdentifiedProducts) {
            this.identifyProducts(this.props.identifiedDoc);
        }
    }

    identifyProducts(doc) {
        if (!doc) return;
        console.log('Identifying products.');


        var usesItemNumbers = null;
        var foundTheFirstProduct = false;

        if (doc.hasOwnProperty("blocks")) {
            doc.blocks.forEach(block => {
                if (block.hasOwnProperty("lines")) {
                    for (var lineIndex = 0; lineIndex < block.lines.length; lineIndex++) {
                        const line = block.lines[lineIndex];

                        // Here we check if the line is worth analyzing. If a line has less than 20 characters 
                        // or it's basically just numbers, it probably isn't a line of a product.
                        const lineLength = line.text.length;
                        let hasEnoughLetters = false
                        const lettersArray = line.text.match(/[a-z]+/igm);
                        if (lettersArray) {
                            hasEnoughLetters = lettersArray.join('').length > 5;
                        }

                        if (lineLength < 20 || !hasEnoughLetters) {
                            continue
                        }

                        if (line.hasOwnProperty("elements")) {
                            let itemNumber = null
                            let code = null
                            let content = null
                            let description = null
                            let quantity = null
                            let price = null

                            // Checks if this line looks like a typical product line, 
                            // that starts with 1 or 2 numbers followed by more text.

                            if (usesItemNumbers === null && line.elements[0] && line.elements[1] && lineLength > 20) {
                                const isFirstElementNumber = /^(\d+)$/gm.test(line.elements[0].text);
                                const isSecondElementNumber = /^(\d+)$/gm.test(line.elements[1].text);

                                if (isFirstElementNumber && isSecondElementNumber) {
                                    console.log('This store probably uses item numbers.');
                                    usesItemNumbers = true;
                                } else if (isFirstElementNumber) {
                                    console.log('This store probably does not use item numbers.');
                                    usesItemNumbers = false;
                                } else {
                                    continue
                                }
                                foundTheFirstProduct = true;
                            } else if (!foundTheFirstProduct) {
                                // if a first product has not yet been found and the line contains only 1 element, it's not a valid line.
                                continue
                            }

                            for (let elementIndex = 0; elementIndex < line.elements.length; elementIndex++) {
                                const element = line.elements[elementIndex];
                                // Identifies possible item numbers and codes. That's why we restrict the index to <= 2
                                if (elementIndex <= 2 && /^(\d+)$/gm.test(element.text)) {
                                    if (elementIndex === 0) {
                                        if (usesItemNumbers === true) {
                                            itemNumber = element.text;
                                        } else if (usesItemNumbers === false) {
                                            code = element.text;
                                        }
                                    } else {
                                        code = element.text;

                                    }
                                } else if (this.contentExpression.test(element.text)) {
                                    content = element.text.match(this.contentExpression)[0];
                                }
                            }

                            if (!code) {
                                // If no code (GTIN or custom) was found, no product was found in this line.
                                continue
                            }

                            if (foundTheFirstProduct) {

                                description = line.elements.slice(usesItemNumbers ? 2 : 1, line.elements.length).map(element => element.text.charAt(0).toUpperCase() + element.text.slice(1).toLowerCase()).join(' ');
                                [quantity, price] = this.getQuantityAndPriceFromLineText(line.text.replace(itemNumber, '').replace(code, ''));

                                // If there isn't price information on this line, we check the next one 
                                // (a common thing that happens on receipts, when the line breaks).
                                if (!price && block.lines[lineIndex + 1]) {
                                    const nextLine = block.lines[lineIndex + 1];
                                    [quantity, price] = this.getQuantityAndPriceFromLineText(nextLine.text);
                                }

                                // console.log('\n\nLine', line.text.split('\n'), '\n\n');
                                // console.log('itemNumber:', Number(itemNumber));
                                // console.log('Code:', code);
                                // console.log('Content:', content);
                                // console.log('Description:', description);
                                // console.log('Quantity:', Number(quantity), 'Price:', Number(price));

                                // If we got to this point, we have a decent enough product identified by the algorithm.
                                // So we can add it to state.
                                this.addIdentifiedProduct(itemNumber, code, content, description, quantity, price);
                            }
                        }
                    }
                }
            });
        }

        this.setState({ hasIdentifiedProducts: true }, () => {
            if (this.state.identifiedProducts.length > 0) {
                this.findProductsInCatalog();
            }
        });
    }

    /**
     * Usually, the price is the last element of a line, and before him is the quantity. 
     * Based on that behavior, we try to identify the price and the quantity of product.
     * 
     * @param {String} text - The whole text of the line, not the elements array.
     */
    getQuantityAndPriceFromLineText(text) {

        var quantity = null;
        var price = null;

        const hasDoublesInLine = this.doublesOnlyExpression.test(text);

        if (hasDoublesInLine) {
            let identifiedDoubles = text.match(this.doublesOnlyExpression);

            if (identifiedDoubles) {
                identifiedDoubles = identifiedDoubles.map(number => Number(number.replace(',', '.')));

                for (var i = identifiedDoubles.length - 1; 0 <= i; i--) {
                    if (price === null && identifiedDoubles[i] !== null && identifiedDoubles[i] !== 0) {
                        price = identifiedDoubles[i];
                    } else if (identifiedDoubles[i] !== null && identifiedDoubles[i] !== 0) {
                        quantity = identifiedDoubles[i];
                    }
                    if (price && quantity)
                        break
                }
            }

            if (!quantity) {
                const identifiedIntegers = text.match(this.integerOnlyExpressionAnywhere);
                if (identifiedIntegers) {
                    quantity = identifiedIntegers[0];
                }

            }
        }

        return [quantity, price];
    }

    addIdentifiedProduct(itemNumber, code, content, description, quantity, price) {

        let contentOfOneUnit = null;
        let contentUnit = null;
        let GTIN = null;
        let storeCode = null;
        if (content) {
            const NUMERIC_REGEXP = /[-]{0,1}[\d]*[.]{0,1}[\d]+/g;
            const number = content.match(NUMERIC_REGEXP)[0];
            const unit = content.match(/[a-zA-Z]+/g)[0];
            contentOfOneUnit = number !== undefined ? Number(number) : 1;
            contentUnit = unit !== undefined ? unit : 'un';
        }

        if (Utils.codeLooksLikeGTIN(code)) {

            code = Utils.getProperGTIN(code);
            let isValid = Utils.isValidGTIN(code);

            if (isValid) {
                GTIN = code;
            } else {
                let fixing769 = code.replace(/^(769)/, '789');
                let fixing739 = code.replace(/^(739)/, '789');
                let fixing799 = code.replace(/^(799)/, '789');
                if (Utils.isValidGTIN(fixing739))
                    GTIN = fixing739;
                else if (Utils.isValidGTIN(fixing769))
                    GTIN = fixing769;
                else if (Utils.isValidGTIN(fixing799))
                    GTIN = fixing799;
                else
                    storeCode = code;

                isValid = Utils.isValidGTIN(GTIN);
            }
        } else {
            storeCode = code;
        }

        let product = {
            'listKey': Utils.randomID(15), // used for displaying the rows for each product.
            'hasSearchedOnCatalog': false,
            'itemNumber': Number(itemNumber),
            'genericName': description,
            'GTIN': GTIN,
            'storeCode': storeCode,
            'tags': ['Novo no Marklii'],
            'packageQuantity': 1,
            'contentOfOneUnit': Number(contentOfOneUnit ? contentOfOneUnit : 1),
            'contentUnit': contentUnit ? contentUnit : GTIN ? 'un' : 'Kg',
            'quantityBought': Number(quantity ? quantity : 1),
            'discount': 0,
            'price': Number(price),
            'isPriceByWeight': GTIN ? false : true,
            'isIncomplete': true
        };

        if (this.state.identifiedProducts.includes(element => {
            if (product.GTIN) {
                return element.GTIN === product.GTIN;
            } else {
                return element.storeCode === product.storeCode;
            }
        })) {
            console.log('⛔️Already exists.');
        }

        this.setState(prevState => ({
            identifiedProducts: prevState.identifiedProducts.concat(product)
        }));
    }

    async findSingleProduct(identifiedProductIndex) {
        const identifiedProduct = this.state.identifiedProducts[identifiedProductIndex];
        const snapshot = await this.getQueryPromiseForProduct(identifiedProduct);
        const product = this.getProductFromSnapshot(snapshot);
        if (product)
            this.replaceIdentifiedProductForFoundProduct(product);
    }

    async findProductsInCatalog() {
        console.log('\n\nFinding products...');

        // Creates all get() promises.
        let queryPromises = [];
        this.state.identifiedProducts.forEach(identifiedProduct => {
            const promise = this.getQueryPromiseForProduct(identifiedProduct);
            if (promise)
                queryPromises.push(promise);
        });

        const snapshots = await Promise.all(queryPromises);

        // For each get() response, we check if a product was found in the Catalog.
        let foundProducts = [];
        snapshots.forEach(snapshot => {
            const product = this.getProductFromSnapshot(snapshot);
            if (product)
                foundProducts.push(product);
        });

        // For all products that were successfully found, we handle their appropriate placement.
        foundProducts.forEach(foundProduct => {
            this.replaceIdentifiedProductForFoundProduct(foundProduct);
        });
    }

    getQueryPromiseForProduct(product) {
        let queryPromise = null;
        if (product.GTIN) {
            if (Utils.isValidGTIN(product.GTIN)) {
                queryPromise = this.props.firebase.catalogProducts().where('GTIN', '==', product.GTIN).limit(1).get();
            }
        } else if (product.storeCode) {
            queryPromise = this.props.firebase.history().where('storeCode', '==', product.storeCode).where('storeID', '==', this.props.purchase.storeID).limit(1).get();
        }

        return queryPromise;
    }

    getProductFromSnapshot(snapshot) {
        let foundProduct = null;
        if (snapshot.docs.length > 0) {
            const productDoc = snapshot.docs[0]; // safe because length > 0
            if (!productDoc)
                return null;
            foundProduct = new CatalogItem(productDoc.id, productDoc.data());

            // If the product does not have a price attached to it, it means it came from the Catalog collection.
            // In that case, we need to update its markliiCode with the document id.
            if (!productDoc.data().markliiCode) {
                foundProduct.markliiCode = productDoc.id;
            }

            foundProduct.clearPurchaseInformation();

            // In case the foundProduct was found in the catalog, we have to manually add the 
            // storeCode (since it is not stored on Catalog products, just in History).
            foundProduct.storeCode = productDoc.data().storeCode;
        } else {
            console.log('Product not found.');
        }
        return foundProduct;
    }

    replaceIdentifiedProductForFoundProduct(foundProduct) {
        if (!foundProduct) {
            console.error('Found product is nil.');
            return
        }

        const relatedIndices = [];
        // We can never modify state directly, so we copy it without leaving a reference.
        let updatedArray = [...this.state.identifiedProducts];

        // Searches for all occurrences of such product in our identifiedProducts array. 
        for (let i = 0; i < updatedArray.length; i++) {
            let identifiedProduct = updatedArray[i];

            // Since products can have both GTIN and storeCode (when stores don't use the GTIN in the receipt),
            // we first have to check the storeCode. If not available, than we look for GTIN.
            if (foundProduct.storeCode && foundProduct.storeCode === identifiedProduct.storeCode) {
                relatedIndices.push(i);
            } else if (foundProduct.GTIN && foundProduct.GTIN === identifiedProduct.GTIN) {
                relatedIndices.push(i);
            }
        }

        // For each identified product related to the found product (from Firestore), 
        // we transfer the properties that might have already been identified.
        relatedIndices.forEach(index => {
            // We need to create a new instance of product, otherwise the memory reference will stick and be the same for all copies.
            // If the reference is the same, any change we make in one would affect the others (which is not correct).
            const newUID = foundProduct.uid + '%' + Utils.randomID(5);
            let newProduct = new HistoryItem(newUID, foundProduct);

            const identifiedProduct = updatedArray[index];
            if (identifiedProduct) {
                newProduct.itemNumber = identifiedProduct.itemNumber;
                newProduct.quantityBought = identifiedProduct.quantityBought;
                newProduct.price = identifiedProduct.price;
                newProduct.discount = identifiedProduct.discount;
                newProduct.storeCode = identifiedProduct.storeCode;
                updatedArray[index] = newProduct;
            } else {
                console.error('Could not find index of', foundProduct.GTIN ? foundProduct.GTIN : foundProduct.storeCode, foundProduct.genericName);
            }
        });
        // console.log(this.state.identifiedProducts);
        this.setState({ identifiedProducts: updatedArray });
    }

    async createNewProductInCatalog(product) {

        // As of now, we only allow GTIN products to be created automatically. 
        // In the future, we can improve this functionality to also handle storeCode products.
        if (!product.GTIN || !Utils.isValidGTIN(product.GTIN)) {
            console.error('GTIN inválido, abortando criação no catálogo');
            return;
        }

        // In order to quickly create a product in the catalog, we set up an incomplete Product object with just basic information.
        let incompleteProduct = new CatalogItem(null, product);

        const docUID = await this.props.firebase.doAddCatalogProduct(Object.assign({}, incompleteProduct));
        incompleteProduct.uid = docUID;
        incompleteProduct.markliiCode = docUID;

        this.replaceIdentifiedProductForFoundProduct(incompleteProduct);

    }

    getProductReadyToSave = (product) => {
        
        // Transform strings into numbers.
        let itemNumber = Number(product.itemNumber);
        if (itemNumber === 0) {
            let lastItem = this.props.productList[this.props.productList.length - 1];
            if (lastItem) {
                itemNumber = lastItem.itemNumber + 1;
            } else {
                itemNumber = 1;
            }
        }
        const price = typeof product.price === "number" ? product.price : Number(product.price.replace(/\D/g, '')) / 100;
        const discount = typeof product.discount === "number" ? product.discount : Number(product.price.replace(/\D/g, '')) / 100;
        const quantity = typeof product.quantityBought === "number" ? product.quantityBought : Number(product.quantityBought.replace(',', '.'));

        // In case of any error, we stop the saving or updating process and notify the user.
        if (isNaN(itemNumber) || isNaN(price) || isNaN(discount) || isNaN(quantity) || price === 0 || quantity === 0) {
            let details = [];
            details.push('\nUID: ' + product.uid);
            details.push('GTIN and Store Code: ' + product.GTIN + ', ' + product.storeCode);
            details.push('itemNumber: ' + itemNumber + ': ' + isNaN(itemNumber));
            details.push('quantity: ' + quantity + ': ' + isNaN(quantity));
            details.push('price: ' + price + ': ' + isNaN(price));
            details.push('discount: ' + discount + ': ' + isNaN(discount));
            console.log('\n\n⛔️Dados incorretos. Preencha todas as quantidades e preços do item.\n', details.join('\n'));
            return null;
        }

        let productBeingSaved = new HistoryItem(product.uid, product);
        productBeingSaved.wasBought(this.props.purchase, product.markliiCode, itemNumber, product.storeCode, quantity, price, discount);

        return productBeingSaved;
    }

    async saveIdentifiedProducts() {
        console.log('\n\nSaving identified products...');
        // 1: Create unknown GTIN products in the catalog
        for (const product of this.state.identifiedProducts) {
            if (!product.uid && product.GTIN) {
                await this.createNewProductInCatalog(product);
                console.log('🤘Created new catalog product for', product.GTIN);
            }
        }

        // 2: Save items to history

        let shouldCommit = true;
        let batch = this.props.firebase.db.batch();
        let confirmationMessages = [];
        for (var i = 0; i < this.state.identifiedProducts.length; i++) {
            let productToAdd = this.state.identifiedProducts[i];

            if (!productToAdd.markliiCode) {
                console.log('⏩ Skipping', productToAdd.GTIN ? productToAdd.GTIN : productToAdd.storeCode, 'because there is no markliiCode.');
                continue;
            }

            if (this.isProductAlreadyAdded(productToAdd)) {
                console.log('⏩ Skipping', productToAdd.GTIN ? productToAdd.GTIN : productToAdd.storeCode, 'because it was already added.');
                continue;
            }

            productToAdd = this.getProductReadyToSave(this.state.identifiedProducts[i]);

            if (productToAdd) {
                let newProductRef = this.props.firebase.history().doc();
                productToAdd.uid = newProductRef.id;
                batch.set(newProductRef, { ...productToAdd });
                confirmationMessages.push(['✅ Added', productToAdd.GTIN ? productToAdd.GTIN : productToAdd.storeCode, 'with id', newProductRef.id].join(' '));
                // console.log('\n\n', productToAdd, '\n\n');
            } else {
                shouldCommit = false
                alert('Dados incorretos. Veja detalhes no console.');
                break;
            }
        }

        if (shouldCommit) {
            batch.commit().then(() => {
                confirmationMessages.forEach(message => {
                    console.log(message);
                });
            }).catch(error => {
                console.error(error);
            });
        }
    }

    addIdentifiedProductsButtonPressed = () => {
        const message = "Você confirma a adição dos " + this.state.identifiedProducts.length + " produtos identificados?"
        if (window.confirm(message)) {
            this.saveIdentifiedProducts();
        }
    }

    onChange = (e, productIndex) => {
        let inputValue = e.target.value;
        const propertyName = e.target.name;
        let editingProduct = this.state.identifiedProducts[productIndex];

        if (propertyName === 'price' || propertyName === 'discount') {
            inputValue = Number(inputValue.replace(/\D/g, '')) / 100;
        }

        if (propertyName === "itemCode") {
            if (Utils.isValidGTIN(inputValue)) {
                editingProduct.GTIN = inputValue;
                editingProduct.storeCode = null;
            } else {
                editingProduct.GTIN = null;
                editingProduct.storeCode = inputValue;
            }
        } else {
            editingProduct[propertyName] = inputValue;
        }

        let updatedArray = [...this.state.identifiedProducts];
        updatedArray[productIndex] = editingProduct;

        this.setState({ identifiedProducts: updatedArray });
        // this.setState({ identifiedProducts: updatedArray }, () => {
        //     console.log('Changed', propertyName, 'to', inputValue);
        // });
    }

    removeIdentifiedProduct = (e) => {
        const indexToDelete = e.target.name;
        let updatedArray = Object.assign([], this.state.identifiedProducts);
        updatedArray.splice(indexToDelete, 1);
        this.setState({ identifiedProducts: updatedArray });
    }

    isProductAlreadyAdded(product) {
        let isProductAlreadyAdded = false;
        if (this.props.productList && this.props.productList.length > 0) {
            isProductAlreadyAdded = this.props.productList.some(addedProduct => {
                return product.storeCode ? addedProduct.storeCode === product.storeCode : addedProduct.GTIN === product.GTIN;
            });
        }
        return isProductAlreadyAdded;
    }

    showIdentifiedProductsRows() {
        let tableRows = <tr><td colSpan="8">Nenhum produto identificado.</td></tr>

        if (this.state.identifiedProducts.length > 0) {
            tableRows = (
                this.state.identifiedProducts.map((product, index) => {

                    let isProductAlreadyAdded = this.isProductAlreadyAdded(product);

                    // Defines how we will show the product description.
                    let productDescription = (
                        <input
                            type='text'
                            name='genericName'
                            autoComplete="off"
                            disabled={product.uid}
                            defaultValue={product.uid ? product.displayName() : product.genericName}
                            onChange={(e) => this.onChange(e, index)}
                        />
                    );

                    if (product.uid)
                        productDescription = <div className={styles.FoundProduct}>{product.displayName()}</div>

                    // Sets tag info about the product code.
                    let tagStyles = [styles.Tag];
                    let tagText = "Próprio";
                    if (Utils.codeLooksLikeGTIN(product.GTIN ? product.GTIN : product.storeCode)) {
                        if (Utils.isValidGTIN(product.GTIN ? product.GTIN : product.storeCode)) {
                            tagText = "GTIN";
                            tagStyles.push(styles.TagGTIN);
                        } else {
                            tagText = "GTIN Inválido";
                            tagStyles.push(styles.TagGTINInvalid);
                        }
                    } else {
                        tagStyles.push(styles.TagStoreCode);
                    }

                    if (isProductAlreadyAdded)
                        tagText = "Já adicionado";

                    // Formats numbers.
                    const priceText = product.price.toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2 });
                    const discountText = product.discount.toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2 });
                    const quantityText = product.quantityBought.toLocaleString('pt-BR', { style: 'decimal' });

                    const key = [index, product.uid, product.listKey].join('-');
                    return (
                        <tr key={key} className={isProductAlreadyAdded ? styles.ProductAlreadyAdded : null}>
                            <td className={styles.ItemNumber}>
                                <input
                                    type='text'
                                    name='itemNumber'
                                    autoComplete="off"
                                    defaultValue={product.itemNumber || index + 1}
                                    onChange={(e) => this.onChange(e, index)}
                                />
                            </td>
                            <td className={styles.GTIN}>
                                <input
                                    type='text'
                                    name='itemCode'
                                    autoComplete="off"
                                    disabled={product.uid}
                                    defaultValue={product.GTIN ? Utils.isValidGTIN(product.GTIN) ? product.GTIN : product.storeCode : product.storeCode || ''}
                                    onChange={(e) => this.onChange(e, index)}
                                    onBlur={() => this.findSingleProduct(index)}
                                />
                                <div className={tagStyles.join(' ')}>{tagText}</div>
                            </td>
                            <td className={styles.ProductDescription}>
                                {productDescription}
                                <div className={styles.ProductDetails}>
                                    {product.uid ? [product.brand, product.displayPackageQuantityAndContent(), ...product.tags].filter(element => element !== null).join(', ') : "Produto não encontrado."}
                                </div>
                            </td>
                            <td className={styles.Currency}>
                                <input
                                    type='text'
                                    name='quantityBought'
                                    autoComplete="off"
                                    value={quantityText}
                                    onChange={(e) => this.onChange(e, index)}
                                />
                            </td>
                            <td className={styles.Currency}>
                                <input
                                    type='text'
                                    name='price'
                                    autoComplete="off"
                                    value={priceText}
                                    onChange={(e) => this.onChange(e, index)}
                                />
                            </td>
                            <td className={styles.Currency}>
                                <input
                                    type='text'
                                    name='discount'
                                    autoComplete="off"
                                    value={discountText}
                                    onChange={(e) => this.onChange(e, index)}
                                />
                            </td>
                            <td><img className={styles.RemoveIcon} src={removeIcon} title="Remover da lista" alt="Remover da lista" name={index} onClick={this.removeIdentifiedProduct} /></td>
                        </tr>
                    );
                })
            );
        }

        return tableRows;
    }

    render() {
        return (
            <div className={styles.ProductList}>
                <div className={styles.TableContainer}>
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Código</th>
                                <th className={styles.AlignLeft}>Descrição</th>
                                <th>Qtde.</th>
                                <th>Preço</th>
                                <th>Desc.</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th colSpan="8"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.showIdentifiedProductsRows()}
                        </tbody>
                    </table>
                </div>

                <div className={styles.NextStepButton}>
                    <Button onClick={this.addIdentifiedProductsButtonPressed}>Adicionar itens selecionados</Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        catalog: state.catalog.catalog
    };
}

export default withFirebase(connect(mapStateToProps)(AddBulkPurchaseItem));