/**
 * Image.js | UI Component
 * An image view with options to zoom and span.
 * 
 * Props: 
 * - imageURL: [array] the URL of the image.
 * - draggable: [boolean] tells if the image can be moved inside its div. (NOT IMPLEMENTED YET)
 * - zoomable: [boolean] tells if the image can be zoomed in and out. (NOT IMPLEMENTED YET)
 */

import React, { Component } from 'react';
import styles from './Image.module.css';

class Image extends Component {

    state = {
        width: '100%',
        translate: { x: 0, y: 0 }
    }

    imageRef = React.createRef();

    mouseDown = (event) => {
        // event.preventDefault();

        if (this.containerSize === null || this.containerSize === undefined) {
            this.containerSize = { width: 0, height: 0 };
            this.containerSize.width = this.imageRef.current.width;
            this.containerSize.height = this.imageRef.current.height;
        }

        if (event.shiftKey) {
            const currentWidth = this.imageRef.current.width;
            let newWidth = currentWidth + (event.deltaY);

            if (newWidth < this.containerSize.width) {
                newWidth = this.containerSize.width;
            }
            this.setState({ width: newWidth });
        } else {
            // const newTranslate = {
            //     x: this.state.translate.x - event.deltaX,
            //     y: this.state.translate.y - event.deltaY
            // }

            // // Check if left side is empty.
            // if (newTranslate.x > 0) {
            //     newTranslate.x = 0;
            // }
            // if (newTranslate.y > 0) {
            //     newTranslate.y = 0;
            // }

            // // Check if right side is empty.
            // const invisibleWidth = (this.imageRef.current.width + newTranslate.x) - this.containerSize.width;
            // const invisibleHeight = (this.imageRef.current.height + newTranslate.y) - 150;

            // console.log(newTranslate.y, invisibleHeight);

            // if (invisibleWidth < 0) {
            //     newTranslate.x -= invisibleWidth;
            // }
            // if (invisibleHeight < 0) {
            //     newTranslate.y -= invisibleHeight;
            // }

            
            // // console.log('Original size', this.containerSize);
            // // console.log('Invisible image', invisibleHeight);

            // this.setState({ translate: newTranslate });
        }
    }

    render() {

        const {
            width,
            translate
        } = this.state;

        const transformTranslate = `translate(${translate.x}px, ${translate.y}px)`;

        return (
            <div ref={this.containerRef} className={styles.Container} onWheelCapture={this.mouseDown}>
                <img
                    ref={this.imageRef}
                    src={(this.props.receiptsURLs || ['hello'])[0]}
                    style={{ width: width, transform: transformTranslate }}
                    alt='alt'
                >
                </img>
            </div>
        );
    }
}

export default Image;