/**
 * NavigationItem.js | Component
 * One item of the navigation list, styled with active state and icon.
 * 
 * Props: 
 * - link: the URL to which the link should direct to.
 * - shouldBeExactMatch: an option that tells NavLink if the active CSS class should be aplied only if the URL is an exact match.
 * - notification: the number to display in the notification tag of the item.
 * - icon: an image src to display. If no one is given, we show a default one.
 */

import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './NavigationItem.module.css'
import defaultIcon from '../../../../assets/images/dashboard-icon@3x.png';

const navigationItem = (props) => {

    // This is the little tag to the right of the item.
    const notification = props.notification ? <div className={styles.Notification}>{props.notification}</div> : null

    const shouldBeExactMatch = props.link === '/' ? true : false;
    
    return (
        <li className={styles.NavigationItem}>
            <NavLink to={props.link} exact={shouldBeExactMatch} activeClassName={styles.active}>
                <img src={props.icon ? props.icon : defaultIcon} alt={props.children} />
                <div>{props.children}</div>
                {notification}
            </NavLink>
        </li>
    );
};

export default navigationItem;