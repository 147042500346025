/**
 * Store.js | Data Model
 * An object representing a Store.
 * 
 * 
 * Properties:
 * - 
 * - 
*/

class Store {

    constructor(uid, jsonPayload) {

        this.uid = uid;
        this.city                = jsonPayload.city ? jsonPayload.city : null;
        this.cnpj                = jsonPayload.cnpj ? jsonPayload.cnpj : null;
        this.location            = jsonPayload.location ? jsonPayload.location : null;
        this.name                = jsonPayload.name ? jsonPayload.name : null;
        this.number              = jsonPayload.number ? jsonPayload.number : null;
        this.registeredByUserID  = jsonPayload.registeredByUserID ? jsonPayload.registeredByUserID : null; 
        this.registryName        = jsonPayload.registryName ? jsonPayload.registryName : null; 
        this.state               = jsonPayload.state ? jsonPayload.state : null;
        this.storeID             = jsonPayload.storeID ? jsonPayload.storeID : null;
        this.street              = jsonPayload.street ? jsonPayload.street : null;
        this.totalCatalogItems   = jsonPayload.totalCatalogItems ? jsonPayload.totalCatalogItems : 0;
        this.totalPricesMapped   = jsonPayload.totalPricesMapped ? jsonPayload.totalPricesMapped : null;
        this.totalPurchases      = jsonPayload.totalPurchases ? jsonPayload.totalPurchases : null;
        this.totalUsers          = jsonPayload.totalUsers ? jsonPayload.totalUsers : null;
        this.registrationDate    = jsonPayload.registrationDate ? jsonPayload.registrationDate.toDate() : new Date(2018,10,1); // Older stores from the beta phase did not have registrationDate.
    }
}

export default Store;