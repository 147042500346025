/**
 * purchaseActions.js | Redux Actions File
 * All purchases-related actions that manage global state.
 * 
 */

import * as actionTypes from './types';

export const addPurchase = (purchase) => {
    return {
        type: actionTypes.ADD_PURCHASE,
        purchase: purchase
    };
};

export const removePurchase = (purchase) => {
    return {
        type: actionTypes.REMOVE_PURCHASE,
        purchase: purchase
    };
};

export const updatePurchase = (purchase) => {
    return {
        type: actionTypes.UPDATE_PURCHASE,
        purchase: purchase
    };
};

export const setUnprocessedNumber = (number) => {
    return {
        type: actionTypes.SET_UNPROCESSED_NUMBER,
        numberOfUnprocessedReceipts: number
    };
};