/**
 * Loading.js | Component
 Loading view with spinning icon.
 * 
 * Props: none.
 */

import React from 'react';
import styles from './Loading.module.css';
import markliiLogo from '../../assets/images/logo-v-gray@3x.png';
import loadingIcon from '../../assets/images/loading-icon-gray@3x.png';


const logo = (props) => (
    <div className={styles.Container}>
        <img src={markliiLogo} alt="Marklii" className={styles.Logo} />
        <img src={loadingIcon} alt="Carregando..." className={styles.Loading} />
        {props.children ? props.children : 'Carregando...'}
    </div>
);

export default logo;