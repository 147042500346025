

export const HOME = '/';
export const LOGOUT = '/logout';
export const CATALOG = '/catalog';
export const USERS = '/users';
export const PURCHASES = '/purchases';
export const READ_PURCHASES = PURCHASES + '/read';
export const PURCHASES_ALL = PURCHASES + '/all';
export const PRICES = '/prices';
export const STORES = '/stores';
export const ERRORS = '/errors';

export const CHANGE_PASSWORD = '/profile/changePassword';
export const UPLOAD_PHOTO = '/profile/uploadPhoto';

