import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withFirebase } from './components/Firebase';

import * as actions from './store/actions';
import * as routes from './config/routes';

import AuthView from './containers/AuthView/AuthView';
import Layout from './hoc/Layout/Layout';
import PurchasesView from './containers/PurchasesView/PurchasesView';
import AllPurchasesView from './containers/PurchasesView/AllPurchasesView/AllPurchasesView';
import ProcessingView from './containers/PurchasesView/ProcessingView/ProcessingView';
import Loading from './components/Loading/Loading';
import Logout from './containers/Logout/Logout';
import UnknownRoute from './components/UnknownRoute/UnknownRoute';
import Purchase from './models/Purchase';
import CatalogItem from './models/CatalogItem';
import CatalogView from './containers/CatalogView/CatalogView';
import ErrorsView from './containers/ErrorsView/ErrorsView';
import UsersView from './containers/UsersView/UsersView';
import PricesView from './containers/PricesView/PricesView';


class App extends Component {

  constructor(props) {
    super(props);
    console.log('Constructing App...');
    this.state = { hasLoadedPurchases: false, hasLoadedCatalog: false };
  }

  componentDidMount() {
    this.authListener();
  }

  componentWillUnmount() {
    this.listener();
    this.removePurchasesListener();
    this.removeCatalogListener();
    this.removeMetricsListener();
  }

  authListener() {
    this.listener = this.props.firebase.auth.onAuthStateChanged((user) => {
      if (user) {
        console.log('[Auth State] Logged in.');
        this.listenToPurchases();
        this.listenToCatalog();
        // this.setState({ hasLoadedCatalog: true });
        this.listenToPurchasesMetrics();
      } else {
        if (this.props.currentUser !== null) {
          this.props.logOut();
        }
        if (this.removePurchasesListener) {
          this.removePurchasesListener();
          this.removeCatalogListener();
        }
        console.log('[Auth State] Logged out.');
      }
    });
  }

  listenToPurchases() {

    this.removePurchasesListener = this.props.firebase.purchases()
      .where('hasBeenProcessed', '==', false)
      .where('receiptStatus', '==', null)
      .orderBy('uploadDate', 'desc')
      .onSnapshot(snapshot => {

        if (snapshot.docs.length > 0) {

          snapshot.docChanges().forEach(change => {
            const purchase = new Purchase(change.doc.id, change.doc.data());
            const isReadyToProcess = purchase.receiptStatus == null && purchase.receiptsUIDs.length > 0;

            if (!isReadyToProcess) {
              console.log('Updating purchase ' + purchase.uid + ' receiptStatus as Waiting.');
              this.props.firebase.doSetOnPurchase(purchase.uid, { receiptStatus: 'waiting' });
            }

            // if (change.type === 'added' && isReadyToProcess) {
            if (change.type === 'added') {
              // console.log('New purchase: ', purchase.uid);
              this.props.addPurchase(purchase);
            }
            if (change.type === 'modified') {
              // console.log('Modified purchase: ', purchase.uid);
              // if (this.props.purchases.filter(p => p.uid === purchase.uid).length > 0) {
              //   if (isReadyToProcess === false) {
              //     this.props.removePurchase(purchase);
              //   } else {
              this.props.updatePurchase(purchase);
              //   }
              // } else if (isReadyToProcess === true) {
              //   this.props.addPurchase(purchase);
              // }
            }
            if (change.type === 'removed') {
              // console.log('Removed purchase: ', purchase.uid);
              this.props.removePurchase(purchase);
            }
          });

          this.setState({ hasLoadedPurchases: true });
        } else {
          this.props.removePurchase();
          this.setState({ hasLoadedPurchases: true });
        }
      }, function (error) {
        console.log(error.message);
      });
  }

  listenToCatalog() {
    this.removeCatalogListener = this.props.firebase.catalogProducts().orderBy('genericName').onSnapshot(snapshot => {
      if (snapshot.docs.length > 0) {
        snapshot.docChanges().forEach(change => {
          const product = new CatalogItem(change.doc.id, change.doc.data());
          product.markliiCode = change.doc.id;

          if (change.type === 'added') {
            this.props.addCatalogItem(product);
          }
          if (change.type === 'modified') {
            // console.log('Modified catalog item: ', product.uid);
            this.props.updateCatalogItem(product);
          }
          if (change.type === 'removed') {
            console.log('Removed catalog item: ', product.uid);
            this.props.removeCatalogItem(product);
          }

        });

        console.log('Catalog loaded successfully...');
        this.setState({ hasLoadedCatalog: true });
      } else {
        console.log('There are no catalog items.');
      }
    }, error => {
      console.error(error);
    })

  }

  listenToPurchasesMetrics() {
    this.removeMetricsListener = this.props.firebase.metricsOf('Purchases').onSnapshot(doc => {
      this.props.setUnprocessedNumber(doc.data().unprocessedPurchases);
      document.title = '(' + doc.data().unprocessedPurchases + ') Marklii Control Tower';
    });
  }


  render() {

    let presentingView = (
      <div></div>
    );

    if (this.props.isAuthenticated) {
      if (this.state.hasLoadedCatalog && this.state.hasLoadedPurchases) {
        presentingView = (
          <Layout>
            <Switch>
              <Route path={routes.PURCHASES} exact component={PurchasesView} />
              <Route path={routes.PURCHASES_ALL} exact component={AllPurchasesView} />
              <Route path={routes.READ_PURCHASES + '/:uid'} exact component={ProcessingView} />
              <Route path={routes.CATALOG} exact component={CatalogView} />
              <Route path={routes.USERS} exact component={UsersView} />
              <Route path={routes.PRICES} exact component={PricesView} />
              <Route path={routes.LOGOUT} exact component={Logout} />
              <Route path={routes.ERRORS} exact component={ErrorsView} />
              <Route path='/' exact component={PurchasesView} />
              <Route path='/' component={UnknownRoute} />
            </Switch>
          </Layout>
        );
      } else {
        presentingView = <Loading>Carregando catálogo...</Loading>
      }
    } else {
      presentingView = <AuthView />
    }

    return (
      <div>
        {presentingView}
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    isAuthenticated: state.auth.currentUser !== null,
    catalog: state.catalog.catalog,
    purchases: state.purchases.purchaseList,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch(actions.logOut()),
    addPurchase: (purchase) => dispatch(actions.addPurchase(purchase)),
    removePurchase: (purchase) => dispatch(actions.removePurchase(purchase)),
    updatePurchase: (purchase) => dispatch(actions.updatePurchase(purchase)),
    setUnprocessedNumber: (number) => dispatch(actions.setUnprocessedNumber(number)),
    addCatalogItem: (item) => dispatch(actions.addCatalogItem(item)),
    removeCatalogItem: (item) => dispatch(actions.removeCatalogItem(item)),
    updateCatalogItem: (item) => dispatch(actions.updateCatalogItem(item))
  }
}

export default withFirebase(withRouter(connect(mapStateToProps, mapDispatchToProps)(App)));