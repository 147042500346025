/**
 * Step.js | UI Component
 * 
 * 
 * Props: 
 * - description: [string] description of the step, optional property
 * - title: [string] title of the step
 * - status: [string] to specify the status. Optional values are: waiting, current, done, error.
 * - onClick: [function] 
 */

import React from 'react';
import styles from './Step.module.css';

const step = (props) => {

    let status = styles.Waiting;
    switch (props.status) {
        case 'current':
            status = styles.Current;
            break;
        
        case 'done':
            status = styles.Done;
            break;
        
        case 'error':
            status = styles.Error;
            break;
    
        default:
            status = styles.Waiting;
            break;
    }

    return (
        <div className={styles.Container} onClick={props.onClick}>
            <div className={[styles.StepCircle, status].join(' ')}>{props.status === 'done' ? '✓' : props.number}</div>
            <div className={styles.TitleBox}>
                <div className={[styles.Title, status].join(' ')}>{props.title}</div>
                <div className={[styles.Description, status].join(' ')}>{props.description}</div>
            </div>
        </div>
    );
}

export default step;