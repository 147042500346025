/**
 * PurchaseList.js | Component
 * A list of recent purchases.
 * 
 * Props:
 * - purchaseList: an array of Purchases.
 */

import React from 'react';
import { Link } from 'react-router-dom';
import PurchaseItem from './PurchaseItem/PurchaseItem';
import styles from './PurchaseList.module.css';
import * as routes from '../../../config/routes';

const purchaseList = (props) => {

    return (
        <div className={styles.List}>
            {props.purchaseList.map(purchase => (
                <Link key={purchase.uid} to={routes.READ_PURCHASES + '/' + purchase.uid}>
                    <PurchaseItem purchase={purchase} />
                </Link>
            ))}
        </div>
    );
}

export default purchaseList;