/**
 * Logout.js | Container
 * Performs the logout and redirects to '/'.
 * 
 * 
 * Global state actions:
 * - logOut()
* An logout js
 */


import React, { Component } from 'react';

// Libraries 
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { withFirebase } from '../../components/Firebase/';

class Logout extends Component {

    componentDidMount() {
        this.props.firebase.doSignOut(); // Signs out from Firebase.
        this.props.logOut();             // Tells our global store that no user is signed in.
    }

    render() {
        return (
            <Redirect to='/' />
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logOut: () => dispatch(actions.logOut())
    };
};

export default withFirebase(connect(null, mapDispatchToProps)(Logout));
