import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { BrowserRouter } from 'react-router-dom';
import reducers from './store/reducers/';
import Firebase, { FirebaseContext } from './components/Firebase/';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Used for Redux Devtools.
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        stateSanitizer: (state) => state.catalog ? { ...state, catalog: '<<LONG_BLOB>>' } : state
    }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk),
  // other store enhancers if any
);

// Create redux store.
console.log('Creating redux store...')
// const store = createStore(reducers, {}, composeEnhancers(applyMiddleware(thunk)));
const store = createStore(reducers, enhancer);

/**
 * <FirebaseContext.Provider> provides the whole component tree with the Firebase instance that was initiated.
 * <Provider> provides the whole component tree with access to the redux global store.
 * <BrowserRouter> enables routing throughout the app.
 */

const app = (
    <FirebaseContext.Provider value={new Firebase()}>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </FirebaseContext.Provider>
);

// Render App to the DOM. <Provider> is needed by Redux.
ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
