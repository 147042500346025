/**
 * EditCatalogItem.js | Container
 * A component used for editing catalog products. Can be used anywhere.
 * 
 * Props: 
 * - markliiCode: [String] the uid of the catalog item.
 * - finishEditing: [function] an optional function used to close the editing window.
 * - onSave: [function] Returns the product that was edited to the parent component. Optional usage.
*/

import React, { Component } from 'react';
import { withFirebase } from '../../components/Firebase';
import Button from '../../components/UI/Button/Button';
import Input, { InputElement } from '../../components/UI/Input/Input';
import TitleSubtitle from '../../components/UI/TitleSubtitle/TitleSubtitle';
import Tag from '../../components/UI/Tag/Tag';
import CatalogItem from '../../models/CatalogItem';

import styles from './EditCatalogItem.module.css';

class EditCatalogItem extends Component {

    constructor(props) {
        super(props);
        this.resetInputs();
        this._isMounted = false;
    }

    state = {
        product: null,
        editedProduct: null,
        productNotFound: false
    }

    resetInputs() {
        this.gtinInput = new InputElement('gtin', 'input', 'text', null, null, null, {});
        this.genericNameInput = new InputElement('genericName', 'input', 'text', null, null, null, { 'required': true });
        this.genericTypeInput = new InputElement('genericType', 'input', 'text', null, null, null, { 'required': false });
        this.isNameFemale = new InputElement('isNameFemale', 'checkbox', 'checkbox', null, false, null, { 'required': true });
        this.alsoKnownAsInput = new InputElement('alsoKnownAs', 'input', 'text', null, null, null, { 'required': false });
        this.variationInput = new InputElement('variation', 'input', 'text', null, null, null, { 'required': false });
        this.storeSectionInput = new InputElement('storeSection', 'input', 'text', null, null, null, { 'required': true });
        this.brandInput = new InputElement('brand', 'input', 'text', null, null, null, { 'required': false });
        this.manufacturerInput = new InputElement('manufacturer', 'input', 'text', null, null, null, { 'required': false });
        this.tagsInput = new InputElement('tags', 'input', 'text', null, null, null, { 'required': false });
        this.quantityInput = new InputElement('quantity', 'input', 'text', null, null, null, { 'required': true, 'isNumber': true });
        this.contentInput = new InputElement('content', 'input', 'text', null, null, null, { 'required': true, 'isNumber': true });
        this.contentUnitInput = new InputElement('contentUnit', 'input', 'text', null, null, null, { 'required': true });
        this.wordForOneUnitInput = new InputElement('wordForOneUnit', 'input', 'text', null, null, null, { 'required': false });
        this.isPriceByWeightInput = new InputElement('isPriceByWeight', 'checkbox', 'checkbox', null, false, null, { 'required': true });
        this.isFemaleWordForOneUnitInput = new InputElement('isFemaleWordForOneUnit', 'checkbox', 'checkbox', null, false, null, { 'required': true });
        this.isIncompleteInput = new InputElement('isIncomplete', 'checkbox', 'checkbox', null, false, null, { 'required': true });
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadProduct();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate() {
        if (this.state.product) {
            if (this.state.product.uid !== this.props.markliiCode) {
                this.setState({ product: null, editedProduct: null });
                this.resetInputs();
                this.loadProduct();
            }
        }
    }

    loadProduct = () => {
        this.props.firebase.catalogProduct(this.props.markliiCode).get().then(doc => {
            if (doc.exists) {
                let catalogProduct = new CatalogItem(doc.id, doc.data());
                catalogProduct.markliiCode = doc.id;
                this.updateInputs(catalogProduct);
                this.setState({ product: catalogProduct, editedProduct: catalogProduct });
            } else {
                this.setState({ productNotFound: true });
                console.error('Catalog product does not exist.');
            }
        }).catch(error => {
            console.error(error);
        });
    }

    updateInputs(product) {
        this.gtinInput.elementConfig.defaultValue = product.GTIN;
        this.genericNameInput.elementConfig.defaultValue = product.genericName;
        this.genericTypeInput.elementConfig.defaultValue = product.genericType;
        this.isNameFemale.updateInputValue(product.isFemaleGenericProduct || false);
        this.alsoKnownAsInput.elementConfig.defaultValue = product.alsoKnownAs.join(', ');
        this.variationInput.elementConfig.defaultValue = product.productVariation;
        this.storeSectionInput.elementConfig.defaultValue = product.storeSection;
        this.brandInput.elementConfig.defaultValue = product.brand;
        this.manufacturerInput.elementConfig.defaultValue = product.manufacturer;
        this.tagsInput.elementConfig.defaultValue = product.tags.join(', ');
        this.quantityInput.elementConfig.defaultValue = product.packageQuantity;
        this.contentInput.elementConfig.defaultValue = product.contentOfOneUnit;
        this.contentUnitInput.elementConfig.defaultValue = product.contentUnit;
        this.wordForOneUnitInput.elementConfig.defaultValue = product.wordForOneUnit;
        this.isPriceByWeightInput.updateInputValue(product.isPriceByWeight || false);
        this.isFemaleWordForOneUnitInput.updateInputValue(product.isFemaleWordForOneUnit || false);
        this.isIncompleteInput.updateInputValue(product.isIncomplete || false);
    }

    inputChanged(e, input) {
        input.updateInputValue(input.elementConfig.type === 'checkbox' ? e.target.checked : e.target.value);
        this.editProduct(input);
    }

    editProduct(input) {
        let editedProduct = Object.assign({}, this.state.editedProduct);

        switch (input.key) {
            case 'gtin':
                editedProduct.GTIN = input.value.trim();
                break;
            case 'genericName':
                editedProduct.genericName = input.value.trim();
                break;
            case 'genericType':
                editedProduct.genericType = input.value.trim();
                break;
            case 'isNameFemale':
                editedProduct.isFemaleGenericProduct = input.value;
                break;
            case 'alsoKnownAs':
                const filtered = input.value.split(',').map(item => item.trim()).filter(Boolean);
                editedProduct.alsoKnownAs = filtered;
                break;
            case 'variation':
                editedProduct.productVariation = input.value.trim();
                break;
            case 'storeSection':
                editedProduct.storeSection = input.value.trim();
                break;
            case 'brand':
                editedProduct.brand = input.value.trim();
                break;
            case 'manufacturer':
                editedProduct.manufacturer = input.value.trim();
                break;
            case 'tags':
                const filteredTags = input.value.split(',').map(item => item.trim()).filter(Boolean);
                editedProduct.tags = filteredTags;
                break;
            case 'quantity':
                editedProduct.packageQuantity = input.value.trim();
                break;
            case 'content':
                editedProduct.contentOfOneUnit = input.value.trim();
                break;
            case 'contentUnit':
                editedProduct.contentUnit = input.value.trim();
                break;
            case 'wordForOneUnit':
                editedProduct.wordForOneUnit = input.value.trim();
                break;
            case 'isPriceByWeight':
                editedProduct.isPriceByWeight = input.value;
                break;
            case 'isFemaleWordForOneUnit':
                editedProduct.isFemaleWordForOneUnit = input.value;
                break;
            case 'isIncomplete':
                editedProduct.isIncomplete = input.value;
                break;

            default:
                break;
        }

        const newProduct = new CatalogItem(editedProduct.uid, editedProduct);
        this.setState({ editedProduct: newProduct });

    }

    finishEditing = () => {
        this.props.finishEditing();
    }

    removeTag = (tagToRemove) => {
        this.setState(function (prevState) {
            const newTags = prevState.editedProduct.tags.filter(tag => tag !== tagToRemove);
            prevState.editedProduct.tags = newTags;
            return {
                editedProduct: prevState.editedProduct
            };
        });
    }

    saveProduct = () => {
        this.props.firebase.catalogProduct(this.state.editedProduct.uid).set({ ...this.state.editedProduct }, { merge: true }).then(() => {
            console.log('Catalog info updated. ID:', this.state.editedProduct.uid);

            // After saving it in Firestore, we update the product in EditCatalogItem's state.
            this._isMounted && this.setState({ product: this.state.editedProduct });

            // And we update the product in ProcessingView's state (parent component).
            if (this.props.onSave) {
                this.props.onSave(this.state.editedProduct);
            }

            // Then, we update all products in the History that matches the markliiCode. 
            // TODO: In the future, we should ask the user if he'd like to update products in History or not.
            this.props.firebase.history().where('markliiCode', '==', this.state.editedProduct.uid).get().then(querySnapshot => {

                // Get all History document IDs that need to be updated.
                const arrayOfHistoryItemsToUpdate = [];
                querySnapshot.forEach(doc => { arrayOfHistoryItemsToUpdate.push(doc.id) });

                var batch = this.props.firebase.db.batch();
                arrayOfHistoryItemsToUpdate.forEach(id => {
                    const itemDoc = this.props.firebase.historyItem(id);
                    batch.set(itemDoc, { ...this.state.editedProduct }, { merge: true });
                });

                batch.commit().then(() => {
                    console.log(arrayOfHistoryItemsToUpdate.length, 'document(s) in History updated.');
                }).catch(error => {
                    console.error(error);
                });

            }).catch(error => {
                console.log(error);
            });
        }).catch(error => {
            console.error(error);
        });
    }

    searchInGoogle = () => {
        if (!this.state.product.GTIN) return;

        window.open('https://www.google.com/search?q=' + this.state.product.GTIN, 'Product Search', 'location=yes,width=770,height=900,top=0,left=900,scrollbars=yes,status=yes');
    }

    render() {

        let inputFields = null;
        if (this.state.editedProduct) {
            inputFields = (
                <div className={styles.Fields}>
                    <TitleSubtitle title='GTIN' subtitle='Código de barras oficial do produto, se houver.' />
                    <div className={styles.GTINInput}>
                        <div className={styles.HorizontalFlex}>
                            <Input
                                key={this.gtinInput.key}
                                name={this.gtinInput.key}
                                element={this.gtinInput}
                                onChange={(event) => this.inputChanged(event, this.gtinInput)}
                            />
                            <div><Button size='small' icon='search' onClick={this.searchInGoogle} /></div>
                            <div><Button size='small'>GS1</Button></div>
                        </div>
                    </div>
                    <br />
                    <div className={styles.HorizontalFlex}>
                        <div>
                            <TitleSubtitle title='Produto Genérico: *' subtitle='Ex.: Leite, Queijo, Suco, Alface...' />
                            <Input
                                key={this.genericNameInput.key}
                                name={this.genericNameInput.key}
                                element={this.genericNameInput}
                                onChange={(event) => this.inputChanged(event, this.genericNameInput)}
                            />
                        </div>
                        <div>
                            <TitleSubtitle title='Tipo Genérico:' subtitle='Ex.: Integral, Desnatado, de Laranja...' />
                            <Input
                                key={this.genericTypeInput.key}
                                name={this.genericTypeInput.key}
                                element={this.genericTypeInput}
                                onChange={(event) => this.inputChanged(event, this.genericTypeInput)}
                            />
                        </div>
                    </div>
                    <label>
                        <div className={styles.HorizontalFlex}>
                            <div className={styles.TopLabel}>É uma palavra feminina?</div>
                            <Input
                                key={this.isNameFemale.key}
                                name={this.isNameFemale.key}
                                element={this.isNameFemale}
                                onChange={(event) => this.inputChanged(event, this.isNameFemale)}
                            />
                        </div>
                    </label>
                    <br />
                    <TitleSubtitle title='Também conhecido como:' subtitle='Inserir termos separados por vírgula. Ex.: Bolacha, Biscoito' />
                    <Input
                        key={this.alsoKnownAsInput.key}
                        name={this.alsoKnownAsInput.key}
                        element={this.alsoKnownAsInput}
                        onChange={(event) => this.inputChanged(event, this.alsoKnownAsInput)}
                    />
                    <br />
                    <TitleSubtitle title='Variação:' subtitle='Variações específicas de um produto que não são comuns a todos os tipos genéricos. ' />
                    <Input
                        key={this.variationInput.key}
                        name={this.variationInput.key}
                        element={this.variationInput}
                        onChange={(event) => this.inputChanged(event, this.variationInput)}
                    />
                    <br />
                    <TitleSubtitle title='Seção do Mercado: *' subtitle='Em qual seção do mercado esse produto é encontrado?' />
                    <Input
                        key={this.storeSectionInput.key}
                        name={this.storeSectionInput.key}
                        element={this.storeSectionInput}
                        onChange={(event) => this.inputChanged(event, this.storeSectionInput)}
                    />
                    <br />
                    <div className={styles.HorizontalFlex}>
                        <div>
                            <TitleSubtitle title='Marca:' subtitle='Como o produto é conhecido no mercado.' />
                            <Input
                                key={this.brandInput.key}
                                name={this.brandInput.key}
                                element={this.brandInput}
                                onChange={(event) => this.inputChanged(event, this.brandInput)}
                            />
                        </div>
                        <div>
                            <TitleSubtitle title='Empresa produtora: ' subtitle='Ex.: Unilever, Johnson & Johnson.' />
                            <Input
                                key={this.manufacturerInput.key}
                                name={this.manufacturerInput.key}
                                element={this.manufacturerInput}
                                onChange={(event) => this.inputChanged(event, this.manufacturerInput)}
                            />
                        </div>
                    </div>
                    <br />
                    <TitleSubtitle title='Tags (características):' subtitle='Qualquer característica que possa servir como filtro de busca.' />
                    <Input
                        key={this.tagsInput.key}
                        name={this.tagsInput.key}
                        element={this.tagsInput}
                        onChange={(event) => this.inputChanged(event, this.tagsInput)}
                    />
                    <div className={styles.Tags}>
                        {this.state.editedProduct.tags.map(tag => (
                            <Tag key={tag} onRemoval={() => this.removeTag(tag)}>{tag}</Tag>
                        ))}
                    </div>
                    <br />
                    <div className={styles.HorizontalFlex}>
                        <div>
                            <TitleSubtitle title='Quantidade: *' subtitle={'Ex.: Yakult tem 6 unidades.'} />
                            <Input
                                key={this.quantityInput.key}
                                name={this.quantityInput.key}
                                element={this.quantityInput}
                                onChange={(event) => this.inputChanged(event, this.quantityInput)}
                            />
                        </div>
                        <div>
                            <TitleSubtitle title='Conteúdo: *' subtitle='De 1 un. Ex.: 200, 400, 1' />
                            <Input
                                key={this.contentInput.key}
                                name={this.contentInput.key}
                                element={this.contentInput}
                                onChange={(event) => this.inputChanged(event, this.contentInput)}
                            />
                        </div>
                        <div>
                            <TitleSubtitle title='Unidade: *' subtitle='Ex.: Kg, g, mL, m, cm.' />
                            <Input
                                key={this.contentUnitInput.key}
                                name={this.contentUnitInput.key}
                                element={this.contentUnitInput}
                                style={{ 'width': '50px' }}
                                onChange={(event) => this.inputChanged(event, this.contentUnitInput)}
                            />
                            <label>
                                <div className={styles.HorizontalFlex}>
                                    <div className={styles.TopLabel}>Por peso</div>
                                    <Input
                                        key={this.isPriceByWeightInput.key}
                                        name={this.isPriceByWeightInput.key}
                                        element={this.isPriceByWeightInput}
                                        onChange={(event) => this.inputChanged(event, this.isPriceByWeightInput)}
                                    />
                                </div>
                            </label>
                        </div>
                    </div>
                    <br />
                    <div className={styles.HorizontalFlex}>
                        <div>
                            <TitleSubtitle title='Palavra para 1 unidade' subtitle='Ex.: caixo, pacote, batata.' />
                            <Input
                                key={this.wordForOneUnitInput.key}
                                name={this.wordForOneUnitInput.key}
                                element={this.wordForOneUnitInput}
                                onChange={(event) => this.inputChanged(event, this.wordForOneUnitInput)}
                            />
                            <label>
                                <div className={styles.HorizontalFlex}>
                                    <div className={styles.TopLabel}>Palavra feminina</div>
                                    <Input
                                        key={this.isFemaleWordForOneUnitInput.key}
                                        name={this.isFemaleWordForOneUnitInput.key}
                                        element={this.isFemaleWordForOneUnitInput}
                                        onChange={(event) => this.inputChanged(event, this.isFemaleWordForOneUnitInput)}
                                    />
                                </div>
                            </label>
                        </div>
                        <div>
                            <TitleSubtitle title='Peso Bruto:' subtitle='' />
                            <div className={styles.TotalWeight}>{this.state.editedProduct.displayTotalContent()}</div>
                            <br />
                            <label>
                                <div className={styles.HorizontalFlex}>
                                    <div className={styles.TopLabel}>Produto desconhecido</div>
                                    <Input
                                        key={this.isIncompleteInput.key}
                                        name={this.isIncompleteInput.key}
                                        element={this.isIncompleteInput}
                                        onChange={(event) => this.inputChanged(event, this.isIncompleteInput)}
                                    />
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className={[styles.Container, 'Card'].join(' ')}>
                <div className={styles.Header}>
                    <div className={[styles.HorizontalFlex, styles.SmallDetails].join(' ')}>
                        <div>GTIN: {this.state.product ? this.state.product.GTIN : '-'}</div>
                        <div>{this.props.markliiCode}</div>
                    </div>
                    <div className={styles.HorizontalFlex}>
                        <div><h2>{this.state.product ? this.state.product.displayName() : this.state.productNotFound ? 'Produto não encontrado' : 'Carregando...'}</h2></div>
                        <div className={styles.ContentBox}>
                            <h2>{this.state.product ? this.state.product.displayTotalContent() : '-'}</h2>
                            <span className={styles.SmallDetails}>{this.state.product ? this.state.product.displayPackageQuantityAndContent() : '-'}</span>
                        </div>
                    </div>
                    <div className={[styles.SideBySideLists].join(' ')}>
                        <div className={styles.SideBySideDiv}>
                            <div className={styles.Bold}>Variação: </div>
                            <div>{this.state.product ? this.state.product.productVariation : '-'}</div>
                        </div>
                        <div className={styles.SideBySideDiv}>
                            <div className={styles.Bold}>Tags: </div>
                            <div>{this.state.product ? this.state.product.tags.join(', ') : '-'}</div>
                        </div>
                        <div className={styles.SideBySideDiv}>
                            <div className={styles.Bold}>Marca: </div>
                            <div>{this.state.product ? this.state.product.brand : '-'}</div>
                        </div>
                        <div className={styles.SideBySideDiv}>
                            <div className={styles.Bold}>Empresa: </div>
                            <div>{this.state.product ? this.state.product.manufacturer : '-'}</div>
                        </div>
                        <div className={styles.SideBySideDiv}>
                            <div className={styles.Bold}>Outros nomes: </div>
                            <div>{this.state.product ? this.state.product.alsoKnownAs.join(', ') : '-'}</div>
                        </div>
                    </div>
                    <br />
                    <div className={styles.HorizontalFlex}>
                        <Button size='small' onClick={this.finishEditing}>Fechar</Button>
                        <Button size='small' onClick={this.saveProduct} disabled={this.state.product === this.state.editedProduct}>Salvar alterações</Button>
                    </div>
                </div>
                <div className={styles.GS1Info}></div>
                <br />
                {inputFields}
                <div className={styles.LinkToGuidelines}>
                    <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/presentation/d/1OosvByIbdLWTDplEoeSR1bvQL8JFDndOYdQCbWJx0dA/edit?usp=sharing">Guia para edição do Catálogo</a>
                </div>
            </div>
        );
    }

}

export default withFirebase(EditCatalogItem);