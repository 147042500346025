/**
 * CurrentUser.js | Component
 * Displays information and options of the current logged user.
 * 
 * Props: 
 * - [Redux] currentUser: Admin object.
 */

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from './CurrentUser.module.css';
import ProfileImage from '../../Profile/ProfileImage/ProfileImage';
import * as routes from '../../../config/routes';
import UpdateMetrics from '../../../containers/UpdateMetrics/UpdateMetrics';

const currentUser = (props) => {
    return (
        <div className={styles.Container}>

            <div className={styles.ProfileImage}>
                <ProfileImage
                    displayName={props.currentUser.displayName}
                    imgURL={props.currentUser.profileURL}
                />
            </div>
            <div className={styles.Options}>
                <div className={styles.Name}>{props.currentUser.displayName}</div>
                <div className={styles.Email}>{props.currentUser.email}</div>
                <ul>
                    <li><Link to={routes.UPLOAD_PHOTO}><i>Definir foto de perfil (em breve)</i></Link></li>
                    <li><Link to={routes.CHANGE_PASSWORD}><i>Alterar Senha (em breve)</i></Link></li>
                    <li><UpdateMetrics metric="all"> Recalcular todas as métricas  <span role="img" aria-label="careful">⚠️</span> </UpdateMetrics></li>
                    <li><UpdateMetrics metric="purchases"> _____ Recalcular compras</UpdateMetrics></li>
                    <li><UpdateMetrics metric="stores"> _____ Recalcular mercados</UpdateMetrics></li>
                    <li><UpdateMetrics metric="users"> _____ Recalcular usuários</UpdateMetrics></li>
                    <li><Link to={routes.LOGOUT}>Sair</Link></li>
                </ul>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.currentUser
    };
}

export default connect(mapStateToProps)(currentUser);