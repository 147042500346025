/**
 * PurchaseDate.js | Component
 * A component used for processing receipts, which updates the  date of a purchase.
 * 
 * Props: 
 * - currentDate: [Date] Shown in case the purchase already has a defined purchased date.
 * - onSubmit: [function] What must be executed on submit.
 * - identifiedDate: [String] (Optional) The string identified as date from FirebaseML text.
 */

import React, { Component } from 'react';
import moment from 'moment';

import TitleSubtitle from '../../../../components/UI/TitleSubtitle/TitleSubtitle';
import Input, { InputElement } from '../../../../components/UI/Input/Input';
import Button from '../../../../components/UI/Button/Button';
import styles from './PurchaseDate.module.css';

class PurchaseDate extends Component {

    dateInput = new InputElement('date', 'input', 'text', '01/03/2019', null, null, { 'required': true, 'isDate': true });

    state = {
        currentDate: null,
        isValid: true
    }

    dateRef = React.createRef();

    inputChanged(e) {
        this.dateInput.updateInputValue(e.target.value);
        this.checkDateValidity();
    }

    checkDateValidity() {
        if (this.dateInput.isValid !== this.state.isValid) {
            this.setState({ isValid: this.dateInput.isValid });
        }
    }

    componentDidMount() {
        this.focusOnInput()
        this.updateInputWithProps()
    }

    componentDidUpdate() {
        this.updateInputWithProps()
    }

    focusOnInput() {
        this.dateRef.current.focus();
    }

    updateInputWithProps() {
        if (this.state.currentDate !== this.props.currentDate) {
            const dateValue = moment(this.props.currentDate).format('DD/MM/YYYY');
            this.dateInput.elementConfig.defaultValue = dateValue;
            this.dateInput.updateInputValue(dateValue);
            this.checkDateValidity();
            this.setState({ currentDate: this.props.currentDate });
        }
    }

    useSmartSuggestion = () => {
        // TO-DO... On click, we would populate the input with the value suggested. Could not make it work yet.
    }

    render() {
        const dateInput = this.dateInput;
        let smartSuggestion = null;
        if (this.props.identifiedDate) {
            smartSuggestion = <div className={styles.SmartSuggestionContainer}>Leitura Automática: <div className={styles.SmartSuggestion} onClick={this.useSmartSuggestion}>{this.props.identifiedDate}</div></div>
        }
        return (
            <div className={[styles.Container, 'Card'].join(' ')}>
                <form autoComplete='off' onSubmit={(e) => { e.preventDefault(); this.props.onSubmit(dateInput.value) }}>
                    <TitleSubtitle title='Quando a compra foi feita?' subtitle='Selecione o dia abaixo, ou carregue os dados da NFe.' />
                    {smartSuggestion}
                    <Input
                        key={dateInput.key}
                        name={dateInput.key}
                        ref={this.dateRef}
                        element={dateInput}
                        onChange={(event) => this.inputChanged(event, dateInput.key)}
                    />
                    <div className={styles.SaveButton}>
                        <Button shouldSubmit disabled={!dateInput.isValid}> Salvar </Button>
                    </div>
                </form>
            </div>
        );
    }
}

export default PurchaseDate;