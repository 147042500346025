import React from 'react';
import styles from './TitleSubtitle.module.css';

const titleSubtitle = (props) => {

    return (
        <div className={styles.Container}>
            <div className={styles.TopLabel}>
                {props.title ? props.title : '-'}
            </div>
            <div className={styles.BottomLabel}>{props.subtitle ? props.subtitle : ''}</div>
        </div>
    );
}

export default titleSubtitle;