/**
 * MarkliiError.js | Data Model
 * An object representing an Error from Firestore.
 * 
*/

class MarkliiError {

    constructor(uid, jsonPayload) {

        this.uid = uid;

        this.date         = jsonPayload.date.toDate();
        this.file         = jsonPayload.file ? jsonPayload.file : null;
        this.description  = jsonPayload.description ? jsonPayload.description : null;
        this.line         = jsonPayload.line ? jsonPayload.line : null;
        this.isRead       = jsonPayload.isRead ? jsonPayload.isRead : false;

    }
}

export default MarkliiError;