/**
 * StatsCard.js | Component
 * Presents statistics information in a preformatted way.
 * 
 * 
 * Props: 
 * - title: [string] the title of the metric.
 * - value: [string] the metric to be displayed.
 * - variation: [number] % representing the variation. Positive or negative.
 * - description: [string] the text on the side of the variation.
 * - caption: [string] text that appears on hover.
 */


import React from 'react';
import styles from './StatsCard.module.css';
import Card from '../../Card/Card';

const statsCard = (props) => {

    let variation = null;
    let variationClasses = [styles.Variation];
    if (props.variation > 0) {
        variation = "↑ " + (props.variation * 100).toFixed(2) + "%";
        variationClasses.push(styles.Positive);
    } else if (props.variation < 0) {
        variation = "↓ " + (props.variation * 100).toFixed(2) + "%";
        variationClasses.push(styles.Negative);
    }
    
    return (
    <Card>
        <div className={styles.Container}>
            <div className={styles.Title}>{props.title}</div>
            <div className={styles.Metric} title={props.caption}>{props.value}</div>
            <div className={styles.Horizontal}>
                <div className={variationClasses.join(' ')}>{variation}</div>
                <div className={styles.Description}>{props.description}</div>
            </div>
        </div>
    </Card>
)};

export default statsCard;