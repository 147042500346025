/**
 * ErrorsView.js | Container
 * Used to visualize app errors.
 * 
 */

import React, { PureComponent } from 'react';
import moment from 'moment';

// Libraries
import { withFirebase } from '../../components/Firebase';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';

import MarkliiError from '../../models/MarkliiError';

import Button from '../../components/UI/Button/Button';
import arrowIcon from '../../assets/images/arrow-down@3x.png';
import styles from './ErrorsView.module.css';

class ErrorsView extends PureComponent {

    state = {
        errors: [],
        selectedErrors: [],
        numberOfRows: 20,
        orderBy: 'desc'
    }

    componentDidMount() {
        this.props.setPageTitle("🆘📛⛔️🧨");
        this.loadErrors();
    }

    loadErrors() {

        let loadedErrors = []

        this.props.firebase.errors().orderBy('date', this.state.orderBy).limit(this.state.numberOfRows).get().then(errorsSnapshot => {
            if (errorsSnapshot.docs.length > 0) {
                errorsSnapshot.docs.forEach(doc => {
                    const error = new MarkliiError(doc.id, doc.data());
                    loadedErrors.push(error);
                });
                this.setState({ errors: loadedErrors });
            } else {
                console.log('No documents.');
            }

            this.setState({ errors: loadedErrors });
        }).catch(error => {
            console.error(error);
        })

    }

    selectError(error) {
        if (this.state.selectedErrors.includes(error.uid)) {
            const updatedArray = this.state.selectedErrors.filter(uid => uid !== error.uid);
            this.setState({ selectedErrors: updatedArray || [] });
        } else {
            this.setState({ selectedErrors: this.state.selectedErrors.concat(error.uid) });
        }
    }

    showMoreRows = () => {
        this.setState((prevState) => ({ numberOfRows: prevState.numberOfRows + 20 }), () => {
            this.loadErrors();
        });
    }

    isReadButtonPressed = () => {
        console.log("Clicked button.");
    }

    /*************************************************
     *  Render supporting methods.
     *************************************************/


    /**
     * Returns all the rows for all products in filteredCatalog, limited by the number of rows setting.
     */
    tableRows = () => {
        let tableRows = <tr><td colSpan="6">Nenhum erro encontrado.</td></tr>

        if (this.state.errors.length > 0) {
            tableRows = (
                this.state.errors.map(error => {
                    return this.rowForError(error);
                })
            );
        }

        return tableRows;
    }

    rowForError(error) {

        const isSelected = this.state.selectedErrors.includes(error.uid);
        const dateText = moment(error.date).format('DD/MM/YYYY HH:mm');

        return (
            <tr key={error.uid} onClick={() => this.selectError(error)}>
                <td><input type="checkbox" checked={isSelected} readOnly /></td>
                <td>{error.isRead ? null : <div className={styles.isNotRead}></div>}</td>
                <td>{dateText}</td>
                <td>{error.file}</td>
                <td>{error.line}</td>
                <td>{error.description}</td>
            </tr>
        );
    }

    render() {

        return (
            <div className={styles.Container}>
                <div className={styles.TopButtons}>
                    <Button size="small" onClick={this.isReadButtonPressed} disabled>Marcar como lido</Button>
                </div>
                <br />
                <br />
                <div className={styles.TableContainer}>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>Data e Hora <img src={arrowIcon} className={styles.OrderIconNewerFirst} alt="Ordenar por data" /></th>
                                <th>Local</th>
                                <th>Linha</th>
                                <th>Descrição</th>
                            </tr>
                            <tr>
                                <th colSpan="6"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.tableRows()}
                        </tbody>
                    </table>
                </div>
                <Button size='small' type='Default' onClick={this.showMoreRows}>↓ Mostrar mais 20 ↓</Button>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setPageTitle: (title) => dispatch(actions.setTitle(title))
    }
}

export default withFirebase(connect(null, mapDispatchToProps)(ErrorsView));