import Product from './Product';

class CatalogItem extends Product {

    constructor(uid, jsonPayload) {
        super(jsonPayload);

        this.uid = uid;
        
        // Catalog Information
        this.segment = jsonPayload.segment;
        this.family = jsonPayload.family;
        this.group = jsonPayload.group;
        this.brick = jsonPayload.brick;
        this.typeOfGTIN = jsonPayload.typeOfGTIN;
        this.inferiorGTIN = jsonPayload.inferiorGTIN;
        this.attributes = jsonPayload.attributes;
        this.country = jsonPayload.country;
        this.CEST = jsonPayload.CEST;
        this.NCM = jsonPayload.NCM;

        // Catalog Statistics Information
        this.avgPrice = Number(jsonPayload.avgPrice);
        this.avgConsumerRating = Number(jsonPayload.avgConsumerRating);
        this.avgDaysUntilDueDate = jsonPayload.avgDaysUntilDueDate ? Number(jsonPayload.avgDaysUntilDueDate) : null;

        this.filter();
        
    }
    
}

export default CatalogItem;