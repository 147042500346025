/**
 * AddPurchaseItem.js | Component
 * A component used for processing receipts, which updates the  date of a purchase.
 * 
 * Props: 
 * - purchase: [Purchase] To which purchase we will add the items.
 * - selectProduct: [function] Selects a product to be added/updated.
 * - selectedProduct: [Product] the product currently selected to be added or updated by this component.
 */
import React, { Component } from 'react';
import { withFirebase } from '../../../../components/Firebase';
import { connect } from 'react-redux';
import Fuse from 'fuse.js';
import CatalogItem from '../../../../models/CatalogItem';
import HistoryItem from '../../../../models/HistoryItem';

import Image from '../../../../components/UI/Image/Image';
import Button from '../../../../components/UI/Button/Button';
import styles from './AddPurchaseItem.module.css';
import Utils from '../../../../utils/Utils';

class AddPurchaseItem extends Component {

    // Settings for the Fuse fuzzy-search library. 
    options = {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
            "normalizedValue",
        ]
    };
    fuse = new Fuse(this.props.catalog, this.options);

    state = {
        itemNumber: 1,
        itemCode: '',
        itemQuantity: '',
        itemPrice: '',
        itemDiscount: '',
        isSearchButtonDisabled: false,
        isLoading: false,
        isGTIN: null,
        codeNotFound: false,
        shouldShake: false,
        itemUnknownName: 'Produto desconhecido',
        preselectedSuggestion: null,
        showSuggestions: false,
        currentSuggestion: '',
        suggestionsList: [],
        suggestionsResult: [],
        priceTagUrl: null,
    }

    // These references are used to focus automatically on the inputs for easier usability of the component.
    eanInputRef = React.createRef();
    quantityInputRef = React.createRef();
    priceInputRef = React.createRef();
    itemUnknownNameRef = React.createRef();
    suggestionsRef = React.createRef();

    componentDidMount() {
        this.eanInputRef.current.focus();
        this.setupSuggestions();
    }

    componentDidUpdate() {
        // Check to make sure the itemNumber is correct.
        let lastItem = this.props.productList[this.props.productList.length - 1];
        let itemNumber = lastItem ? lastItem.itemNumber + 1 : 1;
        if (!this.props.selectedProduct && itemNumber !== this.state.itemNumber)
            this.setState({ itemNumber: itemNumber });

        // If there is a product selected and it has already been added to the purchase, 
        // we update local state with its information.
        if (this.props.selectedProduct && this.state.itemUID !== this.props.selectedProduct.uid) {
            if (this.props.selectedProduct.purchaseID === this.props.purchase.uid) {
                this.setState({
                    itemNumber: this.props.selectedProduct.itemNumber,
                    itemCode: this.props.selectedProduct.storeCode || '',
                    itemQuantity: String(this.props.selectedProduct.quantityBought),
                    itemPrice: this.currencyFormat(this.props.selectedProduct.price),
                    itemDiscount: this.currencyFormat(this.props.selectedProduct.discount),
                    itemUID: this.props.selectedProduct.uid,
                    priceTagUrl: null,
                });
                this.getPriceTagURL();
            }
        }
    }

    getPriceTagURL = () => {
        if (this.props.selectedProduct && this.props.selectedProduct.productInCartPriceTag)
            this.props.firebase.doGetPriceTagURL(this.props.selectedProduct.productInCartPriceTag).then(url => {
                console.log(url);
                this.setState({ priceTagUrl: url });
            }).catch(error => {
                console.log(error.message);
            });
    }

    clearProductSelection = () => {
        this.selectProduct(null);

        // Resets state
        this.setState({
            codeNotFound: null,
            itemDiscount: '',
            itemQuantity: '',
            itemPrice: '',
            itemUID: '',
            isGTIN: null,
            itemUnknownName: 'Produto desconhecido',
            preselectedSuggestion: null,
            priceTagUrl: null,
        });

        // Clear suggestions
        this.selectedSuggestion = Object.assign({}, this.cleanSuggestion);
        this.selectedSuggestion.tags = [];

        this.eanInputRef.current.focus();
    }

    editInCatalog = () => {
        this.props.editCatalogItem();
    }

    createProductInCatalog = () => {

        // In order to quickly create a product in the catalog, we set up an incomplete Product object with just basic information.
        let incompleteProduct = new CatalogItem('', {
            'genericName': this.state.itemUnknownName,
            'GTIN': Utils.isValidGTIN(this.state.itemCode) ? this.state.itemCode : null,
            'tags': ['Novo no Marklii'],
            'packageQuantity': 1,
            'contentOfOneUnit': 1,
            'contentUnit': 'un',
            'isPriceByWeight': false,
            'isIncomplete': true
        });

        // And after adding it to the Catalog collection in Firestore, we select the product.
        this.props.firebase.doAddCatalogProduct(Object.assign({}, incompleteProduct)).then(docUID => {
            incompleteProduct.uid = docUID;
            incompleteProduct.markliiCode = docUID;
            this.selectProduct(incompleteProduct);
            this.quantityInputRef.current.focus();
        });
    }

    addNewNonGTINProduct = () => {

        let incompleteProduct = new CatalogItem('', {
            'genericName': null,
            'genericType': null,
            'brand': null,
            'productVariation': null,
            'GTIN': null,
            'tags': ['Novo no Marklii'],
            'packageQuantity': 1,
            'contentOfOneUnit': 1,
            'contentUnit': 'Kg',
            'isPriceByWeight': true,
            'isIncomplete': true
        });

        const valueToAdd = this.state.itemCode === '' ? null : this.state.itemCode;

        if (this.selectedSuggestion.genericName) {
            incompleteProduct.genericName = this.selectedSuggestion.genericName;
            if (this.selectedSuggestion.genericType) {
                incompleteProduct.genericType = this.selectedSuggestion.genericType !== 'null' ? this.selectedSuggestion.genericType : null;
                if (this.selectedSuggestion.brand) {
                    incompleteProduct.brand = this.selectedSuggestion.brand !== 'null' ? this.selectedSuggestion.brand : null;;
                    if (this.selectedSuggestion.productVariation) {
                        incompleteProduct.productVariation = this.selectedSuggestion.productVariation !== 'null' ? this.selectedSuggestion.productVariation : null;;
                        if (this.selectedSuggestion.contentOfOneUnit) {
                            incompleteProduct.contentOfOneUnit = this.selectedSuggestion.contentOfOneUnit;
                            incompleteProduct.packageQuantity = this.selectedSuggestion.packageQuantity;
                            incompleteProduct.contentUnit = this.selectedSuggestion.contentUnit;
                            incompleteProduct.tags = [...incompleteProduct.tags, ...this.selectedSuggestion.tags];
                            if (this.state.itemCode !== '') {
                                incompleteProduct.tags.push(this.state.itemCode);
                            }
                        } else {
                            const NUMERIC_REGEXP = /[-]{0,1}[\d]*[.]{0,1}[\d]+/g;
                            const number = this.state.itemCode.match(NUMERIC_REGEXP)[0];
                            const unit = this.state.itemCode.match(/[a-zA-Z]+/g)[0];
                            incompleteProduct.contentOfOneUnit = number !== undefined ? Number(number) : 1;
                            incompleteProduct.contentUnit = unit !== undefined ? unit : 'Kg';
                        }
                    } else {
                        incompleteProduct.productVariation = valueToAdd;
                    }
                } else {
                    incompleteProduct.brand = valueToAdd;
                }
            } else {
                incompleteProduct.genericType = valueToAdd;
            }
        } else {
            incompleteProduct.genericName = valueToAdd;
        }

        let productString = 'Produto genérico: ' + incompleteProduct.genericName + '\n';
        productString += 'Tipo genérico: ' + incompleteProduct.genericType + '\n';
        productString += 'Marca: ' + incompleteProduct.brand + '\n';
        productString += 'Variação: ' + incompleteProduct.productVariation + '\n';
        productString += 'Qtde. Pacote: ' + incompleteProduct.packageQuantity + '\n';
        productString += 'Conteúdo 1un.: ' + incompleteProduct.contentOfOneUnit + '\n';
        productString += 'Unidade: ' + incompleteProduct.contentUnit + '\n';
        productString += 'Tags: ' + incompleteProduct.tags.join(', ');

        if (window.confirm('Confirma a criação de um novo produto: \n\n' + productString)) {
            // And after adding it to the Catalog collection in Firestore, we select the product.
            this.props.firebase.doAddCatalogProduct(Object.assign({}, incompleteProduct)).then(docUID => {
                incompleteProduct.uid = docUID;
                incompleteProduct.markliiCode = docUID;
                this.clearSelectedSuggestion();
                this.setState({ itemCode: '' });
                this.selectProduct(incompleteProduct);
                this.eanInputRef.current.focus();
            });
        }

    }

    // This function creates a Product object ready to be added or updated in Firestore.
    // Called by addProductToPurchase() and updateProductInPurchase().
    getProductReadyToSave = () => {

        // Transform strings into numbers.
        const itemNumber = Number(this.state.itemNumber);
        const price = Number(this.state.itemPrice.replace(/\D/g, '')) / 100;
        const discount = Number(this.state.itemDiscount.replace(/\D/g, '')) / 100;
        const quantity = Number(this.state.itemQuantity.replace(',', '.'));

        // In case of any error, we stop the saving or updating process and notify the user.
        if (isNaN(itemNumber) || isNaN(price) || isNaN(discount) || isNaN(quantity) || price === 0 || quantity === 0) {
            if (quantity === 0) { this.quantityInputRef.current.focus(); }
            else if (price === 0) { this.priceInputRef.current.focus(); }
            this.shake();
            return null;
        }

        let productBeingSaved = new HistoryItem(null, this.props.selectedProduct);
        productBeingSaved.wasBought(this.props.purchase, this.props.selectedProduct.markliiCode, itemNumber, this.state.itemCode, quantity, price, discount, this.props.selectedProduct.avgDaysUntilDueDate);

        return productBeingSaved;
    }

    addProductToPurchase = () => {
        const productBeingAdded = this.getProductReadyToSave();
        if (productBeingAdded !== null) {
            this.props.firebase.doAddHistoryItem({ ...productBeingAdded });
            this.prepareForNextItem();
        }
    }

    updateProductInPurchase = () => {
        const productBeingUpdated = this.getProductReadyToSave();
        if (productBeingUpdated !== null) {
            this.setFormEnabledAs(false);
            this.props.firebase.historyItem(this.props.selectedProduct.uid).set({ ...productBeingUpdated }, { merge: true }).then(() => {
                this.prepareForNextItem();
            }).catch(error => {
                this.shake();
                this.setFormEnabledAs(true);
                console.error(error);
            });
        }
    }

    deleteSelectedItem = () => {
        if (this.props.selectedProduct) {
            if (this.props.selectedProduct.purchaseID === this.props.purchase.uid) {
                this.props.firebase.doDeleteHistoryItem(this.props.selectedProduct);
                this.prepareForNextItem();
            }
        }
    }

    // Prepares the component for adding a new item to the purchase.
    prepareForNextItem = () => {
        this.clearProductSelection()
        this.setState({ itemCode: '', itemNumber: this.props.purchase.quantityOfItems + 1 });
        this.setFormEnabledAs(true);
    }

    onSubmit = (e) => {
        e.preventDefault();

        if (this.props.selectedProduct) {
            if (this.state.itemCode !== '') {
                if (this.props.selectedProduct.purchaseID === this.props.purchase.uid) {
                    this.updateProductInPurchase();
                } else {
                    this.addProductToPurchase();
                }
            }
        } else if (this.state.codeNotFound === true) {
            this.createProductInCatalog();
        } else if (!this.handleSuggestionsVisibility(this.state.itemCode)) {
            this.lookForCode();
        }
    }

    onChange = (e) => {
        switch (e.target.name) {
            case 'itemCode':
                this.handleItemCode(e.target.value);
                break;
            case 'itemPrice':
                this.handleItemPrice(e.target.value);
                break;
            case 'itemDiscount':
                this.handleItemDiscount(e.target.value);
                break;
            case 'itemQuantity':
                this.handleItemQuantity(e.target.value);
                break;
            default:
                this.setState({ [e.target.name]: e.target.value });
                break;
        }
    }

    // Key handler of the itemCode input. Used for navigating and selecting suggestions.
    eanKeyPress = (e) => {

        if (e.keyCode === 13) {

            if (this.handleSuggestionsVisibility(this.state.itemCode)) {
                const suggestion = this.state.suggestionsResult[this.state.preselectedSuggestion - 1];
                if (suggestion)
                    this.filterSuggestions(suggestion.value);
            }

        } else if (e.keyCode === 40) { // down arrow
            this.setState(prevState => ({
                preselectedSuggestion: prevState.preselectedSuggestion + 1 > this.state.suggestionsResult.length ? this.state.suggestionsResult.length : prevState.preselectedSuggestion + 1
            }));
            if (this.state.preselectedSuggestion >= 2) {
                this.suggestionsRef.current.scrollTo(0, (this.state.preselectedSuggestion - 1) * 24);
            }

        } else if (e.keyCode === 38) { // up arrow
            this.setState(prevState => ({
                preselectedSuggestion: prevState.preselectedSuggestion === 1 ? 1 : prevState.preselectedSuggestion - 1
            }));
            this.suggestionsRef.current.scrollTo(0, (this.state.preselectedSuggestion - 3) * 24);
        }
    }

    handleItemCode(code) {
        this.setState({ itemCode: code });

        if (this.handleSuggestionsVisibility(code)) {
            this.getSuggestions(code);
        } else {
            this.checkProductCode(code);
        }
    }

    handleItemQuantity(quantity) {
        this.setState({ itemQuantity: String(quantity) });
    }

    handleItemPrice(price) {
        const number = Number(price.replace(/\D/g, '')) / 100;
        this.setState({ itemPrice: number === 0 ? '' : this.currencyFormat(number) });
    }

    handleItemDiscount(discount) {
        const number = Number(discount.replace(/\D/g, '')) / 100;
        this.setState({ itemDiscount: number === 0 ? '' : this.currencyFormat(number) });
    }

    totalPrice() {
        const price = Number(this.state.itemPrice.replace(/\D/g, '')) / 100;
        const discount = Number(this.state.itemDiscount.replace(/\D/g, '')) / 100;
        const quantity = this.state.itemQuantity.replace(',', '.');

        return (quantity * (price - discount));
    }

    currencyFormat(number) {
        return Number(number).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    checkProductCode(code) {
        code = code.replace(/\D/g, '');

        if (Utils.isValidGTIN(code)) {
            this.setState({ isGTIN: true });
        } else {
            if (code === '') {
                this.setState({ isGTIN: null });
            } else {
                this.setState({ isGTIN: false });
            }
        }

    }

    lookForCode = () => {
        const code = this.state.itemCode;
        if (code.trim() === '') {
            this.shake();
            return;
        }

        this.setFormEnabledAs(false);

        if (Utils.isValidGTIN(code)) {
            console.log('Looking for GTIN ', code);
            this.props.firebase.catalogProducts().where('GTIN', '==', code).limit(1).get().then(snapshot => {
                this.setFormEnabledAs(true);
                if (snapshot.docs.length > 0) {
                    snapshot.docs.forEach(doc => {
                        const product = new CatalogItem(doc.id, doc.data());
                        product.markliiCode = doc.id;
                        this.selectProduct(product);
                    });
                    this.quantityInputRef.current.focus();
                } else {
                    this.clearProductSelection();
                    this.setState({ codeNotFound: true });
                    this.itemUnknownNameRef.current.focus();
                    this.shake();
                }
            }).catch(error => {
                console.error(error.message);
            });
        } else {
            console.log('Looking for Custom Code ', code);
            this.props.firebase.history().where('storeCode', '==', code).where('storeID', '==', this.props.purchase.storeID).limit(1).get().then(snapshot => {
                this.setFormEnabledAs(true);
                if (snapshot.docs.length > 0) {
                    snapshot.docs.forEach(doc => {
                        const product = new CatalogItem(doc.id, doc.data());
                        product.clearPurchaseInformation();
                        this.selectProduct(product);
                        this.quantityInputRef.current.focus();
                        if (snapshot.docs.length > 1) {
                            console.log('More than one product was found.');
                        }
                    });
                } else {
                    this.shake();
                    // this.setState({ codeNotFound: true });
                    // this.itemUnknownNameRef.current.focus();
                }
            })
        }

    }

    selectProduct = (product) => {
        if (product && this.props.selectedProduct) {
            console.log('Changing product already in list...');
            const avgDaysUntilDueDate = product.avgDaysUntilDueDate;
            product = new HistoryItem(null, product);
            product.setHistoryInfoFrom(this.props.selectedProduct);
            product.setSpoilingDate(this.props.purchase.purchaseDate, avgDaysUntilDueDate);
        }
        this.props.selectProduct(product);
    }

    setFormEnabledAs(enabled) {
        if (enabled) {
            this.setState({ isLoading: false });
            this.eanInputRef.current.focus();
        } else {
            this.setState({ isLoading: true });
        }
    }

    shake() {
        this.setState({ shouldShake: true });
        setTimeout(() => {
            this.setState({ shouldShake: false });
        }, 2000);
    }

    preselectSuggestion = (index) => {
        this.setState({ preselectedSuggestion: index });
    }

    selectedSuggestion = {
        genericName: undefined,
        genericType: undefined,
        brand: undefined,
        productVariation: undefined,
        packageQuantity: undefined,
        contentOfOneUnit: undefined,
        contentUnit: undefined,
        isPriceByWeight: undefined,
        tags: []
    }
    cleanSuggestion = Object.assign({}, this.selectedSuggestion);

    clearSelectedSuggestion = () => {
        this.selectedSuggestion = { ...this.cleanSuggestion, tags: [] };
        this.setupSuggestions();
        this.eanInputRef.current.focus();
        this.handleSuggestionsVisibility(this.state.itemCode);
    }

    setupSuggestions() {
        let list = [];

        // Has not selected genericName?
        if (this.selectedSuggestion.genericName === undefined) {
            list = [...new Set(this.props.catalog.map(product => (product.genericName)))];
            this.setState({ currentSuggestion: 'Produto Genérico' });

            // Has not selected genericType?
        } else if (this.selectedSuggestion.genericType === undefined) {
            list = [...new Set(this.props.catalog
                .filter(product => product.genericName === this.selectedSuggestion.genericName)
                .map(product => (product.genericType ? product.genericType : 'null')))];
            this.setState({ currentSuggestion: 'Tipo Genérico' });

            // Has not selected brand?
        } else if (this.selectedSuggestion.brand === undefined) {
            list = [...new Set(this.props.catalog
                .filter(product =>
                    product.genericName === this.selectedSuggestion.genericName &&
                    product.genericType === (this.selectedSuggestion.genericType === 'null' ? null : this.selectedSuggestion.genericType))
                .map(product => (product.brand ? product.brand : 'null')))];
            this.setState({ currentSuggestion: 'Marca' });

            // Has not selected variation?
        } else if (this.selectedSuggestion.productVariation === undefined) {
            list = [...new Set(this.props.catalog
                .filter(product =>
                    product.genericName === this.selectedSuggestion.genericName &&
                    product.genericType === (this.selectedSuggestion.genericType === 'null' ? null : this.selectedSuggestion.genericType) &&
                    product.brand === (this.selectedSuggestion.brand === 'null' ? null : this.selectedSuggestion.brand))
                .map(product => (product.productVariation ? product.productVariation : 'null')))];
            this.setState({ currentSuggestion: 'Variação' });

            // Has not selected content?
        } else if (this.selectedSuggestion.contentOfOneUnit === undefined) {
            list = [...new Set(this.props.catalog
                .filter(product =>
                    product.genericName === this.selectedSuggestion.genericName &&
                    product.genericType === (this.selectedSuggestion.genericType === 'null' ? null : this.selectedSuggestion.genericType) &&
                    product.brand === (this.selectedSuggestion.brand === 'null' ? null : this.selectedSuggestion.brand) &&
                    product.productVariation === (this.selectedSuggestion.productVariation === 'null' ? null : this.selectedSuggestion.productVariation))
                .map(product => (
                    product.isPriceByWeight ? 'Por Peso (' + product.contentOfOneUnit + ' ' + product.contentUnit + ')' : product.contentOfOneUnit ? product.packageQuantity === 1 ? product.contentOfOneUnit + ' ' + product.contentUnit : product.packageQuantity + 'x ' + product.contentOfOneUnit + ' ' + product.contentUnit : 'null'
                )))];
            this.setState({ currentSuggestion: 'Conteúdo' });

        } else {
            // Get filtered results based on given answers.
            let result = this.props.catalog.filter(product => {
                return product.genericName === this.selectedSuggestion.genericName &&
                    product.genericType === (this.selectedSuggestion.genericType === 'null' ? null : this.selectedSuggestion.genericType) &&
                    product.brand === (this.selectedSuggestion.brand === 'null' ? null : this.selectedSuggestion.brand) &&
                    product.productVariation === (this.selectedSuggestion.productVariation === 'null' ? null : this.selectedSuggestion.productVariation) &&
                    product.contentOfOneUnit === (this.selectedSuggestion.contentOfOneUnit === 'null' ? null : this.selectedSuggestion.contentOfOneUnit) &&
                    product.packageQuantity === (this.selectedSuggestion.packageQuantity === 'null' ? null : this.selectedSuggestion.packageQuantity) &&
                    product.isPriceByWeight === this.selectedSuggestion.isPriceByWeight &&
                    product.contentUnit === (this.selectedSuggestion.contentUnit === 'null' ? null : this.selectedSuggestion.contentUnit)
            });

            // And then filter by which results contains the selected tags, if any.
            if (this.selectedSuggestion.tags.indexOf('Sem nenhuma tag') > -1) {
                result = result.filter(product => product.tags.length === 0).slice(0, 1); // We return only the first element because there's no more filtering to do. If at this point there are several results, it's just a duplicated product.
            } else if (this.selectedSuggestion.tags.indexOf('Adicionar sem outras tags') > -1) {
                const indexToRemove = this.selectedSuggestion.tags.indexOf('Adicionar sem outras tags');
                if (indexToRemove > -1)
                    this.selectedSuggestion.tags.splice(indexToRemove, 1);
                result = result.filter(product => this.selectedSuggestion.tags.every(r => product.tags.indexOf(r) >= 0) && product.tags.every(r => this.selectedSuggestion.tags.indexOf(r) >= 0)).slice(0, 1);
                if (result.length === 0) {
                    if (window.confirm('Não há nenhum produto que possua apenas as tags [' + this.selectedSuggestion.tags.join(', ') + ']. Gostaria de criar um novo?')) {
                        this.addNewNonGTINProduct();
                    }
                }
            } else {
                result = result.filter(product => this.selectedSuggestion.tags.every(r => product.tags.indexOf(r) >= 0));
            }

            // If there's just one result (as it should), we select the product.
            if (result.length === 1) {
                this.selectProduct(result[0]);
                this.clearSelectedSuggestion();
                return;

                // If there's more than one result, we show available tags for further filtering.
            } else {
                let arrayOfTags = this.props.catalog
                    .filter(product =>
                        product.genericName === this.selectedSuggestion.genericName &&
                        product.genericType === (this.selectedSuggestion.genericType === 'null' ? null : this.selectedSuggestion.genericType) &&
                        product.brand === (this.selectedSuggestion.brand === 'null' ? null : this.selectedSuggestion.brand) &&
                        product.productVariation === (this.selectedSuggestion.productVariation === 'null' ? null : this.selectedSuggestion.productVariation) &&
                        product.contentOfOneUnit === (this.selectedSuggestion.contentOfOneUnit === 'null' ? null : this.selectedSuggestion.contentOfOneUnit) &&
                        this.selectedSuggestion.tags.every(r => product.tags.indexOf(r) >= 0))
                    .map(product => (product.tags.length > 0 ? product.tags || [] : ['Sem nenhuma tag']));
                list = [...new Set([].concat.apply([], arrayOfTags))];
                list = list.filter(item => !this.selectedSuggestion.tags.includes(item));
                if (this.selectedSuggestion.tags.length !== 0)
                    list.push('Adicionar sem outras tags');

                this.setState({ currentSuggestion: 'Tags' });

                console.log('Há mais de um resultado.', result);
            }
        }

        this.setState({ preselectedSuggestion: list.length > 0 ? 1 : null });

        list = list.map(value => ({ 'value': value, 'normalizedValue': value.normalize('NFD').replace(/[\u0300-\u036f]/g, "") }));
        this.setState({ suggestionsList: list, suggestionsResult: [] });
        this.fuse = new Fuse(list, this.options);
    }

    getSuggestions(text) {
        const normalizedText = text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        let results = this.fuse.search(normalizedText).slice(0, 10);
        this.setState({ suggestionsResult: results.length > 0 ? results : this.state.suggestionsList });
    }

    filterSuggestions(text) {
        if (this.selectedSuggestion.genericName === undefined) {
            this.selectedSuggestion.genericName = text;
        } else if (this.selectedSuggestion.genericType === undefined) {
            this.selectedSuggestion.genericType = text;
        } else if (this.selectedSuggestion.brand === undefined) {
            this.selectedSuggestion.brand = text;
        } else if (this.selectedSuggestion.productVariation === undefined) {
            this.selectedSuggestion.productVariation = text;
        } else if (this.selectedSuggestion.contentOfOneUnit === undefined) {
            this.selectedSuggestion.contentOfOneUnit = Number(text.replace(/[^0-9.]+/g, ''));
            this.selectedSuggestion.isPriceByWeight = text.includes('Peso');
            this.selectedSuggestion.packageQuantity = 1;

            if (this.selectedSuggestion.isPriceByWeight)
                this.selectedSuggestion.contentUnit = text.replace('Por Peso', '').replace(/[^a-z+]+/gi, '');
            else if (text.includes('x ')) {
                const textParts = text.split('x ');
                this.selectedSuggestion.packageQuantity = Number(textParts[0].replace(/[^0-9.]+/g, ''));
                this.selectedSuggestion.contentUnit = textParts[1].replace(/[^a-z+]+/gi, '');
                this.selectedSuggestion.contentOfOneUnit = Number(textParts[1].replace(/[^0-9.]+/g, ''));
            } else
                this.selectedSuggestion.contentUnit = text.replace(/[^a-z+]+/gi, '');
        } else {
            this.selectedSuggestion.tags.push(text);
        }

        this.setState({ itemCode: '' });
        this.setupSuggestions();
        this.getSuggestions('  ');
        setTimeout(() => {
            this.getSuggestions('');
        }, 50);
        this.eanInputRef.current.focus();
    }

    handleSuggestionsVisibility = (code) => {
        if (code.match(/^[A-Z]/i) || this.selectedSuggestion.genericName !== undefined) {
            this.setState({ showSuggestions: true });
            return true;
        } else {
            this.setState({ showSuggestions: false });
            return false;
        }
    }

    render() {

        // const product = this.state.product ? this.state.product : {};
        const product = this.props.selectedProduct ? this.props.selectedProduct : {};

        let productInformation = <div></div>;
        let deleteButton = <div></div>;
        let editOrCreateButton = <Button shouldSubmit size='small'> Criar no Catálogo </Button>

        if (this.state.codeNotFound) {
            productInformation = (
                <div>
                    <div className={styles.Label}>{this.state.itemCode}</div>
                    <input className={styles.CleanInput} type='text' ref={this.itemUnknownNameRef} name='itemUnknownName' placeholder='Descrição do produto' defaultValue={this.state.itemUnknownName} onChange={this.onChange} />
                </div>
            );
        }

        if (this.props.selectedProduct) {
            productInformation = (
                <div>
                    <div className={styles.Label}>GTIN: {product.GTIN}</div>
                    <div className={styles.ProductLabel}><b>{product.displayName()}</b></div>
                    <div className={styles.DetailsLabel}>
                        {[product.displayPackageQuantityAndContent(), product.brand].concat(product.tags).filter(item => item !== null).join(', ')}
                    </div>
                    <div className={[styles.Label, styles.LightLabel].join(' ')}>UID: {product.uid} | Marklii Code: {product.markliiCode}</div>
                </div>
            );

            if (this.props.selectedProduct.purchaseID === this.props.purchase.uid) {
                deleteButton = <Button size='small' icon='delete' type='RedAlert' onClick={this.deleteSelectedItem} >Excluir</Button>
            }

            editOrCreateButton = <Button size='small' type='Default' onClick={this.editInCatalog} disabled={!this.props.selectedProduct.markliiCode}> Editar no Catálogo </Button>
        }

        let addNonGTINButton = "Adicionar sem " + this.state.currentSuggestion.toLocaleLowerCase();
        if (this.state.itemCode !== '') {
            addNonGTINButton = "Adicionar '" + this.state.itemCode + "'";
        } else if (this.state.currentSuggestion === 'Tags' &&
            (this.state.suggestionsResult.map(item => item.value).indexOf('Sem nenhuma tag') > -1 || this.selectedSuggestion.tags.length > 0)) {
            addNonGTINButton = null;
        }

        let priceTagComponent;
        if (product.productInCartPriceTag)
            priceTagComponent = <center>Imagem do preço não encontrada</center>;
        if (this.state.priceTagUrl)
            priceTagComponent = <Image receiptsURLs={[this.state.priceTagUrl]} />

        return (
            <div className={[this.state.shouldShake ? styles.Shaker : null, 'Card', styles.Container].join(' ')}>
                <fieldset disabled={this.state.isLoading}>
                    <form autoComplete='off' onSubmit={this.onSubmit}>
                        <div className={styles.InsideContainer}>
                            <div>
                                <div className={styles.Inputs}>
                                    <div className={styles.InputItem}>
                                        <div className={styles.Label}>Item</div>
                                        <input
                                            type='text'
                                            name='itemNumber'
                                            className={styles.ItemNumber}
                                            placeholder='#'
                                            value={this.state.itemNumber}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className={[styles.InputItem, styles.InputCodeItem].join(' ')}>
                                        <div className={styles.TagsDiv}>
                                            <div className={styles.Label}>Código</div>
                                            <div className={[styles.Tag, styles.CustomCode, styles[this.state.isGTIN === false ? 'Visible' : null]].join(' ')}>Código Próprio</div>
                                            <div className={[styles.Tag, styles.GTIN, styles[this.state.isGTIN === true ? 'Visible' : null]].join(' ')}>GTIN</div>
                                        </div>
                                        <input
                                            ref={this.eanInputRef}
                                            type='text'
                                            name='itemCode'
                                            className={styles.ItemCode}
                                            placeholder='789...'
                                            value={this.state.itemCode}
                                            onChange={this.onChange}
                                            onKeyDown={this.eanKeyPress}
                                            onBlur={() => this.handleSuggestionsVisibility(this.state.itemCode)}
                                            onFocus={() => this.handleSuggestionsVisibility(this.state.itemCode)}
                                        />
                                        <div className={[styles.SuggestionsContainer, this.state.showSuggestions ? null : styles.Hidden].join(' ')}>
                                            <div className={styles.Suggestions}>
                                                <div className={styles.SuggestionsHeader}>
                                                    <div className={styles.HorizontalDisplay}>
                                                        <div>{this.state.currentSuggestion}:</div>
                                                        <div className={styles.Link} onClick={this.clearSelectedSuggestion}>X</div>
                                                    </div>
                                                    <div className={styles.Link} onClick={this.addNewNonGTINProduct}>{addNonGTINButton}</div>
                                                </div>
                                                <ul ref={this.suggestionsRef}>
                                                    {this.state.suggestionsResult.map((suggestion, index) => (
                                                        <li
                                                            key={index + 1}
                                                            className={this.state.preselectedSuggestion === index + 1 ? styles.Preselected : null}
                                                            onMouseOver={() => this.preselectSuggestion(index + 1)}
                                                            onMouseOut={() => this.preselectSuggestion(null)}
                                                            onClick={() => this.filterSuggestions(suggestion.value)}
                                                        >
                                                            {suggestion.value}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.InputItem}>
                                        <div className={styles.Label}>&nbsp;</div>
                                        <Button size='small' icon='search' disabled={this.state.isSearchButtonDisabled} onClick={this.lookForCode}></Button>
                                    </div>
                                    <div className={styles.InputItem}>
                                        <div className={styles.Label}>Qtde.</div>
                                        <input
                                            ref={this.quantityInputRef}
                                            type='text'
                                            name='itemQuantity'
                                            className={styles.ItemQuantity}
                                            value={this.state.itemQuantity}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className={styles.InputItem}>
                                        <div className={styles.Label}>Preço Unit.</div>
                                        <input
                                            ref={this.priceInputRef}
                                            type='text'
                                            name='itemPrice'
                                            className={styles.ItemPrice}
                                            value={this.state.itemPrice}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className={styles.InputItem}>
                                        <div className={styles.Label}>Desconto</div>
                                        <input
                                            type='text'
                                            name='itemDiscount'
                                            className={styles.ItemDiscount}
                                            value={this.state.itemDiscount}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                                <div className={styles.ProductInformation}>
                                    {productInformation}
                                    <div>
                                        <div className={styles.Label} style={{ textAlign: "right" }}>Total</div>
                                        <div className={styles.ProductLabel}><b>{this.currencyFormat(this.totalPrice())}</b></div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.Buttons}>
                                <Button size='small' type='Default' onClick={this.clearProductSelection} disabled={!this.props.selectedProduct && !this.state.codeNotFound}> Deselecionar </Button>
                                {editOrCreateButton}
                                <Button size='small' shouldSubmit disabled={!this.props.selectedProduct}> {product.purchaseID === this.props.purchase.uid ? 'Atualizar' : 'Adicionar'} </Button>
                                {deleteButton}
                            </div>
                        </div>
                    </form>
                </fieldset>
                <div className={styles.PriceTag}>
                    {priceTagComponent}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        catalog: state.catalog.catalog
    };
}

export default withFirebase(connect(mapStateToProps)(AddPurchaseItem));
