/**
 * PurchaseAmount.js | Component
 * A component used for processing receipts, which updates the total amount of a purchase.
 * 
 * Props: 
 * - currentAmount: [number] Shown in case the purchase already has a defined total amount.
 * - onSubmit: [function] What must be executed on submit.
 * - identifiedTotalValues: [Array] (Optional) The string identified as date from FirebaseML text.
 */

import React, { Component } from 'react';

import TitleSubtitle from '../../../../components/UI/TitleSubtitle/TitleSubtitle';
import Input, { InputElement } from '../../../../components/UI/Input/Input';
import Button from '../../../../components/UI/Button/Button';
import styles from './PurchaseAmount.module.css';

class PurchaseAmount extends Component {

    amountInput = new InputElement('amount', 'input', 'text', 'R$ 100,00', this.props.currentAmount ? this.currencyFormat(this.props.currentAmount) : null, null, { 'required': true, 'isNumber': true });

    state = {
        currentAmount: null,
        isValid: null
    }

    amountRef = React.createRef();

    inputChanged(e) {

        const input = e.target.value.trim();
        if (input !== '') {
            const number = Number(e.target.value.replace(/\D/g, '')) / 100;
            this.amountRef.current.value = this.currencyFormat(number);
            this.amountInput.updateInputValue(number.toString());
        } else {
            this.amountInput.updateInputValue(null);
        }

        if (this.amountInput.isValid !== this.state.isValid) {
            this.setState({ isValid: this.amountInput.isValid });
        }
    }

    currencyFormat(number) {
        return Number(number).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    componentDidMount() {
        this.focusOnInput()
        this.updateInputWithProps()
    }

    componentDidUpdate() {
        this.updateInputWithProps()
    }

    focusOnInput() {
        this.amountRef.current.focus();
    }

    updateInputWithProps() {
        if (this.state.currentAmount === null && (this.props.currentAmount || this.props.identifiedTotalValues)) {
            let value = Number(this.props.currentAmount ? this.props.currentAmount : this.props.identifiedTotalValues ? this.props.identifiedTotalValues[0] : null);
            this.amountInput.elementConfig.defaultValue = this.currencyFormat(value);
            this.amountInput.updateInputValue(value.toString());
            const isValid = this.amountInput.isValid
            this.setState({ currentAmount: value, isValid: isValid });
        }
    }

    render() {
        const amountInput = this.amountInput;
        let smartSuggestion = null;
        if (this.props.identifiedTotalValues) {
            smartSuggestion = (
                <div className={styles.SmartSuggestionContainer}>Leitura Automática:
                    {this.props.identifiedTotalValues.slice(0, 3).map((value, index) => {
                    return <div key={value + index} className={styles.SmartSuggestion}>{this.currencyFormat(value)}</div>
                })}
                </div>
            );
        }
        return (
            <div className={[styles.Container, 'Card'].join(' ')}>
                <form autoComplete='off' onSubmit={(e) => { e.preventDefault(); this.props.onSubmit(Number(amountInput.value)) }}>
                    <TitleSubtitle title='Qual o valor total da compra?' subtitle='Informe abaixo, ou carregue os dados da NFe.' />
                    {smartSuggestion}
                    <Input
                        key={amountInput.key}
                        name={amountInput.key}
                        ref={this.amountRef}
                        element={amountInput}
                        onChange={(event) => this.inputChanged(event, amountInput.key)}
                    />
                    <div className={styles.SaveButton}>
                        <Button shouldSubmit disabled={!amountInput.isValid}> Salvar </Button>
                    </div>
                </form>
            </div>
        );
    }
}

export default PurchaseAmount;